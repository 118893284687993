<template>
    <div class="agendamento">
        <div class="row">
            <div class="col-sm-7 offset-sm-5" style="text-align:center;">
                <img class="img-fluid" :src="$Url + DadosPaypal.cadOperadora.urlImg" style="width:200px;height:auto;" alt="">
            </div>
        </div>
        <div class="row" style="text-align:center;">
            <div class="col-sm-5"><p class="texto"></p></div>
            <div class="col-sm-7">
                <p class="texto-destaque">Valor Total da compra R$ {{ parseFloat(TotalProdutos).toFixed(2).toString().replace('.', ',') }}</p>
                <p class="texto-strong d-inline-block mb-0 mr-2">
                    clique abaixo para efetuar pagamento
                </p>

            </div>
            <div class="col-sm-7 offset-sm-5">
                <!-- eslint-disable-next-line vue/attribute-hyphenation  -->
               <!--  <paypal-buttons :on-approve="onApprove" :create-order="createOrder" :on-shipping-change="onShippingChange" :on-error="onError" :style-object="style" /> -->
            </div>
        </div>
    </div>

</template>

<script>
    //import Vue from 'vue';

    export default {
        components: {
           /*  'paypal-buttons': window.paypal.Buttons.driver('vue', Vue) */
        },

        data() {
            return {
                paypalRequestId: '',
                TotalProdutos: 0,
                DadosPaypal: '',
            };
        },

        methods: {

        },
        created() {

            this.$http
                .get(this.$apiUrl + "/configoperadora/4")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.DadosPaypal = retornoAPI;
                    //this.TokenYapay = '';
                });

            let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
            dadosArmazenados = JSON.parse(dadosArmazenados);

            let _totalProdutos = 0;
            if (dadosArmazenados.produtos != '') {
                dadosArmazenados.produtos.forEach((itemprod) => {
                    _totalProdutos += parseFloat(itemprod.produto.valorAte)
                });
            }

            if (dadosArmazenados.avulsos != '') {
                if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                    dadosArmazenados.avulsos.forEach((itemavul) => {
                        _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                    });
                }
            }

            //if (dadosArmazenados.foto != '') {
            //    if (dadosArmazenados.foto[0].imagemUrl != "Sem foto") {
            //        dadosArmazenados.foto.forEach((itemfoto) => {
            //            _totalProdutos += parseFloat(itemfoto.valor)
            //        });
            //    }
            //}

            if (dadosArmazenados.agenda != '') {
                if (dadosArmazenados.agenda[0].periodoEntrega != '') {
                    _totalProdutos += parseFloat(dadosArmazenados.agenda[0].periodoEntrega.valorFrete)

                }
            }

            this.TotalProdutos = _totalProdutos;
        },
        computed: {
            createOrder: function () {
                return (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                reference_id: "COMPRALABODEGUITA",
                                amount: {
                                    value: this.TotalProdutos.toString(),
                                },
                            },
                        ],
                    });
                }
            },
            onApprove: function () {
                return (data, actions) => {
                    return actions.order.capture().then(function (orderData) {
                        console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                        var transaction = orderData.purchase_units[0].payments.captures[0];

                        let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        dadosArmazenados.pagamento = []
                        dadosArmazenados.pagamento.push({
                            FormaPgto: 'Paypal - Transaction ' + transaction.status + ': ' + transaction.id,
                            TipoPgto: 'cartao',
                            CodId: '4',
                            ValorTotal: this.TotalProdutos,
                            FotoComprova: '',
                        });
                        localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))
                        this.$redirect_reload("/pedidoRealizado");
                    });
                }
            },
            onShippingChange: function () {
                return (data, actions) => {
                    if (data.shipping_address.country_code != 'BR') {
                        return actions.reject();
                    }
                    return actions.resolve();
                }
            },
            onError: function () {
                return (err) => {
                    //console.log(err);
                    this.$mensagem_normal("N�o foi poss�vel efetuar o pagamento. - COD: " + err);
                }
            },
            style: function () {
                return {
                    shape: 'rect',
                    color: 'blue',
                    layout: 'horizontal',
                    size: 'responsive',
                    label: 'paypal',
                    tagline: true
                }
            },
        },
    };
</script>