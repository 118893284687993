<template>
  
  <section class="carrinho">

    <section class="main-minha_conta">
    
      <div class="container">
        <div class="row">
          <!-- Sidenav -->
          <div class="col-lg-4 col-xl-3">
            
              <ul class="lista-sidebar-suporte_links">
                  <h5 class="title-sidebar-suporte mb-4">Suporte</h5>
                  <li>
                      <router-link to="/comoComprar" class="texto">
                          <i class="fas fa-angle-double-right icon-arrow"></i>
                          Como Comprar
                      </router-link>
                  </li>
                  <li>
                      <router-link to="/comoPagar" class="texto">
                          <i class="fas fa-angle-double-right icon-arrow"></i>
                          Como Pagar
                      </router-link>
                  </li>
                  <li>
                      <router-link to="/entrega" class="texto">
                          <i class="fas fa-angle-double-right icon-arrow"></i>
                          Entregas
                      </router-link>
                  </li>
                  <li>
                      <router-link to="/frete" class="texto">
                          <i class="fas fa-angle-double-right icon-arrow"></i>
                          Fretes
                      </router-link>
                  </li>
                  <li>
                      <router-link to="/duvidasfrequentes" class="texto">
                          <i class="fas fa-angle-double-right icon-arrow"></i>
                          Dúvidas Frequentes
                      </router-link>
                  </li>
                  <li>
                      <router-link to="/trocasdevolucoes" class="texto">
                          <i class="fas fa-angle-double-right icon-arrow"></i>
                          Trocas e Devoluções
                      </router-link>
                  </li>
                  <li>
                      <router-link to="/politicacookies" class="texto active">
                          <i class="fas fa-angle-double-right icon-arrow"></i>
                          Política Cookies
                      </router-link>
                  </li>
                  <li>
                      <router-link to="/termosuso" class="texto">
                          <i class="fas fa-angle-double-right icon-arrow"></i>
                          Termos de Uso
                      </router-link>
                  </li>
                  <li>
                      <router-link to="/politicaprivacidade" class="texto">
                          <i class="fas fa-angle-double-right icon-arrow"></i>
                          Política de Privacidade
                      </router-link>
                  </li>
              </ul>             
          </div>
          <!-- Main -->
          <div class="col-lg-10 col-xl-9 mt-5 mt-lg-0">
            <h3 class="titulo-principal-suporte mb-4">{{ Titulo }}</h3>
            <!-- <h6 class="subtitulo h5 mb-4">Meus Pedidos</h6> -->
            <div class="row">
              <div class="col">
                <div class="content">
                  <div v-html="Texto"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

  </section>

</template>

<script>

    export default {
        components: {
        },
        data() {
            return {
                Retorno: [],
                Titulo: '',
                Texto: '',
            }
        },
        methods: {
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/politica%20de%20cookies")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;

                    if (this.Retorno != null) {
                        this.Titulo = this.Retorno[0].nome;
                        this.Texto = this.Retorno[0].resumo1;
                    }

                });
        },
        mounted() {

        },
    }

</script>

<style scoped>

  @import '/css_cart/carrinho.css';
  @import '/css_cart/minha_conta.css';
  @import '/css/suporte.css';
    @import '/styles/bootstrap4/bootstrap.min.css';
    @import '/plugins/fontawesome-free-5.0.1/css/fontawesome-all.css';
    @import '/plugins/OwlCarousel2-2.2.1/owl.carousel.css';
    @import '/plugins/OwlCarousel2-2.2.1/owl.theme.default.css';
    @import '/plugins/OwlCarousel2-2.2.1/animate.css';
    @import '/plugins/slick-1.8.0/slick.css';
    @import '/styles/shop_styles.css';
    @import '/styles/shop_responsive.css';
    @import '/styles/main_styles.css';
    @import '/styles/responsive.css';
    @import '/styles/th__style.css';

  
</style>