<template>

    <section class="main-carrinho">
        <section class="" :style="{ backgroundImage: 'url(' + $Url + fundotela + ')',backgroundColor:'#e8eaf6',backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight: '665px' }">
            <div>
                <div class="container">
                    <div class="row py-5">
                        <div class="col-lg-7 hidden-sm-md">
                            <div class="carrinho-bg set-img">

                            </div>
                        </div>
                        <div class="col-lg-5">
                            <div class="box_content">
                                <h3 class="titulo-principal text-center mb-5">{{ Titulos[0].nome }}</h3>
                                <div class="input-group mb-3">
                                    <input v-model="Formulario.Email" type="text" class="form-control form-control-icone" placeholder="Email" onfocus="this.placeholder=''" onblur="this.placeholder='Email'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="AbreSenha || AbreCad" class="input-group mb-3">
                                    <input v-model="Formulario.Senha" type="password" class="form-control form-control-icone" placeholder="Senha" onfocus="this.placeholder=''" onblur="this.placeholder='Senha'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-lock"></i>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="AbreCad" class="input-group mb-3">
                                    <input v-model="Formulario.Nomecli" type="text" class="form-control form-control-icone" placeholder="Nome Completo*" onfocus="this.placeholder=''" onblur="this.placeholder='Nome Completo*'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-user-circle"></i>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="AbreCad" class="input-group mb-3">
                                    <input v-model="Formulario.Whatscli" type="text" class="form-control form-control-icone" placeholder="Whatsapp*" onfocus="this.placeholder=''" onblur="this.placeholder='Whatsapp*'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-phone"></i>
                                        </span>
                                    </div>
                                </div>
                                <a v-if="AbreSenha" class="navbar_detalhes-link" @click="showModal_senha=true">
                                    Esqueci a senha
                                </a>
                                <!-- <button class="btn btn-success w-100 mt-4">Fazer Login</button> -->
                                <button v-if="!AbreSenha && !AbreCad" @click="ValidaCliente()" class="btn btn-success w-100 mt-4">{{ Titulos[0].textoBtn1 }}</button>
                                <button v-if="AbreSenha" @click="entrar()" class="btn btn-success w-100 mt-4">{{ Titulos[0].textoBtn1 }}</button>
                                <button v-if="AbreCad" @click="carregaCliente()" class="btn btn-success w-100 mt-4">{{ Titulos[0].textoBtn1 }}</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Modal esqueci a senha -->
        <Modal v-model="showModal_senha">

            <section class="modal-senha">
                <div class="container">
                    <h3 class="titulo-principal text-center">Esqueci a Senha</h3>
                    <div class="row">
                        <div class="col">
                            <form action="#" class="p-4 p-lg-5">
                                <div class="input-group mb-3">
                                    <input type="text" v-model="emailreset" class="form-control form-control-icone" placeholder="Email" onfocus="this.placeholder=''" onblur="this.placeholder='Email'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                </div>
                                <a @click="EnviarResetSenha()" class="btn btn-success w-100">Recuperar a senha</a>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </Modal>
    </section>

</template>

<script>

    import VueModal from '@kouts/vue-modal'
    import moment from 'moment';


    export default {
        components: {
            'Modal': VueModal,
        },
        data() {
            return {
                Titulos: {},
                Formulario: {
                    Email: '',
                    Senha: '',
                    Nomecli: '',
                    Whatscli: '',
                },
                showModal_senha: false,
                AbreSenha: false,
                AbreCad: false,
                Clientes: [],
                ClientesGeral: [],
                Cliente: [],
                fundotela: '',
                DadosCompra: {
                    produtos: [],
                    mensagem: [],
                    agenda: [],
                    foto: [],
                    avulsos: [],
                    baloes: [],
                    informacao: [],
                    pagamento: [],
                    cupom: [],
                },
                ClientesGeralReset: [],
                Contato: {
                    NomeEmpresa: "",
                    Assunto: "",
                    Destinatario: "",
                    EmailResposta: "",
                    TextoEmail: "",
                    Anexo: "",
                    NomeAnexo: "",
                    TipoAnexo: "",
                    temExperiencia: false,
                    temProduto:false,
                },
            }
        },
        created() {

            let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.produtos != '') {
                    dadosArmazenados.produtos.forEach((itemex) => {
                        if (itemex.produto.segmentos[0].segmentoProdNivel1.segmentoProdNivel1Id == 9) {
                            this.temExperiencia = true;
                        }
                        else {
                            this.temProduto = true;
                        }
                    });
                }
            }

            this.CheckLogin();
            //----------------- Titulos planos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textologin")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Titulos = retornoAPI;

                    if (this.Titulos[0].institucionalImg != '') {
                        this.fundotela = this.Titulos[0].institucionalImg[0].urlImagem
                    }
                    else {
                        this.fundotela = '';
                    }
                });

        },
        methods: {
            EnviarResetSenha() {
                if (this.emailreset == '') {
                    this.$mensagem_normal("Por favor preencha o e-mail da conta.");
                }
                else {
                    this.$http
                        .get(this.$apiUrl + "/clientes")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ClientesGeralReset = tiu2.filter(x => x.email.toLowerCase().trim() == this.emailreset.toLowerCase().trim());
                            //console.log(this.ClientesGeralReset)
                            if (this.ClientesGeralReset != '') {
                                //emailrecuperasenha
                                let objetoreset = {
                                    clienteid: parseInt(this.ClientesGeralReset[0].clienteId, 10),
                                    site: 'labodeguita.com.br',
                                    texto: 'Sua solicita&Ccedil;&atilde;o de altera&Ccedil;&atilde;o de senha foi efetuada.',
                                };
                                //console.log(objetoreset)
                                //console.log(this.$apiUrl + "/clientes/emailrecuperasenha")
                                this.$http.post(this.$apiUrl + "/clientes/emailrecuperasenha", objetoreset).then(
                                    (response) => {
                                        // get body data
                                        objetoreset = response.body;
                                        this.$mensagem_normal("Senha enviada para seu e-mail.");
                                        this.$cookie.delete("usuarioLabodeguita");
                                    },
                                    (response) => {
                                        // error callback
                                        this.$mensagem_erro(response.body);
                                    }
                                );
                            }
                            else {
                                this.$mensagem_normal("Usu�rio n�o cadastrado no sistema.");
                            }
                        });
                }
            },
            ValidaCliente() {
                if (this.Formulario.Email == '') {
                    this.$mensagem_normal("Por favor preencha o e-mail");
                }
                else {
                    if (!this.Abresenha) {
                        this.$http
                            .get(this.$apiUrl + "/clientes")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.ClientesGeral = tiu2;
                                this.Clientes = tiu2.filter(x => x.email.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());

                                if (this.Clientes != '') {
                                    this.$mensagem_normal("Usuario ja cadastrado, informe sua senha.");
                                    this.AbreSenha = true;
                                    this.AbreCad = false;
                                }
                                else {
                                    this.$mensagem_normal("Usuario novo, informe alguns dados.");
                                    this.AbreCad = true;
                                    this.AbreSenha = false;
                                }

                            });
                    }
                }
            },
            entrar() {
                let testacli = this.ClientesGeral.filter(x => x.email.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());

                if (testacli == '') {
                    this.$mensagem_normal("Usuario novo, informe alguns dados.");
                    this.AbreCad = true;
                    this.AbreSenha = false;
                }
                else {
                    let _usuario = {
                        email: this.Formulario.Email,
                        senha: this.$sha1(this.Formulario.Senha),
                    };
                    let usuario = {};
                    this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                        (response) => {
                            // get body data
                            usuario = response.body;
                            let _dados = {
                                nomeUsuario: '',
                                usuarioId: '',
                                senha: '',
                                email: '',
                            }
                            _dados.nomeUsuario = usuario.nomeEmpresa;
                            _dados.usuarioId = usuario.clienteId;
                            _dados.senha = usuario.senha;
                            _dados.email = usuario.email;

                            this.$cookie.set("usuarioLabodeguita", JSON.stringify(_dados), {
                                expires: "120m",
                            });

                            let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                            if (!dadosArmazenados) {
                                dadosArmazenados = this.DadosCompra;
                                localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))
                                this.$redirect_reload("/meuspedidos");
                            } else {
                                dadosArmazenados = JSON.parse(dadosArmazenados);
                                if (dadosArmazenados.produtos != '') {

                                    if (dadosArmazenados.pagamento != '') {
                                        this.$redirect_reload("/pedidoRealizado");
                                    }
                                    else {
                                        if (this.temExperiencia && !this.temProduto) {
                                            this.$redirect_reload("/novacontapagamento");
                                        }
                                        else {
                                            this.$redirect_reload("/novacontaendereco");
                                        }                                      
                                    }
                                } else {
                                    this.$redirect_reload("/meuspedidos");
                                }

                            }
                        },
                        (response) => {
                            // error callback
                            this.$cookie.delete("usuarioLabodeguita");
                            this.$mensagem_erro(response.body);
                        }
                    );
                }

            },
            CheckLogin() {
                let usuarioTmp = {
                    email: '',
                    senha: '',
                }
                usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
                if (usuarioTmp != null) {
                    let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                    if (!dadosArmazenados) {
                        dadosArmazenados = this.DadosCompra;
                        localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))
                        this.$redirect_reload("/meuspedidosdetalhe");
                    } else {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        if (dadosArmazenados.produtos != '') {

                            if (dadosArmazenados.pagamento != '') {
                                this.$redirect_reload("/pedidoRealizado");
                            }
                            else {
                                if (this.temExperiencia && !this.temProduto) {
                                    this.$redirect_reload("/novacontapagamento");
                                }
                                else {
                                    this.$redirect_reload("/novacontaendereco");
                                }       
                            }
                        } else {
                            this.$redirect_reload("/meuspedidos");
                        }
                    }

                }
            },
            carregaCliente() {
                if (this.Formulario.Nomecli == '') {
                    this.$mensagem_normal("Por favor preencha o nome completo");
                } else if (this.Formulario.Whatscli == '') {
                    this.$mensagem_normal("Por favor preencha o whatsapp");
                } else if (this.Formulario.Email == '') {
                    this.$mensagem_normal("Por favor preencha o e-mail");
                } else if (this.Formulario.Senha == '') {
                    this.$mensagem_normal("Por favor preencha a Senha");
                } else {

                    let testacli = this.ClientesGeral.filter(x => x.email.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());

                    if (testacli != '') {
                        this.$mensagem_normal("Usuario ja cadastrado, informe sua senha.");
                        this.AbreSenha = true;
                        this.AbreCad = false;
                    }
                    else {
                        let _cliente =
                        {
                            clienteId: 0,
                            nomeEmpresa: this.Formulario.Nomecli,
                            email: this.Formulario.Email,
                            whatsapp: this.Formulario.Whatscli,
                            telefone: this.Formulario.Whatscli,
                            tipoCliente: { tipoClienteId: 1 },
                            ativo: true,
                            visivel: true,
                            DataCadastro: moment().format(),
                            senha: this.$sha1(this.Formulario.Senha),
                        };

                        this.$http.post(this.$apiUrl + "/clientes/", _cliente).then(
                            (response) => {
                                this.Cliente = response.body;
                                let codcli = this.Cliente.clienteId;

                                let _dados = {
                                    nomeUsuario: '',
                                    usuarioId: '',
                                    senha: '',
                                    email: '',
                                }
                                _dados.nomeUsuario = this.Formulario.Nomecli;
                                _dados.usuarioId = codcli;
                                _dados.senha = this.$sha1(this.Formulario.Senha);
                                _dados.email = this.Formulario.Email;

                                this.$cookie.set("usuarioLabodeguita", JSON.stringify(_dados), {
                                    expires: "120m",
                                });

                                if (this.temExperiencia && !this.temProduto) {
                                    this.$redirect_reload("/novacontapagamento");
                                }
                                else {
                                    this.$redirect_reload("/novacontaendereco");
                                }            

                            });
                    }
                }
            },
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/login.css';
</style>