<template>
  
  <section class="vinhos">

    <TopoInternas titulo="Vinhos"></TopoInternas>

    <div class="shop">
				<div class="container">
					<div class="row">
            <!-- Side -->
						<div class="col-lg-3">

							<div class="row">
								<div class="col-md-6 col-lg-12">
									<!-- Shop Sidebar -->
									<div class="shop_sidebar">
										<!-- Categoria -->
										<div class="sidebar_section">
											<div class="sidebar_title">Segmento</div>
											<ul class="sidebar_categories">
												<li><router-link to="/vinhos">Tinto</router-link></li>
												<li><router-link to="/vinhos">Branco</router-link></li>
												<li><router-link to="/vinhos">Rosé</router-link></li>
												<li><router-link to="/vinhos">Espumante</router-link></li>
											</ul>
										</div>
										<div class="sidebar_section">
											<div class="sidebar_title my-4">nome</div>
											<input type="text" class="form-control form-control-sidebar" placeholder="Digite o nome do vinho">
										</div>
										<!-- Filtro de valor -->
										<div class="sidebar_section filter_by_section">
											<div class="sidebar_subtitle">Valor</div>
											<div class="filter_price">
												<div id="slider-range" class="slider_range"></div>
												<!-- <p class="mt-1">Faixa: <input type="text" id="amount" class="amount" readonly style="border:0; font-weight:bold;"></p> -->
											</div>
										</div>
										
									</div>
								</div>
								<div class="col-md-6 col-lg-12">

									<!-- Select Marca -->
										<div class="sidebar_section sidebar-select">
											<div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-4">País</div>
										
											<v-select v-model="Statuselected" @input="MudaStatus"
												:options="Status"
												:reduce="nome => nome.valor"
												single-line
												label="nome" class="style-chooser form-control-sidebar">
											</v-select>								
										
										</div>

										<!-- Select Cidade -->
										<div class="sidebar_section sidebar-select">
											<div class="sidebar_subtitle brands_subtitle mb-4">Uva</div>
											
											<v-select v-model="Statuselected" @input="MudaStatus"
												:options="Status"
												:reduce="nome => nome.valor"
												single-line
												label="nome" class="style-chooser form-control-sidebar">
											</v-select>							
										
										</div>

										<!-- Select Estado -->
										<div class="sidebar_section sidebar-select">
											<div class="sidebar_subtitle brands_subtitle mb-4">Marca</div>
											
											<v-select v-model="Statuselected" @input="MudaStatus"
												:options="Status"
												:reduce="nome => nome.valor"
												single-line
												label="nome" class="style-chooser form-control-sidebar">
											</v-select>								
										
										</div>

								</div>
							</div>

						</div>

						<div class="col-lg-9">

							<div class="row">
								<div class="col">
									<div class="shop_bar mt-5  clearfix">
										<div class="row">
											<div class="col-md-6">
												<div class="shop_product_count mb-4 mb-md-0">
													<span>186</span> produtos encontrados
												</div>
											</div>
											<div class="col-md-6">
												<div class="shop_sorting">
												<span>ordenar por: </span>
												<ul>
													<li>
														<span class="sorting_text">mais populares<i class="fas fa-chevron-down"></i></span>
														<ul>
															<li class="shop_sorting_button" data-isotope-option='{ "sortBy": "original-order" }'>avaliação</li>
															<li class="shop_sorting_button" data-isotope-option='{ "sortBy": "name" }'>nome</li>
															<li class="shop_sorting_button" data-isotope-option='{ "sortBy": "price" }'>valor</li>
														</ul>
													</li>
												</ul>
											</div>
											</div>
										</div>
																				
									</div>
								</div>
							</div>

							<div class="row">

								<div class="col-md-6 col-lg-3 col-xl mb-5" v-for="produto in arr_produtos" :key="produto.id">

									<div class="product_item border_active text-center">
										<router-link to="/produtoDetalhe">
											<div class="product_image d-flex flex-column align-items-center justify-content-center">
												<img :src="produto.imagem" alt="Imagem do produto">
											</div>
										</router-link>

										<div class="product_content">
											<div class="product_price">R$ {{ produto.preco }}</div>
											<div class="product_name">
												<div>
													<router-link to="/produtoDetalhe">{{ produto.nome }}</router-link>
												</div>
											</div>
										</div>
									</div>

								</div>

							</div>
  

							<div class="shop_content">

								<!-- Shop Page Navigation -->
								<div class="shop_page_nav d-flex flex-row justify-content-center">
									<div class="page_prev d-flex flex-column align-items-center justify-content-center"><i class="fas fa-chevron-left"></i></div>
									<ul class="page_nav d-flex flex-row">
										<li><a href="#">1</a></li>
										<li><a href="#">2</a></li>
										<li><a href="#">3</a></li>
										<li><a href="#">...</a></li>
										<li><a href="#">9</a></li>
									</ul>
									<div class="page_next d-flex flex-column align-items-center justify-content-center"><i class="fas fa-chevron-right"></i></div>
								</div>

							</div>

						</div>
					</div>
				</div>
			</div>

  </section>

</template>

<script>
import TopoInternas from '../shared/TopoInternas.vue'

export default {
  components: {
    TopoInternas
  },
	methods: {
		MudaStatus(value) {
			this.Ativo = value;
		}
	},
  data() {
    return {
      arr_produtos: [
        {
          id: 1,
          imagem: 'img/cerveja1.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        },
        {
          id: 2,
          imagem: 'img/cerveja2.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        },
        {
          id: 3,
          imagem: 'img/cerveja3.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        },
        {
          id: 4,
          imagem: 'img/cerveja2.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        },
        {
          id: 5,
          imagem: 'img/cerveja1.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        },
				{
          id: 6,
          imagem: 'img/cerveja1.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        },
        {
          id: 7,
          imagem: 'img/cerveja2.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        },
        {
          id: 8,
          imagem: 'img/cerveja3.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        },
        {
          id: 9,
          imagem: 'img/cerveja2.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        },
        {
          id: 10,
          imagem: 'img/cerveja1.jpg',
					preco: '123,45',
					nome: 'Nome do Produto'
        }
      ],

			Statuselected: 'Labodeguita',
      Ativo: null,
      Status: [
        { 
          nome: "Opção A",
          valor: true 
        },
        { 
          nome: "Opção B", 
          valor: false 
        }
      ]
    }
  },
	
}
</script>

<style scoped>

  @import '/css/vinhos.css';

  ul {
    padding-left: unset;
  }

  .form-control-sidebar {
    max-width: 80%;
  }

  
	.product_item {	
		width: 100%;
		background: #FFFFFF;
		box-shadow: 6px 4px 8px #ccc;
		padding-top: 100px;
		padding-bottom: 100px;
		transition: all .2s ease-in;
	}

	.product_item:hover {	
		box-shadow: 10px 10px 8px #ccc;
	}

	/********************
	Responsive
	********************/

	/* Smartphones */
	@media (max-width:767px) {
		.product_item {
			left: 0px;
		}	
		.shop_sorting {
    float: initial;
		}
	}

	/* Tudo, menos desktop */
	@media (max-width:1199px) {
		.product_item {	
			height: min-content;
			padding-top: 60px;
			padding-bottom: 40px;
		}
		.product_price {
			margin-top: 60px;
		}			
	
	}

</style>