<template>
  
  <TopoInternas titulo="Empório"></TopoInternas>

</template>

<script>
import TopoInternas from '../shared/TopoInternas.vue'

export default {
  components: {
    TopoInternas
  }
}
</script>