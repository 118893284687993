<template>
  
  <section class="experiencias">

    <TopoInternas titulo="Experiências"></TopoInternas>
    <div class="container mb-5">
      
      <div class="row">
        <div class="col text-center">
          <div class="exp_descricao th__section-pad-50">
            <h5>Texto falando sobre as experiências lorem iosum dolor sit amet consecteteur...</h5>
          </div>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-4 col-xl-3 mb-4 mb-md-0">
          <div class="sidebar_section sidebar-select">

            <v-select v-model="Statuselected" @input="MudaStatus"
              :options="Status"
              :reduce="nome => nome.valor"
              single-line
              label="nome" class="style-chooser">
            </v-select>				

          </div>
        </div>
        <div class="col-md-4 col-xl-3">
          <div class="sidebar_section sidebar-select">

            <v-select v-model="Statuselected" @input="MudaStatus"
              :options="Status"
              :reduce="nome => nome.valor"
              single-line
              label="nome" class="style-chooser">
            </v-select>		

          </div>
        </div>
      </div>

      <div class="row">

        <div class="col-md-6 col-lg-4" v-for="experiencia in arr_experiencias" :key="experiencia.id">
          <div class="img-item set-img" :style="{ backgroundImage: 'url(' + require('@/assets/img/03.png') + ')' }"></div>         
          <div class="content">
            <span class="subtexto">{{ experiencia.data }}</span><i class="fas fa-grip-lines-vertical text-secondary mx-4"></i><span class="subtexto">das {{ experiencia.hora }}</span>
            <h3 class="titulo_card mt-3" v-html="experiencia.descricao"></h3>
            <router-link to="/experienciaDetalhe" class="btn btn-success">Ver Mais Detalhes</router-link>
          </div>         
        </div>

      </div>
    </div>
  </section>

</template>

<script>
import TopoInternas from '../shared/TopoInternas.vue'
 import vSelect from 'vue-select';

export default {
  components: {
    TopoInternas,
    vSelect
  },
  data() {
    return {
      arr_experiencias: [
        {
          id: 1,
          data: '10/10/2021',
          hora: '19h às 21h',
          descricao: 'Título descrição 1 <br /> lorem ipsum'
        },
        {
          id: 2,
          data: '20/10/2021',
          hora: '19h às 21h',
          descricao: 'Título descrição 2 <br /> lorem ipsum'
        },
        {
          id: 3,
          data: '30/10/2021',
          hora: '19h às 21h',
          descricao: 'Título descrição 3 <br /> lorem ipsum'
        },
      ],

      Statuselected: null,
      Ativo: null,
      Status: [
        { 
          nome: "Ativo",
          valor: true 
        },
        { 
          nome: "Inativo", 
          valor: false 
        }
      ]   
    }
  },
  MudaStatus(value) {
    this.Ativo = value;
  }
}
</script>

<style scoped>

  .img-item {
    border-radius: 5px;
    box-shadow: 1px 1px 10px #303030;
    height: 300px;
  }
  .content {
    padding: 10px 30px 30px 30px;
  }

  /* Smartphones */
  @media (max-width:767px) {
    .content {
    padding: 30px 10px 50px 10px;
  }
  }

</style>