<template>

    <section class="home">

                   <div class="col hidden-xl hidden-lg" style="margin-top:0px;margin-bottom:20px;">
                        <form action="#" class="">
                            <input type="search" v-model="textobusca" required="required" class="header_search_input" placeholder="Busque aqui o seu produto" style="padding:10px;">
                            <button @click="ValidaBusca()" class="header_search_button trans_300"><i class="fas fa-search"></i></button>
                        </form>
                    </div> 


        <div v-for="ItemHome in ItensHome" :key="ItemHome.homeMontaItemId" style="z-index: 0">

            <!--Banner Slider -->
            <b-carousel v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 1" id="carousel-fade" :class="validacss(ItemHome,null)"
                        controls
                        indicators
                        fade
                        v-model="slide"
                        :interval="6000"
                        img-width="1350"
                        img-height="330"
                        @sliding-end="endSlide"
                        @sliding-start="initCarousel"
                        style="text-shadow: 2px 3px 3px #222222;">
                <b-carousel-slide v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" caption="" :img-src="$Url + ItemImagem.urlImg">
                </b-carousel-slide>
            </b-carousel>

            <!--Banner Carrossel -->
            <carousel v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 2" :class="validacss(ItemHome,'respiro')"
                      :perPageCustom="[[340, 4],[768, 5], [1024,8], [1600,12]]"
                      autoplay
                      :autoplayTimeout=4000
                      autoplayHoverPause
                      paginationEnabled
                      :paginationSize=0
                      :loop="true">
                <slide v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" class="produto-item" style="text-align:center;">
                    <a href="" @click="$redirect_reload(ItemImagem.link)" class="produto-link">
                        <img class="imagem" :src="$Url + ItemImagem.urlImg" alt="" style="width: 100%;padding: 10px;">
                    </a>
                    <a href="" @click="$redirect_reload(ItemImagem.link)" class="product_price">{{ ItemImagem.texto }}</a>
                </slide>
            </carousel>

            <!--Banner linha inteira -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 9" :class="validacss(ItemHome,'banner-linha respiro')">
                <div v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,1)" :key="ItemImagem.homeBannerItemId">
                    <a href="" @click="$redirect_reload(ItemImagem.link)" class="produto-link">
                        <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                    </a>
                </div>
            </div>

            <!--Banner 1 por linha  -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 4" :class="validacss(ItemHome,'container banner-1linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,1)" :key="ItemImagem.homeBannerItemId" class="col-md-12">
                        <a href="" @click="$redirect_reload(ItemImagem.link)" class="produto-link">
                            <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                        </a>
                    </div>
                </div>
            </div>

            <!--Banner 2 por linha  -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 5" :class="validacss(ItemHome,'container banner-2linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,2)" :key="ItemImagem.homeBannerItemId" class="col-md-6 col-6 mb-4">
                        <a href="" @click="$redirect_reload(ItemImagem.link)" class="produto-link">
                            <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                        </a>
                    </div>
                </div>
            </div>

            <!--Banner 3 por linha  -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 6" :class="validacss(ItemHome,'container banner-3linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,3)" :key="ItemImagem.homeBannerItemId" class="col-md-4 col-12 mb-4">
                        <a href="" @click="$redirect_reload(ItemImagem.link)" class="produto-link">
                            <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                        </a>
                    </div>
                </div>
            </div>

            <!--Banner 4 por linha  -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 7" :class="validacss(ItemHome,'container banner-4linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,4)" :key="ItemImagem.homeBannerItemId" class="col-md-3 col-6 mb-4">
                        <a href="" @click="$redirect_reload(ItemImagem.link)" class="produto-link">
                            <div class="img-item">
                                <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <!--Banner 6 por linha  -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 8" :class="validacss(ItemHome,'container banner-6linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,6)" :key="ItemImagem.homeBannerItemId" class="col-md-2 col-4 mb-4">
                        <a href="" @click="$redirect_reload(ItemImagem.link)" class="produto-link">
                            <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                        </a>
                    </div>
                </div>
            </div>

            <!--linha destaque -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 3" :class="validacss(ItemHome,'produtos respiro')">
                <div class="mb-4">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <!-- Deals -->
                                <div class="deals">
                                    <div class="deals_title">Destaque</div>
                                    <div class="deals_slider_container">
                                        <!-- Deals Slider -->
                                        <div class="owl-theme deals_slider">
                                            <!-- Deals Item 1 -->
                                            <div class="owl-item deals_item" v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true && x.produto.visivel == true).slice(0,1)" :key="ItemImagem.homeBannerItemId">
                                                <figure id="container" class="vfigure2">
                                                    <div v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId">
                                                        <img :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" />
                                                        <figcaption :title="ItemEtiqueta.nomePremio" class="vfigcaption2">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                                    </div>
                                                </figure>
                                                <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                    <div class="deals_image">
                                                        <img v-if="ItemImagem.produto.imagens!=''" :src="$Url + ItemImagem.produto.imagens[0].urlImg" alt="">
                                                        <img v-else :src="$Url + '/imagens/indisponivel.jpg'" alt="" />
                                                    </div>
                                                </a>
                                                <div class="deals_content">
                                                    <div class="deals_info_line d-flex flex-row justify-content-start">
                                                        <div class="deals_item_category"><a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))"><span v-html="ItemImagem.produto.resuno"></span></a></div>
                                                    </div>
                                                    <div class="deals_info_line">
                                                        <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                            <div class="deals_item_name">{{  ItemImagem.produto.nomeProduto }}</div>
                                                        </a><br />
                                                        <div class="deals_item_price ml-auto" v-if="ItemImagem.produto.descricao4=='' || ItemImagem.produto.descricao4==null">R$ {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</div>
                                                        <div class="deals_item_price ml-auto" v-else>Loja Parceira</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Featured -->
                            <div class="col-lg-8">
                                <div class="tabbed_container">
                                    <div class="tabs row">
                                        <ul class="clearfix">
                                            <li class="active">{{ ItemHome.homeBanner.nomeBloco }}</li>
                                        </ul>
                                        <div class="tabs_line"><span></span></div>
                                    </div>
                                    <!-- Product Panel -->
                                    <div class=" panel active" style="padding-left: 20px;">
                                        <div class="row">
                                            <!-- Slider Item (up 1) -->
                                            <div class="featured_slider_item col-3 col-md-3" v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true && x.produto.visivel == true).slice(1,9)" :key="ItemImagem.homeBannerItemId">
                                                <div class="product_grid">
                                                    <!-- Product Item 1 -->
                                                    <figure id="container" class="vfigure">
                                                        <div v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId">
                                                            <img class="imgcelet" :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" />
                                                            <figcaption :title="ItemEtiqueta.nomePremio" class="vfigcaption peq">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                                        </div>
                                                    </figure>
                                                    <div class="product_item discount" style="padding-right: 20px;">
                                                        <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                            <div class="product_image d-flex flex-column align-items-center justify-content-center">
                                                                <img v-if="ItemImagem.produto.imagens!=''" :src="$Url + ItemImagem.produto.imagens[0].urlImg" style="width:150px;" alt="">
                                                                 <img v-else :src="$Url + '/imagens/indisponivel.jpg'" alt="" /> 
                                                            </div>
                                                        </a>
                                                        <div class="product_content">
                                                            <br /><span v-if="ItemImagem.produto.valorDe > 0" class="product_price">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                                            <p>
                                                                R$ <span class="product_price"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                                            </p>
                                                            <div class="product_name"><div><a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">{{  ItemImagem.produto.nomeProduto }}</a></div></div>
                                                        </div>
                                                        <ul class="product_marks">
                                                            <li v-if="ItemImagem.produto.etiqueta != null" class="bestsellers_mark bestsellers_discount" :style="{color:ItemImagem.produto.etiquetaCorFonte,backgroundcolor:ItemImagem.produto.etiquetaCorFundo}">{{ ItemImagem.produto.etiqueta }}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 10" :class="validacss(ItemHome,'produtos container respiro')">
                <div class="col-md-12 text-center">
                    <h3 class="titulo_section titulo_section-xl text-center">{{ ItemHome.homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','') }}</h3>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-6 mb-4 mb-lg-0" v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true && x.produto.visivel == true).slice(0,2)" :key="ItemImagem.homeBannerItemId">
                        <div class="produto-card produto-card_2">
                            <figure id="container" class="vfigure">
                                <div v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId">
                                    <img :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" />
                                    <figcaption :title="ItemEtiqueta.nomePremio" class="vfigcaption">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                </div>
                            </figure>
                            <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                <div class="produto-img set-img set-tam2" :style="{ backgroundImage: 'url('  + $Url + ItemImagem.produto.imagens[0].urlImg +  ')', backgroundSize:'contain' }">
                                    <img v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                    <div class="middle middle_2">
                                        <div class="text">Ver Mais Detalhes</div>
                                    </div>
                                </div>
                            </a>
                            <div class="produto-content" style="padding-top:10px;">
                                <h6 class="product_name">{{  ItemImagem.produto.nomeProduto }}</h6>
                                <div style="display:inline-flex;">
                                    <span v-if="ItemImagem.produto.valorDe > 0" class="product_price" style="font-size:11px;padding-top:6px;padding-right:3px;">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                    <div v-if="ItemImagem.produto.descricao4=='' || ItemImagem.produto.descricao4==null">
                                        R$ <span class="product_price"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                    </div>
                                    <div v-else>
                                        <span class="product_price"> Loja Parceira</span>
                                    </div>
                                </div>
                                <!--<router-link  :to="/produtoDetalhe/ + ItemImagem.produto.produtoId" class="btn btn-card">Ver Detalhes</router-link>-->
                                <br /><a v-if="ItemImagem.produto.indisponivel == true" class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--3 por linha -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 11" :class="validacss(ItemHome,'respiro')">
                <div class="best_sellers">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="tabs clearfix tabs-right tabbed_container">
                                    <h2 class="new_arrivals_title">{{ ItemHome.homeBanner.nomeBloco }}</h2>
                                    <div class="tabs_line"><span></span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4 mb-2">
                            <div class="col-lg-4" v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true && x.produto.visivel == true).slice(0,3)" :key="ItemImagem.homeBannerItemId">
                                <div class="bestsellers_item discount">
                                    <figure id="container" class="vfigure" style="top: 30px;padding-left: 51px;">
                                        <div v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId">
                                            <img :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" />
                                            <figcaption :title="ItemEtiqueta.nomePremio" class="vfigcaption">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                        </div>
                                    </figure>
                                    <div class="bestsellers_item_container d-flex flex-row align-items-center justify-content-start">
                                        <div class="bestsellers_image">
                                            <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                <img v-if="ItemImagem.produto.imagens!=''" :src="$Url + ItemImagem.produto.imagens[0].urlImg" alt="">
                                                <img v-else :src="$Url + '/imagens/indisponivel.jpg'" alt="" /> 
                                           </a>
                                        </div>
                                        <div class="bestsellers_content">
                                            <!--<div class="bestsellers_category"><a href="produto-detalhe.html">Headphones</a></div>-->
                                            <div class="bestsellers_name"><a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">{{  ItemImagem.produto.nomeProduto }}</a></div>
                                            <!-- <div class="rating_r rating_r_4 bestsellers_rating"><i></i><i></i><i></i><i></i><i></i></div> -->
                                            <div class="bestsellers_price discount" v-if="ItemImagem.produto.descricao4=='' || ItemImagem.produto.descricao4==null">
                                                R$ {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}
                                                <span v-if="ItemImagem.produto.valorDe > 0">R$ {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                            </div>
                                            <div class="bestsellers_price discount" v-else>
                                                Loja Parceira
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="bestsellers_fav active"><i class="fas fa-heart"></i></div> -->
                                    <ul class="bestsellers_marks">
                                        <li v-if="ItemImagem.produto.etiqueta != null" class="bestsellers_mark bestsellers_discount" :style="{color:ItemImagem.produto.etiquetaCorFonte,backgroundcolor:ItemImagem.produto.etiquetaCorFundo}">{{ ItemImagem.produto.etiqueta }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--4 por linha -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 12" :class="validacss(ItemHome,'respiro')">
                <div class="trends">
                    <div class="trends_background" style="background-color: #993366;"></div>
                    <!-- <div class="trends_overlay"></div> -->
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="trends_container">
                                    <h2 class="trends_title text-white text-center">
                                        {{
                                         ItemHome.homeBanner.nomeBloco
                                        }}
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-4">
                                <div class="trends_slider_container">
                                    <!-- Trends Slider -->
                                    <div class="row">
                                        <!-- Trends Slider Item 1 -->
                                        <div class="col-lg-3 col-6" v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true && x.produto.visivel == true).slice(0,4)" :key="ItemImagem.homeBannerItemId" style="padding-bottom:10px;">
                                            <div class="trends_item discount" style="text-align:center;">
                                                <figure id="container" class="vfigure">
                                                    <div v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId">
                                                        <img class="imgcelet" :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" />
                                                        <figcaption :title="ItemEtiqueta.nomePremio" class="vfigcaption peq">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                                    </div>
                                                </figure>
                                                <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                    <div class="trends_image d-flex flex-column align-items-center justify-content-center">
                                                        <img v-if="ItemImagem.produto.imagens!=''" :src="$Url + ItemImagem.produto.imagens[0].urlImg" alt="">
                                                        <img v-else :src="$Url + '/imagens/indisponivel.jpg'" alt="" /> 
                                                    </div>
                                                    <div class="trends_content" style="height: 90px;">
                                                        <!--<div class="trends_category"><a href="produto-detalhe.html">Smartphones</a></div>-->
                                                        <div class="trends_info clearfix">
                                                            <div class="" style="height:40px;">{{  ItemImagem.produto.nomeProduto }}</div>
                                                            <br />
                                                            <span v-if="ItemImagem.produto.valorDe > 0" class="trends_price"> De <span style="text-decoration: line-through;" >{{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span></span>
                                                            <p v-if="ItemImagem.produto.descricao4=='' || ItemImagem.produto.descricao4==null">
                                                               Por R$ <span class="text-left"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                                            </p>
                                                            <p v-else>
                                                               <span class="text-left"> Loja Parceira</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <ul class="trends_marks">
                                                    <li v-if="ItemImagem.produto.etiqueta != null" class="bestsellers_mark bestsellers_discount" :style="{color:ItemImagem.produto.etiquetaCorFonte,backgroundcolor:ItemImagem.produto.etiquetaCorFundo}">{{ ItemImagem.produto.etiqueta }}</li>
                                                </ul>
                                                <!-- <div class="trends_fav"><i class="fas fa-heart"></i></div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--6 por linha -->
            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 13" :class="validacss(ItemHome,'produtos container respiro')">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="tabs clearfix tabs-right">
                                <div class="new_arrivals_title">{{ ItemHome.homeBanner.nomeBloco }}</div>
                                <div class="tabs_line"><span></span></div>
                            </div>
                            <div class="shop_content">
                                <div class="row">
                                    <div class="col-lg-2 col-6" v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true && x.produto.visivel == true)" :key="ItemImagem.homeBannerItemId">
                                        <div class="product_grid">

                                            <!-- Product Item 1 -->
                                            <div class="product_item discount">
                                                <figure id="container" class="vfigure">
                                                    <div v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId">
                                                        <img :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" />
                                                        <figcaption :title="ItemEtiqueta.nomePremio" class="vfigcaption">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                                    </div>
                                                </figure>
                                                <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                                    <div class="product_image d-flex flex-column align-items-center justify-content-center">
                                                        <img v-if="ItemImagem.produto.imagens!=''" :src="$Url + ItemImagem.produto.imagens[0].urlImg" style="width:150px;" alt="">
                                                        <img v-else :src="$Url + '/imagens/indisponivel.jpg'" alt="" /> 
                                                    </div>
                                                </a>
                                                <div class="product_content">
                                                    <div style="display:inline-flex;padding-top: 25px">
                                                        <span v-if="ItemImagem.produto.valorDe > 0" class="product_price" style="font-size:11px;padding-top:6px;padding-right:3px;">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                                        <div v-if="ItemImagem.produto.descricao4=='' || ItemImagem.produto.descricao4==null">
                                                            R$ <span class="product_price"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                                        </div>
                                                        <div v-else>
                                                            <span class="product_price"> Loja Parceira</span>
                                                        </div>
                                                    </div>
                                                    <div class="product_name"><div><a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">{{  ItemImagem.produto.nomeProduto }}</a></div></div>
                                                </div>
                                                <ul class="product_marks">
                                                    <li v-if="ItemImagem.produto.etiqueta != null" class="bestsellers_mark bestsellers_discount" :style="{color:ItemImagem.produto.etiquetaCorFonte,backgroundcolor:ItemImagem.produto.etiquetaCorFundo}">{{ ItemImagem.produto.etiqueta }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="ItemHome.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 15" :class="validacss(ItemHome,'container')">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-12">
                        <div style="padding-top:20px;">
                            <h3 class="titulo_section titulo_section-xl text-center">{{ ItemHome.homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','') }}</h3>
                        </div>
                        <carousel id="carro2" :class="validacss(ItemHome,null)"
                                  :perPageCustom="[[340, 2],[768, 3], [1024,4], [1600,4]]"
                                  autoplay
                                  :autoplayTimeout=6000
                                  autoplayHoverPause
                                  paginationEnabled
                                  :paginationSize=0
                                  :loop="true">

                            <slide v-for="ItemImagem in ItemHome.homeBanner.homeBannerItens.filter(x => x.visivel == true && x.produto.visivel == true)" :key="ItemImagem.homeBannerItemId" class="produtos respiro">
                                <div class="produto-card produto-card_2">
                                    <!-- Product Item 1 -->
                                    <figure id="container" class="vfigure" style="left: 30px;">
                                        <div v-for="ItemEtiqueta in  ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId">
                                            <img :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" />
                                            <figcaption :title="ItemEtiqueta.nomePremio" class="vfigcaption">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                        </div>
                                    </figure>
                                    <div class="product_item discount">

                                        <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                            <div class="product_image d-flex flex-column align-items-center justify-content-center">
                                                <img v-if="ItemImagem.produto.imagens!=''" :src="$Url + ItemImagem.produto.imagens[0].urlImg" class="set-tam4" alt="">
                                                <img v-else :src="$Url + '/imagens/indisponivel.jpg'" class="set-tam4" alt="" /> 
                                            </div>
                                        </a>
                                        <div class="product_content">
                                            <div style="display:inline-flex;padding-top: 70px">
                                                <span v-if="ItemImagem.produto.valorDe > 0" class="product_price" style="font-size:11px;padding-top:6px;padding-right:3px;">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                                <div v-if="ItemImagem.produto.descricao4=='' || ItemImagem.produto.descricao4==null">
                                                    R$ <span class="product_price"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                                </div>
                                                <div v-else>
                                                    <span class="product_price"> Loja Parceira</span>
                                                </div>
                                            </div>
                                            <div class="product_name"><div><a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId + '/0/' + ItemImagem.produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">{{  ItemImagem.produto.nomeProduto }}</a></div></div>
                                        </div>
                                        <ul class="product_marks">
                                            <li v-if="ItemImagem.produto.etiqueta != null" class="bestsellers_mark bestsellers_discount" :style="{color:ItemImagem.produto.etiquetaCorFonte,backgroundcolor:ItemImagem.produto.etiquetaCorFundo}">{{ ItemImagem.produto.etiqueta }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
        <section class="home_pub mb-5 mt-5">
            <div class="container">
                <div class="row">
                    <div v-for="ItemHome2 in ItensHome2" :key="ItemHome2.institucionalId" class="col-lg-6 mb-5 mb-lg-0 home_pub1">
                        <a class="btn btn-comprar btn-lojaPub" @click="$redirect_reload(ItemHome2.nome)">
                            <h2 style="color: #000; font-size: 1.6rem;">{{ ItemHome2.textoBtn1 }}</h2>
                            <img class="img-fluid" :src="$Url + ItemHome2.institucionalImg[0].urlImagem" alt="">

                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="from_blog_area mb-5" style="padding-top: 60px;">
            <div class="container">
                <div class="from_blog_inner">
                    <div class="c_main_title" style="text-align:center;">
                        <h2 style="color: #000; font-size: 1.6rem;">Blog La Bodeguita</h2>
                    </div>
                    <div class="row">
                        <div v-for="artigo in Artigos.slice(0,6)" :key="artigo.institucionalId" class="col-lg-4 col-sm-6" style="padding-top:30px">
                            <div class="from_blog_item">
                                <a href="" @click="$redirect_reload('/blog/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('!','').replaceAll('?',''))">
                                    <img v-if="artigo.institucionalImg!=''" class="img-fluid" :src="$Url + artigo.institucionalImg[0].urlImagem" alt="">
                                    <img v-else class="img-fluid" src="/imagens/indisponivel.jpg" alt="">
                                </a>
                                <div class="f_blog_text">
                                    <a href="" @click="$redirect_reload('/blog/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ','-').replaceAll('/','').replaceAll('!','').replaceAll('?',''))">
                                        <p style="font-size:18px;padding-top:5px;">{{ artigo.nome }}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Adicione mais Produtos -->
        <Modal v-model="showModal" title="">

            <section class="section-modal modal-produtos">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal">
                                {{ tituloModal }}
                            </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12ccol-lg-12 my-4">
                           <span v-html="textoModal"></span>
                        </div>
                    </div>
                </div>
                <footer class="modal_footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12 col-lg-12" style="text-align:right;">
                                <a class="btn btn-dark"
                                   @click="CienteModal()">
                                    {{ textoBtnmodal }}
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </section>
        </Modal>
    </section>

</template>

<script>

    import { BCarousel } from 'bootstrap-vue'
    import { Carousel, Slide } from 'vue-carousel';
    import { VMoney } from 'v-money'

    export default {
        components: {
            BCarousel,
            Carousel,
            Slide,
        },
        data() {
            return {
                ItensHome: [],
                ItemHome: {},
                ListaGeral: [],
                ListaItensGeral: [],
                ListaEtiquetaImg: [],
                ListaFotosProd: [],
                slide: 0,
                sliding: null,
                parcelas: 0,
                parcelasTexto: '',
                Artigos: [],
                artigo: {},
                TituloBlog: '',
                ItensHome2: [],
                ItemHome2: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                },
                showModal: false,
                textomodal: [],
                tituloModal:'',
                textoModal: '',
                textoBtnmodal:'',
                textobusca:'',

            }
        },
        directives: { money: VMoney },
        methods: {
             ValidaBusca() {
                if (this.textobusca != '') {
                    this.$redirect("/produtosbusca/" + this.textobusca);
                }
                else {
                    this.$mensagem_normal("Digite uma palavra-chave e clique na lupa para pesquisar.");
                }

            },
            validacss(value, txt) {

                let recebe = '';
                //console.log(value.homeMontaItemId + "-" + value.visivelMobile + "-" + value.visivelDesktop)
                if (value.visivelMobile && value.visivelDesktop) {
                    recebe = ''
                }

                if (value.visivelMobile && !value.visivelDesktop) {
                    recebe = ' hidden-lg hidden-xl '
                }

                if (!value.visivelMobile && value.visivelDesktop) {
                    recebe = ' hidden-sm-md hidden-xs '
                }

                if (!value.visivelMobile && !value.visivelDesktop) {
                    recebe = ' hidden-lg hidden-xl hidden-sm-md hidden-xs '
                }

                if (txt != null) {
                    return recebe + txt;
                }
                else {
                    return recebe;
                }


            },
         /*    VerificaTipo(value) {
                var retorno = this.ListaGeral.filter(x => x.homeBannerId == value);
                return retorno[0].tipoBlocoHome.tipoBlocoHomeId;
            }, */
           /*  CarregaImagem(value) {
                var retorno = this.ListaItensGeral.filter(x => x.homeBanner.homeBannerId == value);
                //console.log(retorno)
                return retorno.sort(function (a, b) {
                    return a.ordem - b.ordem;
                });
            }, */
           /*  CarregaEtiqueta(value) {
                //if (this.ListaEtiquetaImg != null) {
                console.log(this.ListaEtiquetaImg)
                console.log(value)
                var retorno = this.ListaEtiquetaImg.filter(x => x.produto.produtoId == value);
                console.log(retorno)
                return retorno.sort(function (a, b) {
                    return a.ordem - b.ordem;
                });
                //}
                //else {
                //    return null
                //}
            }, */
/*             CarregaImagemUnica(value) {
                var retorno = this.ListaFotosProd.filter(x => x.produto.produtoId == parseInt(value))
                if (retorno != null) {
                    retorno.sort(function (a, b) {
                        if (a.ordem > b.ordem) {
                            return 1;
                        }
                        if (a.ordem < b.ordem) {
                            return -1;
                        }
                        return 0;
                    });
                    return this.$Url + retorno[0].urlImg;
                }
                else {
                    return this.$Url + "/imagens/indisponivel.jpg"
                }

            }, */
            initCarousel() {
                this.sliding = true
            },
            endSlide() {
                this.sliding = false
            },
            CienteModal() {
                let AceiteLabodeguita = localStorage.getItem('aceitelabodeguita');
                if (!AceiteLabodeguita) {
                    AceiteLabodeguita = "aceitelabodeguita";
                    localStorage.setItem('aceitelabodeguita', String(AceiteLabodeguita))
                    this.showModal = false;
                }
                else {
                    if (AceiteLabodeguita == "aceitelabodeguita") {
                        this.showModal = false;
                    }
                    else {
                        this.showModal = true;
                    }
                }
            },
        },
        created() {
            
            this.$http
                .get(this.$apiUrl + "/homemontaitem")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ItensHome = tiu2
                  /*   .sort(function (a, b) {
                        return a.ordem - b.ordem;
                    }); */
/*                      console.log('this.ItensHome')
                     console.log(this.ItensHome) */
                });


         /*    this.$http
                .get(this.$apiUrl + "/homebanner")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaGeral = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });

            this.$http
                .get(this.$apiUrl + "/homebannerItem")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaItensGeral = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });
 */
           /*  this.$http
                .get(this.$apiUrl + "/produtopremio")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaEtiquetaImg = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                }); */

/*             this.$http
                .get(this.$apiUrl + "/produtotextogeral")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.parcelas = parseInt(tiu2[0].texto1, 10);
                    this.parcelasTexto = tiu2[0].texto2;
                }); */

/*             this.$http
                .get(this.$apiUrl + "/ProdutoImagem")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaFotosProd = tiu2
                }); */

            this.$http
                .get(this.$apiUrl + "/institucional/telas/artigos")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Artigos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        return a.data1 - b.data1;
                    }).reverse();
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/itenshome")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ItensHome2 = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });

            let AceiteLabodeguita = localStorage.getItem('aceitelabodeguita');
            if (!AceiteLabodeguita) {
                 //tela modal 18 anos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/cadastromodal")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.textomodal = tiu2;
                    if (this.textomodal != '') {
                        if (this.textomodal[0].visivel) {
                            this.tituloModal = this.textomodal[0].nome;
                            this.textoModal = this.textomodal[0].descricao1;
                            this.textoBtnmodal = this.textomodal[0].textoBtn1;
                            this.showModal = true;
                        }
                    }
                });
                
            }
            else {
                this.showModal = false;
            }

        },
        mounted() {

        },
    }

</script>

<style scoped>
    @import '/css/produtos.css';

    .bg-color {
        background-color: #993366;
    }

    .imagem_produto {
        max-height: 250px;
    }

    .product_item {
        width: 100%;
        transition: all .2s ease-in;
        background-color: transparent;
    }

    .vfigure {
        position: absolute;
        float: left;
        top: 20px;
        height: 40px;
        z-index: 999;
        text-align: center;
    }

    .vfigcaption {
        font-size: 12px;
        left: 0px;
        top: 17px;
        color: #000000;
        width: 50px;
        position: absolute;
    }

    .vfigcaption2 {
        font-size: 12px;
        left: 0px;
        top: 17px;
        color: #000000;
        width: 50px;
        position: absolute;
    }

    .vfigure2 {
        position: absolute;
        float: left;
        top: 20px;
        height: 40px;
        z-index: 999;
        text-align: center;
    }

    


    /********************
    Responsive
    ********************/

    /* Smartphones */
    @media (max-width:767px) {
        .product_item {
            left: 0px;
        }

        .vfigure {
            position: absolute;
            float: left;
            top: 65px;
            height: 40px;
            z-index: 999;
            text-align: center;
        }

        .vfigcaption {
            font-size: 12px;
            left: 0px;
            top: 17px;
            color: #000000;
            width: 50px;
            position: absolute;
        }

       .peq {
            font-size: 9px;
            left: 0px;
            top: 10px;
            color: #000000;
            width: 36px;
            position: absolute;
        }

        .imgcelet{
            width: 35px;
        }

        .vfigure2 {
            position: absolute;
            float: left;
            top: 65px;
            height: 40px;
            z-index: 999;
            left: 30px;
        }


        .vfigcaption2 {
            font-size: 12px;
            left: 0px;
            top: 17px;
            color: #000000;
            width: 50px;
            position: absolute;
        }

        .new_arrivals_title {
            position: absolute;
            left: 0;
            top: 20px;
            font-size: 24px;
            font-weight: 500;
            margin-bottom: -29px;
            }
    }

    /* Tudo, menos desktop */
    @media (max-width:1199px) {
        .product_item {
            height: min-content;
            padding-top: 60px;
            padding-bottom: 40px;
        }

        .product_price {
            margin-top: 0px;
        }
    }

    /* Desktop + Notbooks */
    @media (min-width:1200px) {
        .product_item_alt {
            min-height: 240px;
        }
    }
</style>