<template>

	<div class="header_main altura" >
		<div class="container">

			<div class="row hidden-sm">
				<!-- Login -->
				<div class="col-md-11 offset-md-1 col-lg-7 offset-lg-3 col-xl-7 offset-xl-3 topbar">
					<div class="row">
						<div class="col topbar-content hidden-sm">
							<router-link to="/meusPedidos">Meus Pedidos</router-link>
						</div>
						<div class="col topbar-content separador hidden-sm">
							<router-link to="/meusDados">Minha Conta</router-link>
						</div>
						<div v-if="usuario == ''" class="col topbar-content separador ">
							<router-link to="/login">
								<i class="far fa-user icon-user"></i>
								Registre-se
							</router-link>
						</div>
						<div v-if="usuario == ''" class="col topbar-content separador">
							<router-link to="/login">Login</router-link>
						</div>
						<div v-else class="col topbar-content separador" style="font-size:14px;">
                          Olá, {{ usuario.nomeEmpresa }}
						</div>
						<div v-if="usuario != ''" class="col topbar-content separador">
							<a href="" @click="Logout()">Logout</a>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<!-- Logo -->
				<div class="col-lg-3 col-xl-3 col-7 order-1">
					<div class="logo_container">
						<div class="logo">
							<a href="" @click="$redirect_reload('/')" >
								<h1><img class="img-fluid" src="@/assets/img/LogoLaBodeguita.svg" alt="Logo Labodeguita" style="padding-bottom: 10px;width: auto;height: 170px;"></h1>
							</a>
						</div>
					</div>
				</div>
				<!-- Search -->
				<div class="col-lg-6 col-12 order-lg-2 order-3 text-lg-left text-right">
					<div class="header_search">
						<div class="header_search_content">
							<div class="header_search_form_container">
								<form action="#" class="header_search_form clearfix">
									<input type="search" v-model="textobusca" @keyup.enter="ValidaBusca()" required="required" class="header_search_input" placeholder="Busque aqui o seu produto">

									<!-- Se excluir, quebra a página (??) -->
									<div class="custom_dropdown" style="display: none;">
										<div class="custom_dropdown_list">
											<span class="custom_dropdown_placeholder clc">All Categories</span>
											<i class="fas fa-chevron-down"></i>
											<ul class="custom_list clc">
												<li><a class="clc" href="#">All Categories</a></li>
												<li><a class="clc" href="#">Computers</a></li>
												<li><a class="clc" href="#">Laptops</a></li>
												<li><a class="clc" href="#">Cameras</a></li>
												<li><a class="clc" href="#">Hardware</a></li>
												<li><a class="clc" href="#">Smartphones</a></li>
											</ul>
										</div>
									</div>

									<button @click="ValidaBusca()" class="header_search_button trans_300"><i class="fas fa-search"></i></button>
								</form>
							</div>
						</div>
					</div>
				</div>
				<!-- Cart -->
				<div class="col-lg-3 col-xl-3 col-4 order-lg-3 order-2 text-lg-left text-right">
					<div class="wishlist_cart d-flex flex-row align-items-center justify-content-end">
						<!-- Cart -->
						<div class="cart">
							<div class="cart_container d-flex flex-row align-items-center justify-content-end">
								<div class="cart_icon">
									<a href="" @click="$redirect_reload('/carrinho')"><i class="fas fa-shopping-cart"></i></a>
									<!--<div class="cart_count"><span>12</span></div>-->
								</div>
								<div class="cart_content">
									<div class="cart_text">
										<a href="" @click="$redirect_reload('/carrinho')">Visualizar Carrinho</a>
									</div>
										
								</div>
								<div class="hidden-lg hidden-xl">
									<div class="">
										<div v-if="usuario == ''" class="col topbar-content separador">
										<router-link to="/login">Login</router-link>
										</div>
										<div v-else class="col topbar-content separador" style="font-size:12px;">
										Olá, {{ usuario.nomeEmpresa }}
										</div>
										<div v-if="usuario != ''" class="col topbar-content separador" style="font-size:12px;padding-top:10px;">
											<a href="" @click="Logout()">Logout</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
    //var coll = document.getElementsByClassName("collapsible");
    //var i;

    //for (i = 0; i < coll.length; i++) {
    //    coll[i].addEventListener("click", function () {
    //        this.classList.toggle("active");
    //        var content = this.nextElementSibling;
    //        if (content.style.display === "block") {
    //            content.style.display = "none";
    //        } else {
    //            content.style.display = "block";
    //        }
    //    });
    //}


    export default {
        data() {
            return {
                Whats: [],
                What: {},
                WhatIcone: {},
                Segmentos: [],
                segmento: {},
                Segmentos2: [],
                segmento2: {},
                textobusca: '',
				usuario: {},
            };
        },
/*         props: {
            SegmentoId: {
                type: Number,
                required: true,
                default: 0
            }
        }, */
        methods: {
            ValidaBusca() {
                if (this.textobusca != null) {
                    this.$redirect("/produtosbusca/" + this.textobusca);
                }
                else {
                    this.$mensagem_normal("Digite uma palavra-chave e clique na lupa para pesquisar.");
                }

            },
			CheckLogin() {
                let usuarioTmp = {
                    email: '',
                    senha: '',
                }
                usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
                if (usuarioTmp != null) {
                    let _usuario = {
                        email: usuarioTmp.email,
                        senha: usuarioTmp.senha,
                    };
                    this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                        (response) => {
                            // get body data
                                this.usuario = response.body;
                        },
                        (response2) => {
                            // error callback
                            this.$mensagem_erro(response2.body);
                            
                        }
                    );
                } 
				else{
					this.usuario = '';
				}
            },
			Logout() {
                this.$cookie.delete("usuarioLabodeguita");
				localStorage.removeItem('dadoslabodeguita');
                this.$redirect_reload("/");
            },
        },
        created() {
             this.CheckLogin();
            //this.$http
            //    .get(this.$apiUrl + "/institucional/telas/Lista%20Whats")
            //    .then((res2) => res2.json())
            //    .then((tiu2) => {
            //        this.Whats = tiu2;
            //        this.WhatIcone = this.Whats[0];
            //    });

            //if (this.SegmentoId != 0) {
            //    this.$http
            //        .get(this.$apiUrl + "/SegmentoProdNivel2/Nivel1/" + parseInt(this.SegmentoId, 10))
            //        .then((res2) => res2.json())
            //        .then((tiu2) => {
            //            this.Segmentos2 = tiu2.filter(x => x.visivel).sort(function (a, b) {
            //                return a.ordem - b.ordem;
            //            });
            //        });
            //}

            //    this.$http
            //        .get(this.$apiUrl + "/SegmentoProdNivel1")
            //        .then((res2) => res2.json())
            //        .then((tiu2) => {
            //            this.Segmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
            //                return a.ordem - b.ordem;
            //            });
            //        });


        },
        mounted() {

        },
        components: {

        },
    };
</script>

<style scoped>
    .altura {
        padding-top:10px;
    }

	.topbar {
		padding-top: 10px;
	}

	.topbar-content {
		font-size: 15px;
		font-weight: 500;
		padding: 0 5px;
		padding-left: 15px;
		text-transform: uppercase;
	}

		.topbar-content a {
			color: #606060 !important;
		}

			.topbar-content a:hover {
				color: #5e1936 !important;
			}

	.separador {
		border-left: 1px solid #eee;
	}

	.icon-user {
		color: #eee;
		margin-right: 5px;
	}


	/********************
	Responsive
	********************/

	/* Smartphones */
	@media (max-width:767px) {

		.topbar-content {
			padding-left: 0px;
			text-align: center;
		}

        .altura {
            padding-top: 30px;
        }
	}

	/* Tablets */
	@media (min-width:768px) and (max-width:991px) {
		.header_main {
			padding-bottom: 20px;
		}
	}
</style>