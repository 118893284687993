<template>

    <section class="carrinho">


        <section class="main-minha_conta">

            <div class="container">
                <div class="row">
                    <div class="col-lg-12 px-xl-12" style="text-align:center;">
                        <h3 class="titulo-principal-suporte mb-4">{{ Titulo }}</h3>
                        <!-- <h6 class="subtitulo h5 mb-4">Meus Pedidos</h6> -->
                        <div class="row">
                            <div class="col">
                                <div class="content">
                                    <div v-html="Texto"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- Sobre (Content) + Aside -->
                    <div class="bg0 mt-5">
                        <div class="container">
                            <div class="row">
                                <div class="images col-12 col-lg-3 px-xl-3 mb-4" v-for="src in artigos" :key="src.institucionalId" v-viewer="{navbar: false, toolbar:false, title: false}" style="text-align:center;">
                                    <img :src="$Url + src.institucionalImg[0].urlImagem" style="width:100%;padding:5px;border-radius:25px;">
                                    <span v-html="src.descricao1" style="width:100%;"></span>
                                    <h6 style="width:100%;">
                                        <a :href="'https://www.instagram.com/' + src.campoExtra1.replace('@','')" target="_blank" style="color:#212529;" >{{ src.campoExtra1 }}</a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>
<script>
    import moment from 'moment'
    import 'viewerjs/dist/viewer.css'
    export default {
        components: {
        },
        data() {
            return {
                artigos: [],
                artigosLateral: [],
                artigo_titulo: '',
                artigo_nome: '',
                artigo_imagem: '',
                artigo_texto: '',
                artigo_data: '',
                artigo: {},
                Retorno: [],
                Titulo: '',
                Texto: '',
                images: [],
            }
        },
        methods: {
            carregaArtigo(idArtigo) {
                this.$http
                    .get(this.$apiUrl + "/institucional/" + idArtigo)
                    .then((res) => res.json())
                    .then((retornoAPI) => {
                        this.artigo = retornoAPI
                        this.artigo_titulo = this.artigo.nome
                        this.artigo_nome = this.artigo.campoExtra1;
                        this.artigo_texto = this.artigo.descricao1;
                        this.artigo_data = this.artigo.data1;
                        if (this.artigo.institucionalImg[0] != null) {
                            this.artigo_imagem = this.$Url + this.artigo.institucionalImg[0].urlImagem;
                            this.images = this.artigo.institucionalImg;
                        }
                        else {
                            this.artigo_imagem = '';
                        }
                    });
            },
            currentDateTime(vdate) {
                return moment(vdate).format('DD/MM/YYYY')
            },
        },
        created() {
            // Conte�do Artigos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/cadastromural")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        return a.institucionalId - b.institucionalId;
                    }).reverse();
                    //this.artigos.forEach((itemex) => {
                    //    this.images.push({
                    //        "<img src=" + this.$Url + itemex.institucionalImg[0].urlImagem + "style='width: 150px; padding: 5px;'>" });
                    //});
                    // this.artigosLateral = this.artigos; //.slice(0, 15).sort(() => Math.random() - 0.5)
                    // Default: Ao carregar o site, trazer artigo mais recente
                    //this.artigo_titulo = this.artigos[0].nome;
                    //this.artigo_nome = this.artigos[0].campoExtra1;
                    //this.artigo_texto = this.artigos[0].descricao1;
                    //this.artigo_data = this.artigos[0].data1;
                    //if (this.artigos[0].institucionalImg[0] != null) {
                    //    this.artigo_imagem = this.$Url + this.artigos[0].institucionalImg[0].urlImagem;
                    //    this.images = this.artigos[0].institucionalImg;
                    //}
                    //else {
                    //    this.artigo_imagem = '';
                    //}
                });
            this.$http
                .get(this.$apiUrl + "/institucional/telas/titulomural")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;
                    if (this.Retorno != null) {
                        this.Titulo = this.Retorno[0].nome;
                        this.Texto = this.Retorno[0].descricao1;
                    }
                });
        },
        mounted() {
        },
    }
</script>
<style scoped>

    p {
        margin-top: 0;
        margin-bottom: 0 !important;
    }
    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
    @import '/css/suporte.css';

</style>
