<template>

    <header class="header">

        <!-- Main Navigation -->

        <nav class="main_nav">
            <div class="container">
                <div class="row">
                    <div class="col">

                        <div class="main_nav_content d-flex flex-row">

                            <!-- Categories Menu -->

                            <div class="cat_menu_container hidden-xl hidden-lg">
                                <div class="cat_menu_title d-flex flex-row align-items-center justify-content-start">
                                    <div class="cat_burger"><span></span><span></span><span></span></div>
                                    <div class="cat_menu_text">Menu</div>
                                </div>

                                <!-- Deptos topo -->
                                <ul class="cat_menu pl-0">
                                    <li>
                                        <a href="" @click="$redirect_reload('/sobre')" style="color:#bbbbbb;">Sobre</a>
                                    </li>
                                    <li>
                                        <a href="" @click="$redirect_reload('/blog')" style="color:#bbbbbb;">Blog</a>
                                    </li>
                                    <li v-for="segmento in Segmentos.segmento" :key="segmento.segmentoProdNivel1Id">
                                        <a href="#" @click="$redirect_reload('/produtos/' + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))" style="color:#bbbbbb;">{{ segmento.nomeSegmento}}</a>
                                    </li>
                                     <!--li>
                                        <a href="#" @click="$redirect_reload('/produtosrestaurantes/' + '1' + '/' + '0' + '/' + 'Restaurantes')" style="color:#bbbbbb;">Restaurantes</a>
                                    </li-->
                                    
                                   <!--  <li>
                                        <a href="#" @click="$redirect_reload('/promocoes')" style="color:#bbbbbb;">Outlet</a>
                                    </li> -->
                                </ul>
                            </div>
                            <!-- Main Nav Menu -->

                            <div class="main_nav_menu ml-5">
                                <ul class="standard_dropdown main_nav_dropdown main_nav_desktop">
                                    <li>
                                        <a href="" @click="$redirect_reload('/')" style="color:#bbbbbb;">Home</a>
                                    </li>
                                    <li>
                                        <a href="" @click="$redirect_reload('/sobre')" style="color:#bbbbbb;">Sobre</a>
                                    </li>
                                   <!--  <li>
                                        <a href="#" @click="$redirect_reload('/promocoes')" style="color:#bbbbbb;">Outlet</a>
                                    </li> -->
                                    <li v-for="segmento in Segmentos.segmento" :key="segmento.segmentoProdNivel1Id">
                                        <a href="" @click="$redirect_reload('/produtos/' + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))" style="color:#bbbbbb;">{{ segmento.nomeSegmento}}</a>
                                    </li>
                                     <li>
                                        <a href="" @click="$redirect_reload('/produtosrestaurantes/' + '1' + '/' + '0' + '/' + 'Restaurantes')" style="color:#bbbbbb;">Restaurantes</a>
                                    </li>
                                    <li>
                                        <a href="" @click="$redirect_reload('/blog')" style="color:#bbbbbb;">Blog</a>
                                    </li>
                                    <li>
                                        <a href="" @click="$redirect_reload('/mural')" style="color:#bbbbbb;">Mural</a>
                                    </li>
                                    <!--<li>
                                        <a href="#" @click="$redirect_reload('/contato')" style="color:#bbbbbb;">Contato</a>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
        </nav>



    </header>

</template>
<script>
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.display === "block") {
                content.style.display = "none";
            } else {
                content.style.display = "block";
            }
        });
    }

    export default {
        data() {
            return {
                Segmentos: [],
                segmento: {},
                textobusca:'',
            };
        },
        methods: {
            ValidaBusca() {
                if (this.textobusca != '') {
                    this.$redirect("/produtosbusca/" + this.textobusca);
                }
                else {
                    this.$mensagem_normal("Digite uma palavra-chave e clique na lupa para pesquisar.");
                }

            },
        },
        created() {

            this.Segmentos = this.$segmentos;
        },
        mounted() {

        },
        components: {
        },
    };
</script>

<style scoped>

</style>

