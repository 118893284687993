<template>

    <section class="carrinho">


        <section class="main-minha_conta">

            <div class="container">
                <div class="row">
                    <!-- Sobre (Content) + Aside -->
                    <div class="bg0 mt-5">
                        <div class="container">
                            <div class="row justify-content-center">

                                <div class="col-lg-9 px-xl-3">
                                    <div class="section-content mb-5">

                                        <h2>{{ artigo_titulo }}</h2>
                                        <span class="data">{{ currentDateTime(artigo_data) }}</span>

                                        <img class="w-100 set-img mt-5 mb-4" :src="artigo_imagem" alt="">
                                        <p v-html="artigo_texto"></p>

                                    </div>
                                </div>

                                <!------------------------------------------- Aside -->
                                <div class="col-lg-3 mb-5">
                                    <h3 class="color-title f-weight-700">Mais recentes</h3>
                                    <hr>
                                    <div class="side-articles"
                                         v-for="artigo in artigosLateral" :key="artigo.id">

                                        <div class="side-articles-item my-4">
                                            <a href="#" @click="carregaArtigo(artigo.institucionalId)">
                                                <h6 class="f-weight-600 mb-2" style="color:#5e1936;">
                                                    {{ artigo.nome }}
                                                </h6>
                                            </a>
                                            <span class="data">{{ currentDateTime(artigo.data1) }}</span>
                                        </div>

                                        <hr>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </section>

</template>

<script>
    import moment from 'moment'

    export default {
        components: {
        },
        data() {
            return {
                artigos: [],
                artigosLateral: [],
                artigo_titulo: '',
                artigo_imagem: '',
                artigo_texto: '',
                artigo_data: '',
                artigo: {},  
            }
        },
        methods: {
            carregaArtigo(idArtigo) {
                this.$http
                    .get(this.$apiUrl + "/institucional/" + idArtigo)
                    .then((res) => res.json())
                    .then((retornoAPI) => {
                        this.artigo = retornoAPI
                        this.artigo_titulo = this.artigo.nome
                        this.artigo_texto = this.artigo.resumo1;
                        this.artigo_data = this.artigo.data1;

                        if (this.artigo.institucionalImg[0] != null) {
                            this.artigo_imagem = this.$Url + this.artigo.institucionalImg[0].urlImagem;
                        }
                        else {
                            this.artigo_imagem = '';
                        }

                    });
            },
            currentDateTime(vdate) {
                return moment(vdate).format('DD/MM/YYYY')
            },
        },
        created() {

            // Conte�do Artigos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/artigos")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                        return a.data1 - b.data1;
                    }).reverse();
                    this.artigosLateral = this.artigos; //.slice(0, 15).sort(() => Math.random() - 0.5)

                    console.log(typeof this.$route.params.idArtigo)

                    if (typeof this.$route.params.idArtigo != 'undefined') {
                        this.carregaArtigo(this.$route.params.idArtigo);
                    }
                    else {
                        // Default: Ao carregar o site, trazer artigo mais recente
                        this.artigo_titulo = this.artigos[0].nome;
                        this.artigo_texto = this.artigos[0].resumo1;
                        this.artigo_data = this.artigos[0].data1;
                        if (this.artigos[0].institucionalImg[0] != null) {
                            this.artigo_imagem = this.$Url + this.artigos[0].institucionalImg[0].urlImagem;
                        }
                        else {
                            this.artigo_imagem = '';
                        }
                    }
                });
        },
        mounted() {

        },
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
    @import '/css/suporte.css';
    @import '/styles/bootstrap4/bootstrap.min.css';
    @import '/plugins/fontawesome-free-5.0.1/css/fontawesome-all.css';
    @import '/plugins/OwlCarousel2-2.2.1/owl.carousel.css';
    @import '/plugins/OwlCarousel2-2.2.1/owl.theme.default.css';
    @import '/plugins/OwlCarousel2-2.2.1/animate.css';
    @import '/plugins/slick-1.8.0/slick.css';
    @import '/styles/shop_styles.css';
    @import '/styles/shop_responsive.css';
    @import '/styles/main_styles.css';
    @import '/styles/responsive.css';
    @import '/styles/th__style.css';
</style>