<template>

    <section class="carrinho">

        <CheckAcesso />

        <section class="main-minha_conta">

            <div class="container">
                <div class="row">
                    <!-- Sidenav -->
                    <div class="col-lg-2">
                        <ul class="lista-sidebar_links">
                            <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                            <li><router-link to="/meusPedidos" class="texto active">Meus Pedidos</router-link></li>
                            <li><router-link to="/meusArquivos" class="texto">Meus Arquivos</router-link></li>
                            <li><router-link to="/meusArquivos" class="texto">Arquivos</router-link></li>
                            <li><router-link to="/meusGaleriaFotos" class="texto">Galeria de Fotos</router-link></li>
                            <li><router-link to="/meusVideos" class="texto">V&iacute;deos</router-link></li>
                        </ul>
                    </div>
                    <!-- Main -->
                    <div class="col-lg-10 col-xl-9 offset-xl-1 mt-5 mt-lg-0">
                        <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
                        <h6 class="subtitulo h5 mb-4">Meus Pedidos</h6>
                        <!-- Lista de Pedidos -->
                        <div class="row">
                            <div class="col">
                                <ul class="lista-pedidos border-top py-3">
                                    <li>
                                        <div class="row">
                                            <div class="col-6 col-md-3 col-lg-3 mb-4 mb-md-0">
                                                <div class="pedido_id">
                                                    <i class="fas fa-clipboard-list text-success fa-2x"></i>
                                                    <div>
                                                        <h6 class="mb-0">Pedido: {{ UltimoPedido.pedidoId }}</h6>
                                                        <div class="pedido_dados">Pgto: {{ UltimoPedido.formaPgto }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                                <div class="pedido_dados">{{ FormataData(UltimoPedido.dataPedido) }}</div>
                                            </div>
                                            <div class="col-6 col-md-2 col-lg-2 mb-4 mb-md-0 d-flex align-items-center">
                                                <div>
                                                    <div class="pedido_dados">R$ {{ parseFloat(TotalPedido).toFixed(2).toString().replace('.',',') }}</div>

                                                </div>
                                            </div>

                                            <div class="col-6 col-md-4 col-lg-2 d-flex align-items-center justify-content-end">
                                                <button class="btn btn-dark" disabled>{{ UltimoPedido.situacaoPedido }}</button>
                                            </div>

                                            <div class="col-6 col-md-3 col-lg-3 d-flex align-items-center justify-content-lg-end">
                                                <button v-if="validacomprova == ''" @click.prevent="showModal_comprova=true" class="btn btn-danger">Enviar Comprovante</button>
                                                <button v-else @click.prevent="showModal_comprova=true" class="btn btn-danger">Ver Comprovante</button>
                                                <!-- Escreva a Mensagem -->
                                                <Modal v-model="showModal_comprova" title="">
                                                    <section class="">
                                                        <div class="container">

                                                            <div class="row justify-content-center text-center">
                                                                <div class="col-lg-9 col-xl-8 text-center">
                                                                    <input v-if="validacomprova == ''" id="arquivo" type="file" class="" />
                                                                    <button v-if="validacomprova == ''" @click="carregaFoto()" class="btn btn-danger" style="margin-left:10px;margin-top:15px;">Enviar Comprovante</button>
                                                                    <div class="col-6 col-md-3 col-lg-3 d-flex align-items-center justify-content-lg-end">

                                                                    </div>
                                                                    <div class="editar-cartao mt-4 text-center">
                                                                        <img class="img-fluid" :src="$Url + validacomprova" alt="">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </Modal>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Status -->
                        <div class="row">
                            <div class="col">
                                <div class="status border-top py-3 px-xl-5">
                                    <div class="row"  v-if="PedidosEndereco!=''">
                                        <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                            <i v-if="(PedidosCliente[0].situacaoPedido == 'Aguardando' || PedidosCliente[0].situacaoPedido == '1') || (PedidosCliente[0].situacaoPedido == 'Agendado' || PedidosCliente[0].situacaoPedido == '2') || (PedidosCliente[0].situacaoPedido == 'Enviado' || PedidosCliente[0].situacaoPedido == '3') || (PedidosCliente[0].situacaoPedido == 'Entregue' || PedidosCliente[0].situacaoPedido == '4') " class="fa fa-thumbs-up text-success fa-2x"></i>
                                            <i v-else class="fa fa-thumbs-up text-secondary fa-2x"></i>
                                            <p class="titulo-status text-secondary mb-0 mt-2" style="font-size: 13px;">Pedido Realizado</p>
                                        </div>
                                        <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                            <i v-if="(PedidosCliente[0].situacaoPedido == 'Aguardando' || PedidosCliente[0].situacaoPedido == '1') || (PedidosCliente[0].situacaoPedido == 'Agendado' || PedidosCliente[0].situacaoPedido == '2') || (PedidosCliente[0].situacaoPedido == 'Enviado' || PedidosCliente[0].situacaoPedido == '3') || (PedidosCliente[0].situacaoPedido == 'Entregue' || PedidosCliente[0].situacaoPedido == '4')" class="fa fa-money text-success fa-2x"></i>
                                            <i v-else class="fa fa-money text-secondary fa-2x"></i>
                                            <p class="titulo-status text-secondary mb-0 mt-2" style="font-size: 13px;">Pgto Selecionado</p>
                                        </div>
                                        <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                            <i v-if="(PedidosCliente[0].situacaoPedido == 'Agendado' || PedidosCliente[0].situacaoPedido == '2') || (PedidosCliente[0].situacaoPedido == 'Enviado' || PedidosCliente[0].situacaoPedido == '3') || (PedidosCliente[0].situacaoPedido == 'Entregue' || PedidosCliente[0].situacaoPedido == '4')" class="fa fa-calendar text-success fa-2x"></i>
                                            <i v-else class="fa fa-calendar text-secondary fa-2x"></i>
                                            <p class="titulo-status text-secondary mb-0 mt-2" style="font-size: 13px;">Agendado</p>
                                        </div>
                                        <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                            <i v-if="PedidosCliente[0].situacaoPedido == 'Agendado' || PedidosCliente[0].situacaoPedido == '2' || (PedidosCliente[0].situacaoPedido == 'Enviado' || PedidosCliente[0].situacaoPedido == '3') || (PedidosCliente[0].situacaoPedido == 'Entregue' || PedidosCliente[0].situacaoPedido == '4')" class="fa fa-codepen text-success fa-2x"></i>
                                            <i v-else class="fa fa-codepen text-secondary fa-2x"></i>
                                            <p class="titulo-status text-secondary mb-0 mt-2" style="font-size: 13px;">Separando</p>
                                        </div>
                                        <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                            <i v-if="(PedidosCliente[0].situacaoPedido == 'Enviado' || PedidosCliente[0].situacaoPedido == '3') || (PedidosCliente[0].situacaoPedido == 'Entregue' || PedidosCliente[0].situacaoPedido == '4')" class="fa fa-space-shuttle text-success fa-2x"></i>
                                            <i v-else class="fa fa-space-shuttle text-secondary fa-2x"></i>
                                            <p class="titulo-status text-secondary mb-0 mt-2" style="font-size: 13px;">Enviado</p>
                                        </div>
                                        <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                            <i v-if="PedidosCliente[0].situacaoPedido == 'Entregue' || PedidosCliente[0].situacaoPedido == '4'" class="fa fa-check text-success fa-2x"></i>
                                            <i v-else class="fa fa-check text-secondary fa-2x"></i>
                                            <p class="titulo-status text-secondary mb-0 mt-2" style="font-size: 13px;">Entregue</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Descrição do Pedido -->
                        <div class="row">
                            <div class="col">
                                <div v-for="produto in UltimoPedido.listaPedidoItem" :key="produto.pedidoItemId" class="descricao-pedido border-top py-3">
                                    <div class="row">
                                        <div class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                            <div class="descricao-img">
                                                <img v-if="produto.produto.imagens != null" class="img-fluid border border-rounded mb-3 mb-md-0"
                                                     :src="$Url + produto.produto.imagens[0].urlImg" alt="">
                                                <img v-else class="img-fluid border border-rounded mb-3 mb-md-0"
                                                     :src="$Url + '/imagens/indisponivel.jpg'" alt="">
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-2 d-flex align-items-center">
                                            <div class="descricao-content">
                                                <h6 class="subtitulo">{{ produto.produto.nomeProduto }}</h6>
                                                <p class="texto-mensagem"> Código: {{ produto.produto.produtoId }}</p>
                                                <p class="texto-mensagem"> Referência: {{ produto.produto.referencia }}</p>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-3 d-flex align-items-center">
                                            <div class="descricao-content">
                                                <p class="texto-mensagem" v-html="produto.produto.resuno"></p>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-1 d-flex align-items-center">
                                            <div class="descricao-content">
                                                <h6 class="subtitulo">{{ produto.qtdeProduto }}</h6>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-2 d-flex align-items-center">
                                            <div class="descricao-content">
                                                <h6 class="subtitulo">R$ {{  parseFloat(produto.valorProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-2 d-flex align-items-center">
                                            <div class="descricao-content">
                                                <h6 class="subtitulo">R$ {{ parseFloat(produto.valorProduto * produto.qtdeProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-for="adicional in UltimoPedido.listaPedidoItemAdicional" :key="adicional.pedidoItemAdicionalId" class="descricao-pedido border-top py-3">
                            <div class="row">
                                <div class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                    <div class="descricao-img">
                                        <img v-if="adicional.itemAvulso.urlImg != null" class="img-fluid border border-rounded mb-3 mb-md-0" style="width:80%;"
                                             :src="$Url + adicional.itemAvulso.urlImg" alt="">
                                        <img v-else class="img-fluid border border-rounded mb-3 mb-md-0" style="width:70%;"
                                             :src="$Url + '/imagens/indisponivel.jpg'" alt="">
                                    </div>
                                </div>
                                <div class="col-6 col-md-2 d-flex align-items-center">
                                    <div class="descricao-content">
                                        <h6 class="subtitulo">{{ adicional.itemAvulso.nomeItemAvulso }}</h6>
                                        <p class="texto-mensagem"> Código: {{ adicional.itemAvulso.itemAvulsoId }}</p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-3 d-flex align-items-center">
                                    <div class="descricao-content">
                                        <p class="texto-mensagem"></p>
                                    </div>
                                </div>
                                <div class="col-6 col-md-1 d-flex align-items-center">
                                    <div class="descricao-content">
                                        <h6 class="subtitulo">{{ adicional.qtdeProduto }}</h6>
                                    </div>
                                </div>
                                <div class="col-6 col-md-2 d-flex align-items-center">
                                    <div class="descricao-content">
                                        <h6 class="subtitulo">R$ {{  parseFloat(adicional.valorProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                    </div>
                                </div>
                                <div class="col-6 col-md-2 d-flex align-items-center">
                                    <div class="descricao-content">
                                        <h6 class="subtitulo">R$ {{ parseFloat(adicional.valorTotalProduto).toFixed(2).toString().replace('.',',') }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Entrega do Pedido -->
                        <div class="row">
                            <div class="col">
                                <div class="border-top py-3">
                                    <div class="row">
                                        <div class="col-lg-7 mb-4 mb-lg-0">
                                            <div v-if="PedidosEndereco!=''">
                                                <h5 class="subtitulo">Entrega</h5>
                                                <p class="texto-mensagem mb-0">{{ PedidosEndereco[0].endereco }}, {{ PedidosEndereco[0].numero }} - {{ PedidosEndereco[0].complemento }} </p>
                                                <p class="texto-mensagem mb-0">{{ PedidosEndereco[0].nomeCidade }} / {{ PedidosEndereco[0].nomeEstado }}</p>
                                                <p class="texto-mensagem mb-0">{{ PedidosEndereco[0].bairro }} / {{ PedidosEndereco[0].cep }}</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h5 class="subtitulo">Cupom Desconto</h5>
                                                <h6 class="subtitulo">(-) R$ {{ parseFloat(UltimoPedido.valorCupom).toFixed(2).toString().replace('.',',') }}</h6>
                                            </div>
                                            <div  v-if="PedidosEndereco!=''" class="d-flex align-items-center justify-content-between">
                                                <h5 class="subtitulo">Frete</h5>
                                                <h6 class="subtitulo">R$ {{ parseFloat(UltimoPedido.valorFrete).toFixed(2).toString().replace('.',',') }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="border-top py-3">
                                    <div class="row">
                                        <div class="col-lg-2 offset-lg-7">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h5 class="subtitulo">Total</h5>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 offset-lg-1">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h5 class="subtitulo">R$ {{ parseFloat(TotalPedido).toFixed(2).toString().replace('.',',') }}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-center">
                                            <router-link to="/meusPedidos" class="btn btn-success mt-5">Ver histórico de pedidos</router-link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>

    </section>

</template>

<script>

    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
    import moment from 'moment';
    import { VMoney } from 'v-money'

    export default {
        components: {
            CheckAcesso,
        },
        directives: { money: VMoney },
        data() {
            return {
                ClienteId: 0,
                PedidoId: this.$route.params.id,
                PedidosCliente: [],
                UltimoPedido: {},
                TotalPedido: 0,
                PedidosEndereco: [],
                PedidosComprova: [],
                validacomprova: [],
                showModal_comprova: false,
                FotoCarregada: '',
            }
        },
        methods: {
            FormataData(value) {
                return moment(value).format('DD/MM/YYYY')
            },
            carregaFoto() {
                let file = document.getElementById("arquivo").files[0];
                let erroarquivo = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                }
                else {
                    erroarquivo = "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                }
                if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                }
                else {
                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        this.$http.post(this.$apiUrl + "/PedidoPagtoComprova/upload", data).then(
                            (response3) => {
                                // get body data
                                this.FotoCarregada = response3.body;

                                //carrega Pedido comprova
                                this.$http
                                    .get(this.$apiUrl + "/PedidoPagtoComprova")
                                    .then((res2) => res2.json())
                                    .then((tiu2) => {
                                        this.PedidosComprova = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))

                                        //editar pedido pagamento comprova
                                        let _pedidoPagamentoComprova = {}
                                        _pedidoPagamentoComprova = {
                                            PedidoPagtoComprovaId: parseInt(this.PedidosComprova[0].pedidoPagtoComprovaId),
                                            UrlImg: this.FotoCarregada,
                                            Pedido: { PedidoId: parseInt(this.UltimoPedido.pedidoId, 10) },
                                        }

                                        this.$http
                                            .put(this.$apiUrl + "/PedidoPagtoComprova/" + parseInt(this.PedidosComprova[0].pedidoPagtoComprovaId,10), _pedidoPagamentoComprova)
                                            .then(
                                                response6 => {
                                                    // get body data
                                                    this.PedidosComprova = response6.body;
                                                    this.$redirect_reload("/meusPedidosDetalhe/" + parseInt(this.ClienteId,10) );
                                                },
                                                async response6 => {
                                                    let recebe2 = response6.body;
                                                    this.$mensagem_normal(recebe2);
                                                }
                                            );
                                    });
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    }
                }
            },
        },
        created() {

            let usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
            if (usuarioTmp != null) {
                this.ClienteId = parseInt(usuarioTmp.usuarioId, 10)
           
            //carrega Pedido do cliente
            this.$http
                .get(this.$apiUrl + "/Pedido/completo")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    console.log(tiu2)
                    if (this.PedidoId == null) {
                        this.PedidosCliente = tiu2.filter(x => x.cliente.clienteId == parseInt(this.ClienteId, 10)).sort(function (a, b) {
                            return a.pedidoId - b.pedidoId;
                        }).reverse();


                        if (this.PedidosCliente != null) {
                            this.UltimoPedido = this.PedidosCliente[0]
                        }
                        console.log(this.UltimoPedido)

                        //calcula valor total
                        let valor = 0
                        let recebe = this.UltimoPedido.listaPedidoItem;
                        recebe.forEach((item) => {
                            valor += item.valorTotalProduto * item.qtdeProduto
                        });

                        let recebe2 = this.UltimoPedido.listaPedidoItemAdicional;
                        recebe2.forEach((item) => {
                            valor += item.valorTotalProduto
                        });

                        //let recebe3 = this.UltimoPedido.listaPedidoItemManual;
                        //recebe3.forEach((item) => {
                        //    valor += item.valorTotalProduto
                        //});

                        valor += this.UltimoPedido.valorFrete

                        this.TotalPedido = (valor - this.UltimoPedido.valorCupom)


                        //carrega Pedido endereco
                        this.$http
                            .get(this.$apiUrl + "/PedidoEndereco")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.PedidosEndereco = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))

                            });

                        //carrega Pedido agendamento
                        //this.$http
                        //    .get(this.$apiUrl + "/PedidoAgendamento")
                        //    .then((res2) => res2.json())
                        //    .then((tiu2) => {
                        //        this.PedidosAgendamento = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))
                        //    });

                        //carrega Pedido agendamento
                        //this.$http
                        //    .get(this.$apiUrl + "/PedidoFoto")
                        //    .then((res2) => res2.json())
                        //    .then((tiu2) => {
                        //        this.PedidosFoto = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))
                        //    });

                        //carrega Pedido mensagem
                        //this.$http
                        //    .get(this.$apiUrl + "/PedidoMensagem")
                        //    .then((res2) => res2.json())
                        //    .then((tiu2) => {
                        //        this.PedidosMensagem = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))
                        //    });

                        //carrega Pedido comprova
                        this.$http
                            .get(this.$apiUrl + "/PedidoPagtoComprova")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.PedidosComprova = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))

                                if (this.PedidosComprova != '') {
                                    if (this.PedidosComprova[0].urlImg != null) {
                                        this.validacomprova = this.PedidosComprova[0].urlImg
                                    } else {
                                        this.validacomprova = '';
                                    }
                                }
                                else {
                                    this.validacomprova = '';
                                }

                            });

                    } else {
                        this.PedidosCliente = tiu2.filter(x => x.pedidoId == parseInt(this.PedidoId, 10))


                        if (this.PedidosCliente != null) {
                            this.UltimoPedido = this.PedidosCliente[0]
                        }

                        //calcula valor total
                        let valor = 0
                        let recebe = this.UltimoPedido.listaPedidoItem;
                        recebe.forEach((item) => {
                            valor += item.valorProduto * item.qtdeProduto
                        });

                        let recebe2 = this.UltimoPedido.listaPedidoItemAdicional;
                        recebe2.forEach((item) => {
                            valor += item.valorTotalProduto
                        });

                        //let recebe3 = this.UltimoPedido.listaPedidoItemManual;
                        //recebe3.forEach((item) => {
                        //    valor += item.valorTotalProduto
                        //});

                        valor += this.UltimoPedido.valorFrete

                        this.TotalPedido = (valor - this.UltimoPedido.valorCupom)


                        //carrega Pedido endereco
                        this.$http
                            .get(this.$apiUrl + "/PedidoEndereco")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.PedidosEndereco = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))

                            });

                        //carrega Pedido agendamento
                        //this.$http
                        //    .get(this.$apiUrl + "/PedidoAgendamento")
                        //    .then((res2) => res2.json())
                        //    .then((tiu2) => {
                        //        this.PedidosAgendamento = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))
                        //    });

                        //carrega Pedido agendamento
                        //this.$http
                        //    .get(this.$apiUrl + "/PedidoFoto")
                        //    .then((res2) => res2.json())
                        //    .then((tiu2) => {
                        //        this.PedidosFoto = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))

                        //        if (this.PedidosFoto[0].urlImagem == 'Sem foto') {
                        //            this.PedidosFoto = null
                        //        }
                        //    });

                        //carrega Pedido mensagem
                        //this.$http
                        //    .get(this.$apiUrl + "/PedidoMensagem")
                        //    .then((res2) => res2.json())
                        //    .then((tiu2) => {
                        //        this.PedidosMensagem = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))
                        //    });

                        //carrega Pedido comprova
                        this.$http
                            .get(this.$apiUrl + "/PedidoPagtoComprova")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.PedidosComprova = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))
                               
                                if (this.PedidosComprova != '') {
                                    if (this.PedidosComprova[0].urlImg != null) {
                                        this.validacomprova = this.PedidosComprova[0].urlImg
                                    } else {
                                        this.validacomprova = '';
                                    }
                                }
                                else {
                                    this.validacomprova = '';
                                }

                            });
                    }
                
                });
            }
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
    @import '/css/modais.css';


    @media (max-width:600px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 100%;
            outline: none;
            height: 270px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 12px;
        }

        .imagemCartao {
            height: 270px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 90%;
        }
    }

    @media (min-width:608px) and (max-width:991px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 98%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 80%;
        }
    }

    @media (min-width:992px) and (max-width:1199px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    @media (min-width:1200px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }
</style>