<template>

    <div class="carrinho">
        <div class="border-top py-4">
            <h6 class="texto-destaque mb-0" style="text-align: right !important;">Valor Total: R$ {{ parseFloat(TotalProdutos).toFixed(2).toString().replace('.', ',') }}</h6>
        </div>
        <div class="row">
            <div class="form-group row align-items-center mb-3">
                <div class="col-sm-8 col-7">
                    <label for="inputNumCartao" class="col-sm-5 col-form-label label-pagto2">Número Cartão</label>
                    <input v-model="numCartao" type="text" v-mask="'####-####-####-####'" inputmode="numeric" @blur="Carregaparcelas()" class="form-control">
                </div>
                <div class="col-sm-4 col-5">
                    <label for="inputcvc" class="col-sm-12 col-form-label label-pagto2">CVC <span class="feedback">3 digitos atrás cartão</span></label>
                    <input v-model="numcvc" type="text" v-mask="'####'" inputmode="numeric" class="form-control">
                </div>
                <div class="col-sm-7 col-12">
                    <label for="inputNome" class="col-sm-12 col-form-label label-pagto2" style="padding-top: 20px;">
                        Nome Completo <span class="feedback">Titular do Cartão</span>
                    </label>
                    <input v-model="nomeTitular" type="text" class="form-control">
                </div>
                <div class="col-sm-5 col-12">
                    <!--<input v-model="cpfTitular" type="text" class="form-control">-->
                    <label style="padding-right:20px;padding-top:10px;">
                        <input type="radio" id="cpf-example" value="###.###.###-##" pattern="\d*" v-model="dynamicMask">
                        <span> CPF</span>
                    </label>
                    <label>
                        <input type="radio" id="cnpj-example" value="##.###.###/####-##" pattern="\d*" v-model="dynamicMask">
                        <span> CNPJ</span>
                    </label>
                    <br />
                    <input type="text" inputmode="numeric"
                           v-mask="dynamicMask"
                           v-model="cpfTitular"
                           class="form-control" />
                </div>
                <div class="col-sm-6 col-6">
                    <label for="inputValidade" class="col-sm-12 col-form-label label-pagto2">Mês validade</label>
                    <v-select v-model="mesSelected" @input="MudaMes" pattern="\d*"
                              :options=" [{ valor: '01', texto: 'Janeiro', numtexto: '01' },
                                    { valor: '02', texto: 'Fevereiro', numtexto: '02' },
                                    { valor: '03', texto: 'Março', numtexto: '03' },
                                    { valor: '04', texto: 'Abril', numtexto: '04' },
                                    { valor: '05', texto: 'Maio', numtexto: '05' },
                                    { valor: '06', texto: 'Junho', numtexto: '06' },
                                    { valor: '07', texto: 'Julho', numtexto: '07' },
                                    { valor: '08', texto: 'Agosto', numtexto: '08' },
                                    { valor: '09', texto: 'Setembro', numtexto: '09' },
                                    { valor: '10', texto: 'Outubro', numtexto: '10' },
                                    { valor: '11', texto: 'Novembro', numtexto: '11' },
                                    { valor: '12', texto: 'Dezembro', numtexto: '12' },]"
                              :reduce="(x) => x.valor"
                              single-line
                              label="numtexto" placeholder="Mês" style="width:100%;"></v-select>
                </div>
                <div class="col-sm-6 col-6">
                    <label for="inputValidade" class="col-sm-12 col-form-label label-pagto2">Ano validade</label>
                    <v-select v-model="anoSelected" @input="MudaAno" pattern="\d*"
                              :options=" [{ano:'2021'}, {ano:'2022'}, {ano:'2023'}, {ano:'2024'}, {ano:'2025'}, {ano:'2026'}, {ano:'2027'}, {ano:'2028'}, {ano:'2029'}, {ano:'2030'}, {ano:'2031'}, {ano:'2032'}, {ano:'2033'}, {ano:'2034'}, {ano:'2035'}]"
                              :reduce="(x) => x.ano"
                              single-line
                              label="ano" placeholder="Ano"></v-select>
                </div>
                <div v-if="retornoparcelas != ''" class="col-sm-12 col-12">
                    <label for="inputcvc" class="col-sm-12 col-form-label label-pagto2">Qtde Parcelas</label>
                    <v-select v-model="Parcelaselected" @input="MudaParcela"
                              :options="retornoparcelas"
                              :value="retornoparcelas.pagamentoId"
                              single-line
                              label="textopagamento" class="style-chooser w-100"></v-select>
                </div>
            </div>
            <!-- Endereço -->
            <div class="form-group row align-items-center mt-4 mb-3">
                <div class="col-sm-12">
                    <input type="checkbox" id="checkbox" v-model="checked">
                      <label for="checkbox" style="padding-left:5px;"> Usar endereço de entrega</label>
                </div>
                <div v-if="!checked" class="col-sm-12">
                    <label for="inputcvc" class="col-sm-5 col-form-label label-pagto2">CEP <span class="feedback">Titular do Cartão</span></label>
                    <div class="input-group">
                        <input v-model="numCep" v-mask="'#####-###'" type="text" inputmode="numeric" class="form-control" @input="limpa()">
                        <div class="input-group-append">
                            <a href="" @click.prevent="Buscacep(numCep)" class="input-group-text" id="basic-addon2">Verificar</a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showend" class="form-group row align-items-center mb-2">
                <div class="col-sm-9">
                    <label for="inputEnd" class="col-sm-5 col-form-label label-pagto2">Endereço <span class="feedback">Titular do Cartão</span></label>
                    <input v-model="endereco" type="text" class="form-control">
                </div>
                <div class="col-sm-3">
                    <label for="inputComp" class="col-sm-5 col-form-label label-pagto2">Numero</label>
                    <input v-model="numeroend" type="number" class="form-control">
                </div>
            </div>
            <div v-if="showend" class="form-group row align-items-center mb-2">
                <div class="col-sm-5">
                    <label for="inputComp" class="col-sm-12 col-form-label label-pagto2">Complemento</label>
                    <input v-model="complemento" type="text" class="form-control">
                </div>
                <div class="col-sm-5">
                    <label for="inputCidade" class="col-sm-12 col-form-label label-pagto2">Cidade</label>
                    <input v-model="nomeCidade" type="text" class="form-control">
                </div>
                <div class="col-sm-2">
                    <label for="inputCidade" class="col-sm-12 col-form-label label-pagto2">Estado</label>
                    <input v-model="siglaEstado" v-mask="'AA'" type="text" class="form-control">
                </div>
            </div>
            <div v-if="showend" class="form-group row align-items-center mb-2">
                <div class="col-sm-12">
                    <label for="inputBairro" class="col-sm-12 col-form-label label-pagto2">Bairro</label>
                    <input v-model="nomeBairro" type="text" class="form-control">
                </div>
            </div>
        </div>
        <div class="box_content-footer">
            <div class="mt-4 mt-md-0">
                <button v-if="btnavancar==true" @click="EfetuarPagamento()" class="btn btn-success px-md-5 mt-0">Avançar</button>
            </div>
        </div>


    </div>

</template>

<script>

    import vSelect from 'vue-select'
      //import moment from 'moment';
       import { VMoney } from 'v-money'

    export default {
        metaInfo: {
            htmlAttrs: {
                lang: 'pt-br'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
            ]
        },
        components: {
            vSelect,
        },
         directives: { money: VMoney },
        data() {
            return {
                numCartao: '',
                nomeTitular: '',
                cpfTitular: '',
                numcvc: '',
                endereco: '',
                numeroend: '',
                complemento: '',
                siglaEstado: '',
                nomeCidade: '',
                nomeBairro: '',
                numCep: '',
                usuario: [],
                IP: {},
                mesSelected: null,
                CodMes: '',
                anoSelected: null,
                Retornocep: {},
                CodAno: 0,
                data: {},
                TokenYapay: '',
                DadosYapay: [],
                produtos: [],
                TipoCartao: '',
                CodParcela: '',
                retornopagamento: {},
                retornoparcelas: [],
                Parcelaselected: null,
                TotalProdutos: 0,
                dynamicMask: '###.###.###-##',
                showend: false,
                btnavancar: true,
                checked:false,
            }
        },
        methods: {
            limpa() {
                if (this.numCep == '') {
                    this.showend = false
                    this.btnavancar = false
                }
            },
            MudaMes(value) {
                if (value != null) {
                    this.CodMes = value;
                }
                else {
                    this.CodMes = '';
                }
            },
            MudaAno(value) {
                if (value != null) {
                    this.CodAno = value;
                }
                else {
                    this.CodAno = 0;
                }
            },
            MudaParcela(value) {
                if (value != null) {
                    this.CodParcela = value.pagamentoId;
                }
                else {
                    this.CodParcela = "0";
                }
            },
            EfetuarPagamento() {
                if (this.numCartao == '') {
                    this.$mensagem_normal("Por favor preencha dados do cartão");
                } else if (this.nomeTitular == '') {
                    this.$mensagem_normal("Por favor preencha nome completo do titular");
                } else if (this.cpfTitular == '') {
                    this.$mensagem_normal("Por favor preencha CPF ou CNPJ do titular");
                } else if (this.numcvc == '') {
                    this.$mensagem_normal("Por favor preencha Codigo CVV (digitos atrás do cartão)");
                } else if (this.mesSelected == null) {
                    this.$mensagem_normal("Selecione mês de validade do cartão");
                } else if (this.anoSelected == null) {
                    this.$mensagem_normal("Selecione ano de validade do cartão");
                } else if (this.Parcelaselected == null) {
                    this.$mensagem_normal("Selecione quantidade de parcelas para pagamento");
                } else if (this.numCep == '' && !this.checked) {
                    this.$mensagem_normal("Preencha o CEP do endereço do titular do cartão");
                } else if (this.endereco == '' && !this.checked) {
                    this.$mensagem_normal("Preencha o endereço do titular do cartão");
                } else if (this.numeroend == '' && !this.checked) {
                    this.$mensagem_normal("Preencha o número do endereço do titular do cartão");
                } else if (this.complemento == '' && !this.checked) {
                    this.$mensagem_normal("Preencha o complemento do endereço do titular do cartão");
                } else if (this.nomeCidade == '' && !this.checked) {
                    this.$mensagem_normal("Preencha a cidade do endereço do titular do cartão");
                } else if (this.siglaEstado == '' && !this.checked) {
                    this.$mensagem_normal("Preencha a sigla do estado do endereço do titular do cartão");
                } else if (this.nomeBairro == '' && !this.checked) {
                    this.$mensagem_normal("Preencha o bairro do endereço do titular do cartão");
                }
                else {

                    let vcep = '';
                    let vendereco = '';
                    let vnum='';
                    let vcomplemento='';
                    let vnomecidade='';
                    let vestado='';
                    let vnomebairro='';

                     if (this.checked){
                          let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                          dadosArmazenados = JSON.parse(dadosArmazenados);
                            vcep = dadosArmazenados.agenda[0].cep.replaceAll('-', '');
                            vendereco = dadosArmazenados.agenda[0].endereco;
                            vnum= dadosArmazenados.agenda[0].numero;
                            vcomplemento= dadosArmazenados.agenda[0].complemento;
                            vnomecidade= dadosArmazenados.agenda[0].cidade;
                            vestado= dadosArmazenados.agenda[0].estado;
                            vnomebairro=dadosArmazenados.agenda[0].bairro;
                     }
                     else{
                      vcep = this.numCep.replaceAll('-', '');
                      vendereco = this.endereco;
                      vnum= this.numeroend;
                      vcomplemento= this.complemento;
                      vnomecidade= this.nomeCidade;
                      vestado= this.siglaEstado;
                      vnomebairro= this.nomeBairro;
                     }


                    this.TipoCartao = this.BuscaCartao(this.numCartao);
                    this.data = {
                        token_account: this.TokenYapay,
                        customer: {
                            contacts: [
                                {
                                    type_contact: "M",
                                    number_contact: this.usuario.whatsapp.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', ''),
                                }
                            ],
                            addresses: [
                                {
                                    type_address: "B",
                                    postal_code: vcep,
                                    street: vendereco,
                                    number: vnum,
                                    completion: vcomplemento,
                                    neighborhood: vnomebairro,
                                    city: vnomecidade,
                                    state: vestado,
                                }
                            ],
                            name: this.nomeTitular,
                            cpf: this.cpfTitular.replaceAll('-', '').replaceAll('.', ''),
                            email: this.usuario.email,
                        },
                        transaction_product: this.produtos,
                        transaction: {
                            available_payment_methods: "3,4,5,20,25",
                            customer_ip: this.IP.ip,
                            //url_notification: "http://www.loja.com.br/notificacao",
                        },
                        payment: {
                            payment_method_id: this.TipoCartao,
                            card_name: this.nomeTitular,
                            card_number: this.numCartao.replaceAll('-', ''),
                            card_expdate_month: this.CodMes,
                            card_expdate_year: this.CodAno,
                            card_cvv: this.numcvc,
                            split: this.CodParcela
                        }
                    }
                    //console.log(this.data)
                    this.$http.post(this.$apiUrl + "/post?url=https://api.intermediador.yapay.com.br/api/v3/transactions/payment", this.data)
                        .then(response => {
                            if (response.ok) {
                                this.retornopagamento = response.body;
                                let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                                dadosArmazenados = JSON.parse(dadosArmazenados);
                                dadosArmazenados.pagamento = []
                                dadosArmazenados.pagamento.push({
                                    FormaPgto: 'Yapay', //+ this.retornopagamento.data_response.transaction.transaction_id,
                                    TipoPgto: 'cartao',
                                    CodId: '1',
                                    ValorTotal: this.TotalProdutos,
                                    FotoComprova: '',
                                    PedidoId:'',
                                });
                                
                                //console.log(response)
                                localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))
                                this.$redirect("/pedidoRealizado");
                            }
                        },
                            error => {
                                try {
                                    this.$mensagem_erro(error.data.error_response.general_errors[0].code + " - " + error.data.error_response.general_errors[0].message)

                                } catch {
                                    try {
                                        this.$mensagem_erro(error.data.error_response.validation_errors[0].code + " - " + error.data.error_response.validation_errors[0].message_complete)
                                    } catch {
                                        console.log(error)
                                        this.$mensagem_erro("Verifique os dados do cartão.")
                                    }
                                }
                            });
                }

            },
            BuscaCartao(cardnumber) {
                cardnumber = cardnumber.replace(/[^0-9]+/g, '');
                var cards = {
                    3: /^4[0-9]{12}(?:[0-9]{3})/, //visa
                    4: /^5[1-5][0-9]{14}/, //mastercard
                    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
                    5: /^3[47][0-9]{13}/, //amex
                    //discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
                    20: /^(606282\d{10}(\d{3})?)|(3841\d{15})/, //hipercard
                    16: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/, //elo
                    //jcb: /^(?:2131|1800|35\d{3})\d{11}/,
                    //aura: /^(5078\d{2})(\d{2})(\d{11})$/
                };

                for (var flag in cards) {
                    if (cards[flag].test(cardnumber)) {
                        return flag;
                    }
                }

                return false;
            },
            Buscacep(value) {
                this.$http
                    .get("https://viacep.com.br/ws/" + value + "/json/")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.Retornocep = tiu2

                        if (this.Retornocep != null) {
                            this.endereco = this.Retornocep.logradouro;
                            this.numeroend = null;
                            this.complemento = this.Retornocep.complemento;
                            this.siglaEstado = this.Retornocep.uf;
                            this.nomeCidade = this.Retornocep.localidade;
                            this.nomeBairro = this.Retornocep.bairro;
                            this.showend = true;
                            this.btnavancar = true;
                        }
                        else {
                            alert("CEP inexistente");
                        }
                    });
            },
            Carregaparcelas() {
                if (this.numCartao != '') {
                    this.TipoCartao = this.BuscaCartao(this.numCartao);
                    let dados = {
                        token_account: this.TokenYapay,
                        price: this.TotalProdutos.toString(),
                        type_response: "J"
                    }
                    let retorno = []
                    console.log(dados)
                    this.$http.post(this.$apiUrl + "/post?url=https://api.intermediador.yapay.com.br/v1/transactions/simulate_splitting", dados)
                        .then(response => {
                            if (response.ok) {
                                try {
                                    console.log(response)
                                    retorno = response.body.data_response.payment_methods.filter(x => x.payment_method_id == parseInt(this.TipoCartao, 10));

                                    if (retorno != '') {
                                        retorno[0].splittings.forEach((itemex) => {
                                            if (itemex.split_rate == 0) {
                                                this.retornoparcelas.push({ pagamentoId: itemex.split, textopagamento: itemex.split + "x de R$ " + parseFloat(itemex.value_split).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                                            }
                                            else {
                                                this.retornoparcelas.push({ pagamentoId: itemex.split, textopagamento: itemex.split + "x de R$ " + parseFloat(itemex.value_split).toFixed(2).toString().replace('.', ',') })
                                            }

                                        });
                                    }
                                    else {
                                        this.$mensagem_erro("Número de cartão inválido para esta compra.")
                                    }

                                } catch {
                                    this.$mensagem_erro("Número de cartão inválido para esta compra.")
                                }

                            }
                        },
                            error => {
                                this.$mensagem_erro("Número de cartão inválido para esta compra.")
                                console.log(error)
                            });
                }
            },
        },
        created() {
            let usuarioTmp = {
                email: '',
                senha: '',
            }
            usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
            if (usuarioTmp != null) {
                let _usuario = {
                    email: usuarioTmp.email,
                    senha: usuarioTmp.senha,
                };
                this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                    (response) => {
                        // get body data
                        this.usuario = response.body;
                    },
                );
            }

            this.$http
                .get("https://api.ipify.org/?format=json")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.IP = tiu2
                });

            this.$http
                .get(this.$apiUrl + "/configoperadora/3")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.DadosYapay = retornoAPI;
                    this.TokenYapay = this.DadosYapay.tokenOperadora;
                });
            //this.TokenYapay = '27591a88edf668b';


            let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
            dadosArmazenados = JSON.parse(dadosArmazenados);

            let _produtos = []
            let _totalProdutos = 0;
            let _cupom = 0;
            if (dadosArmazenados.produtos != '') {
                dadosArmazenados.produtos.forEach((itemprod) => {
                    _produtos.push({
                        description: itemprod.produto.nomeProduto,
                        quantity: itemprod.qtde,
                        price_unit: itemprod.produto.valorAte,
                        code: itemprod.produto.produtoId,
                        sku_code: itemprod.produto.referencia,
                    })
                    _totalProdutos += parseFloat(itemprod.produto.valorAte * itemprod.qtde)
                });
            }

            if (dadosArmazenados.avulsos != '') {
                if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                    dadosArmazenados.avulsos.forEach((itemavul) => {
                        _produtos.push({
                            description: itemavul.avulso.nomeItemAvulso,
                            quantity: "1",
                            price_unit: itemavul.avulso.valorDe,
                            code: itemavul.avulso.itemAvulsoId,
                            sku_code: itemavul.avulso.itemAvulsoId,
                        })
                        _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                    });
                }
            }

            //if (dadosArmazenados.foto != '') {
            //    if (dadosArmazenados.foto[0].imagemUrl != "Sem foto") {
            //        dadosArmazenados.foto.forEach((itemfoto) => {
            //            _produtos.push({
            //                description: "Envio de Foto junto Cesta",
            //                quantity: "1",
            //                price_unit: itemfoto.valor,
            //                code: "999999",
            //                sku_code: "999999",
            //            })
            //            _totalProdutos += parseFloat(itemfoto.valor)
            //        });
            //    }
            //}

            if (dadosArmazenados.informacao != '') {
                _totalProdutos += parseFloat(dadosArmazenados.informacao[0].ValorFrete)
            }

            if (dadosArmazenados.cupom != '') {
                _cupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10)
            }

            this.produtos = _produtos;
            this.TotalProdutos = _totalProdutos - _cupom;
        },
    }

</script>

