import { render, staticRenderFns } from "./PageMeusPedidosDetalhe.vue?vue&type=template&id=7ad4bc69&scoped=true&"
import script from "./PageMeusPedidosDetalhe.vue?vue&type=script&lang=js&"
export * from "./PageMeusPedidosDetalhe.vue?vue&type=script&lang=js&"
import style0 from "./PageMeusPedidosDetalhe.vue?vue&type=style&index=0&id=7ad4bc69&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad4bc69",
  null
  
)

export default component.exports