<template>

    <footer class="footer set-img" style="background-Image: url('/img/rodapeimg.jpg');">
        <div class="container">
            <div class="row mb-5">

                <!-- col 1 (Menu + Ajuda) -->
                <div class="col-6 col-md-3 col-lg-2 mb-5 mb-lg-0">
                    <div class="footer_column">
                        <div class="footer_title">Menu</div>

                        <ul class="footer_list">
                            <li><a href="" @click="$redirect_reload('/')">Home</a></li>
                            <li><a href="" @click="$redirect_reload('/contato')">Contato</a></li>
                            <li><a href="" @click="$redirect_reload('/sobre')">Sobre Nós</a></li>
                            <li><a href="" @click="$redirect_reload('/blog')">Blog</a></li>
                        </ul>

                        <div class="footer_title">Minha Conta</div>
                        <ul class="footer_list">
                            <li><router-link to="/login">Login</router-link></li>
                            <li><router-link to="/meusDados">Meus Dados</router-link></li>
                            <li><router-link to="/carrinho">Meu Carrinho</router-link></li>
                            <li><router-link to="/meusPedidos">Meus Pedidos</router-link></li>
                        </ul>
                    </div>
                </div>
                <!-- col 3 (Empório) -->
                <div class="col-6 col-md-3 col-lg-2 mb-5 mb-lg-0">
                    <div class="footer_column">
                        <div class="footer_title">Suporte</div>

                        <ul class="footer_list">
                            <li  v-for="linhaRet in Retorno" :key="linhaRet.institucionalId">
                                <a href="" @click="$redirect_reload('/informacoesgerais/'+ linhaRet.institucionalId + '/' + linhaRet.nome.replaceAll(' ','-').replaceAll('/',''))">
                                    {{ linhaRet.nome }}
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
                <!-- col 2 (Vinhos + Minha conta) -->
                <div class="col-6 col-md-3 col-lg-2 mb-5 mb-lg-0">
                    <div class="footer_column">
                        <div class="footer_title">Produtos</div>

                        <!-- Array Vinhos -->
                        <ul class="footer_list">
                            <li v-for="segmento in Segmentos.segmento" :key="segmento.segmentoProdNivel1Id">
                                <a href="" @click="$redirect_reload('/produtos/' + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))" style="color:#bbbbbb;">{{ segmento.nomeSegmento}}</a>
                            </li>
                            <li>
                                <a href="" @click="$redirect_reload('/produtosrestaurantes/' + '1' + '/' + '0' + '/' + 'Restaurantes')" style="color:#bbbbbb;">Restaurantes</a>
                            </li>
                        </ul>


                    </div>
                </div>
            </div>

            <!-- logo + social -->
            <div class="row">
                <div class="col-lg-5 mb-5 mb-md-0">
                    <div style="background-color: #ffffff; padding: 20px; border-radius: 15px;width: 210px;">
                    <router-link to="/">
                        <img src="@/assets/img/LogoLaBodeguita.svg" alt="Logo" style="height: 170px;color:#ffffff">
                    </router-link>
                  </div>
                </div>

            </div>
            <!-- Contact -->
            <div class="row">
                <div class="col-lg-5">
                    <div class="footer-contact">
                        <span v-if="informacoes!=''" v-html="informacoes[0].resumo1"></span>
                    </div>
                    <div class="social">
                        <a v-if="Yout!='' && Yout[0].visivel" :href="Yout[0].nome" target="_blank">
                            <img class="social-icon" src="@/assets/img/social/youtube.png" alt="Link youtube">
                        </a>
                        <a v-if="Face!='' && Face[0].visivel" :href="Face[0].nome" target="_blank">
                            <img class="social-icon" src="@/assets/img/social/facebook.png" alt="Link facebook">
                        </a>
                        <a v-if="Insta!='' && Insta[0].visivel" :href="Insta[0].nome" target="_blank">
                            <img class="social-icon" src="@/assets/img/social/instagram.png" alt="Link instagram">
                        </a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-5 mt-3">
                    <img :src="logSelo" class="img-fluid" style="border-radius:10px;height:100px;" />
                </div>
            </div>
            <div class="row">
                <div class="col text-center py-4 mt-5">
                    <a href="https://avancedigital.com.br/" target="_blank"><img src="@/assets/img/avance.png" alt=""></a>
                </div>
            </div>
        </div>
    </footer>

</template>

<script>
    export default {
        data() {
            return {
                Segmentos: [],
                informacoes: [],
                Face: [],
                Insta: [],
                Yout: [],
                logo_footer: '@/assets/img/10.png',
                logSelo: '',
                resultSelo: [],
                Retorno: [],
                linhaRet: {},
            }
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/informacoes")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.informacoes = tiu2;
                });

            this.Segmentos = this.$segmentos;

            this.$http
                .get(this.$apiUrl + "/institucional/telas/facebook")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Face = tiu2
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/instagram")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Insta = tiu2
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/youtube")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Yout = tiu2
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/selodeseguranca")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.resultSelo = tiu2
                    if (this.resultSelo.institucionalImg != '') {
                        this.logSelo = this.$Url + this.resultSelo[0].institucionalImg[0].urlImagem;
                    }
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/informacoesgerais")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2.filter(x => x.visivel);
                });
        },
        mounted() {

        },
        components: {
        },
    }
</script>

<style scoped>

    .social-icon {
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        margin-left: 8px;
        transition: transform .2s ease-in;
    }

        .social-icon:hover {
            transform: scale(1.1);
        }
</style>