<template>
  
  <section class="depoimentos">
    <div class="container">
      <div class="row">
        <h6 class="text-black">Depoimentos</h6>
        <hr>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-10">

          <carousel class="pt-5" :autoplay="true" :responsive="{0:{items:1,nav:false},600:{items:1,nav:true}}">

            <div class="text-center">

              <h4 class="text_depo">Aliquam nec ipsum tincidunt diam malesuada accumsan rhoncus blandit mauris. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce urna tortor, tristique id orci eu!</h4>
              <div class="autor_depo">
                <img class="img-depo img-fluid" src="img/depo.jpg" alt="">
                <h6>Nome Sobrenome</h6>
              </div>

            </div>

            <div class="text-center">

              <h4 class="text_depo">Aliquam nec ipsum tincidunt diam malesuada accumsan rhoncus blandit mauris. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce urna tortor, tristique id orci eu!</h4>
              <div class="autor_depo">
                <img class="img-depo img-fluid" src="img/depo.jpg" alt="">
                <h6>Nome Sobrenome</h6>
              </div>

            </div>

            <div class="text-center">

              <h4 class="text_depo">Aliquam nec ipsum tincidunt diam malesuada accumsan rhoncus blandit mauris. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce urna tortor, tristique id orci eu!</h4>
              <div class="autor_depo">
                <img class="img-depo img-fluid" src="img/depo.jpg" alt="">
                <h6>Nome Sobrenome</h6>
              </div>

            </div>
            
          </carousel>

        </div>
      </div>
    </div>
  </section>
  
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  components: { 
    carousel 
  }
}

</script>

<style scoped>

  .text_depo {
    color: #828282;
    font-weight: 400;
    font-style: italic;
  }

  .autor_depo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px auto;
    max-width: 220px;
  }

  .img-depo {
    width: 70px !important;
    height: 70px;
    border-radius: 50%;
  }

</style>