<template>


    <section class="main-carrinho">
        <CheckAcesso />

        <section :style="{ backgroundImage: 'url(' + $Url + fundotela + ')',backgroundColor:'#e8eaf6',backgroundSize:'cover',backgroundRepeat:'no-repeat' }">

            <div class="container">
                <div class="row py-5">
                    <div class="col-lg-6 hidden-sm-md">
                        <div class="carrinho-bg set-img">

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="box_content">
                            <h3 class="titulo-principal text-center mb-4">Informação</h3>

                            <!-- Status -->
                            <div class="status-novaConta px-md-5">
                                <div class="status-item pos-item2">
                                    <div class="status-number"><i class="fas fa-check"></i></div>
                                    <span class="status-text">Login</span>
                                </div>
                                <div class="status-item active pos-item2">
                                    <div class="status-number"><span>2</span></div>
                                    <span class="status-text">Endereço</span>
                                </div>
                                <div class="status-item">
                                    <div class="status-number"><span>3</span></div>
                                    <span class="status-text">Pagamento</span>
                                </div>
                            </div>
                            <hr>
                            <!-- Form -->
                            <div class="text-center">
                                <h5 class="subtitulo mb-0">{{ Titulos[0].nome }}</h5>
                                <p class="texto-mensagem">{{ Titulos[0].referencia }}</p>
                            </div>
                            <form class="px-md-5 my-5">
                                <div class="form-group row">
                                    <!-- Endereço -->
                                    <div class="form-group row align-items-center mb-3">
                                        <div class="col-sm-12">
                                            <label for="inputcvc" class="col-sm-5 col-form-label label-pagto2">CEP</label>
                                            <div class="input-group">
                                                <input v-model="numCep" v-mask="'#####-###'" type="text" maxlength="10" inputmode="numeric" class="form-control" @input="limpa()">
                                                <div class="input-group-append">
                                                    <a href="" @click.prevent="Buscacep(numCep)" class="input-group-text" id="basic-addon2">Verificar</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="showend" class="form-group row align-items-center mb-2">
                                        <div class="col-sm-9">
                                            <label for="inputEnd" class="col-sm-5 col-form-label label-pagto2">Endereço</label>
                                            <input v-model="endereco" type="text" class="form-control" maxlength="400">
                                        </div>
                                        <div class="col-sm-3">
                                            <label for="inputComp" class="col-sm-5 col-form-label label-pagto2">Numero</label>
                                            <input v-model="numeroend" type="number" class="form-control">
                                        </div>
                                    </div>
                                    <div v-if="showend" class="form-group row align-items-center mb-2">
                                        <div class="col-sm-5">
                                            <label for="inputComp" class="col-sm-12 col-form-label label-pagto2">Complemento</label>
                                            <input v-model="complemento" type="text" class="form-control" maxlength="40">
                                        </div>
                                        <div class="col-sm-5">
                                            <label for="inputCidade" class="col-sm-12 col-form-label label-pagto2">Cidade</label>
                                            <input v-model="nomeCidade" type="text" class="form-control" maxlength="200">
                                        </div>
                                        <div class="col-sm-2">
                                            <label for="inputCidade" class="col-sm-12 col-form-label label-pagto2">Estado</label>
                                            <input v-model="siglaEstado" v-mask="'AA'" type="text" class="form-control" maxlength="200">
                                        </div>
                                    </div>
                                    <div v-if="showend" class="form-group row align-items-center mb-2">
                                        <div class="col-sm-12">
                                            <label for="inputBairro" class="col-sm-12 col-form-label label-pagto2">Bairro</label>
                                            <input v-model="nomeBairro" type="text" class="form-control" maxlength="200">
                                        </div>
                                    </div>
                                </div>
                                 <div class="col-md-10 offset-md-1 col-lg offset-lg-0 mt-4 mt-md-0 vHorarios">
                                    <label v-if="ListaEntregaFrete != ''" style="font-size:15px;font-weight:400;padding-top:10px;">Selecione tipo da Entrega<span style="color:red;">*</span></label>
                                    <div v-for="(listaFrete,index) in ListaEntregaFrete" :key="index" class="form-check">
                                        <input class="form-check-input"
                                               type="radio"
                                               :value="listaFrete"
                                               @click="ValorFrete=listaFrete.valor,calcvalorTotal()"
                                               v-model="Frete">{{ listaFrete.nome }} - R$ {{ parseFloat(listaFrete.valor).toFixed(2).toString().replace('.',',') }} Prazo: {{ listaFrete.prazo }} dias úteis
                                    </div>
                                </div>
                            </form>
                            <div class="voltar border-top pt-4 hidden-md hidden-lg hidden-xl">
                                <a href="" @click="$redirect_reload('/carrinho')" class="voltar text-secondary">Voltar</a>
                            </div>
                            <div class="box_content-footer pt-md-5">
                                 <div class="voltar hidden-sm"><a href="" @click="$redirect_reload('/carrinho')" class="voltar text-secondary">Voltar</a></div>
                                <div class="mt-4 mt-md-0">
                                   <a @click="ListaEntregaFrete=[],numCep='',showend=false,EndNovo=true" class="btn btn-outline-danger btn-maisProdutos">Outro Endereço</a>
                                  <button @click="carregaDados()" class="btn btn-success mt-0 px-md-5 mt-0">Confirmar Endereço</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>

</template>

<script>

    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
    import moment from 'moment';

    export default {
        components: {
            CheckAcesso,
        },
        data() {
            return {
                ProdutoId: 0,
                endereco: '',
                numeroend: '',
                complemento: '',
                siglaEstado: '',
                nomeCidade: '',
                nomeBairro: '',
                numCep: '',
                Titulos: [],
                fundotela: '',
                Retornocep: {},
                showend: false,
                UltimoPedido: {},
                PedidosEndereco: [],
                PedidosCliente: [],
                ListaEntregaFrete: [],
                listaFrete: {},
                Frete: '',
                ValorFrete:0,
                ListaProdutos: [],
                listaProduto: {},
                ListaAvulsos: [],
                listaAvulso: {},
                EndNovo:false,
                valorTotal:0,
                ValorCupom:0,
            }
        },
        created() {
            this.calcvalorTotal();
            //----------------- Titulos planos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textoinformacao")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Titulos = retornoAPI;

                    if (this.Titulos[0].institucionalImg != '') {
                        this.fundotela = this.Titulos[0].institucionalImg[0].urlImagem
                    }
                    else {
                        this.fundotela = '';
                    }
                });

            if (this.EndNovo == false){
                    let usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
                    if (usuarioTmp != null) {
                        this.ClienteId = parseInt(usuarioTmp.usuarioId, 10)
                            
                        this.$http
                            .get(this.$apiUrl + "/Pedido/completo")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                //console.log(tiu2)
                                if (this.PedidoId == null) {
                                    this.PedidosCliente = tiu2.filter(x => x.cliente.clienteId == parseInt(this.ClienteId, 10)).sort(function (a, b) {
                                        return a.pedidoId - b.pedidoId;
                                    }).reverse();
                                     
                                    if (this.PedidosCliente.length > 0) {
                                        this.UltimoPedido = this.PedidosCliente[0]
                                        
                                        //carrega Pedido endereco
                                        this.$http
                                            .get(this.$apiUrl + "/PedidoEndereco")
                                            .then((res2) => res2.json())
                                            .then((tiu2) => {
                                                
                                                this.PedidosEndereco = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))

                                                if (this.PedidosEndereco != null) {
                                                console.log(this.PedidosEndereco)
                                                this.numCep = this.PedidosEndereco[0].cep;
                                                    this.endereco = this.PedidosEndereco[0].endereco;
                                                    this.numeroend = this.PedidosEndereco[0].numero;
                                                    this.complemento = this.PedidosEndereco[0].complemento;
                                                    this.siglaEstado = this.PedidosEndereco[0].nomeEstado;
                                                    this.nomeCidade = this.PedidosEndereco[0].nomeCidade;
                                                    this.nomeBairro = this.PedidosEndereco[0].bairro;
                                                    this.showend = true;

                                                    this.CalculaFrete();
                                                }

                                        });

                                    }
                                    else{
                                        let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                                        if (dadosArmazenados) {
                                            dadosArmazenados = JSON.parse(dadosArmazenados);
                                            this.ProdutoId = dadosArmazenados.produtos[0].produtoId;

                                        /*  if (dadosArmazenados.informacao != '') {
                                                this.numCep = dadosArmazenados.informacao[0].CEP;
                                                this.Buscacep(this.numCep);
                                            } */

                                        }
                                    }
                                }
                        });

                    }
             }

        },
        methods: {
            currentDateTime(vdate) {
                return moment(vdate).format('LL');
            },
            limpa() {
                if (this.numCep == '') {
                    this.showend = false
                }
            },
            Buscacep(value) {
                this.$http
                    .get("https://viacep.com.br/ws/" + value + "/json/")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.Retornocep = tiu2

                        if (this.Retornocep != null) {
                            this.endereco = this.Retornocep.logradouro;
                            this.numeroend = null;
                            this.complemento = null;
                            this.siglaEstado = this.Retornocep.uf;
                            this.nomeCidade = this.Retornocep.localidade;
                            this.nomeBairro = this.Retornocep.bairro;
                            this.showend = true;

                            this.CalculaFrete();
                        }
                        else {
                            alert("CEP inexistente");
                        }
                    });
            },
            carregaDados() {
                if (this.numCep == '') {
                    this.$mensagem_normal("Preencha o CEP de entrega e clique em verificar.");
                } else if (this.endereco == '') {
                    this.$mensagem_normal("Preencha o endereço de entrega");
                } else if (this.numeroend == null) {
                    this.$mensagem_normal("Preencha o número do endereço de entrega");
                } else if (this.complemento == '') {
                    this.$mensagem_normal("Preencha o complemento do endereço de entrega");
                } else if (this.nomeCidade == '') {
                    this.$mensagem_normal("Preencha a cidade do endereço de entrega");
                } else if (this.siglaEstado == '') {
                    this.$mensagem_normal("Preencha a sigla do estado do endereço de entrega");
                } else if (this.nomeBairro == '') {
                    this.$mensagem_normal("Preencha o bairro do endereço de entrega");
                } else if (this.Frete == ''){
                     this.$mensagem_normal("Por favor escolha o tipo de entrega");
                }
                else {
                    let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                        if (dadosArmazenados) {
                            dadosArmazenados = JSON.parse(dadosArmazenados);
                            if (dadosArmazenados.agenda[0] != null) {
                                dadosArmazenados.agenda[0] = {
                                    estado: this.siglaEstado,
                                    cidade: this.nomeCidade,
                                    bairro: this.nomeBairro,
                                    endereco: this.endereco,
                                    numero: this.numeroend,
                                    complemento: this.complemento,
                                    cep: this.numCep,
                                };
                            }
                            else {
                                dadosArmazenados.agenda.push({
                                    estado: this.siglaEstado,
                                    cidade: this.nomeCidade,
                                    bairro: this.nomeBairro,
                                    endereco: this.endereco,
                                    numero: this.numeroend,
                                    complemento: this.complemento,
                                    cep: this.numCep,
                                });
                            }
                             dadosArmazenados.informacao = []
                             dadosArmazenados.informacao.push({ TipoFrete: this.Frete.nome, ValorFrete: this.Frete.valor, Prazo: this.Frete.prazo, CEP: this.numCep });

                        }
                    localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))
                    this.$redirect_reload("/novaContaPagamento");
                }
            },
             calcvalorTotal() {
                let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                if (!dadosArmazenados) {
                    return 0;
                }
                else {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let vproduto = 0;
                     if (dadosArmazenados.cupom != '') {
                       this.ValorCupom = dadosArmazenados.cupom[0].ValorCupom;
                     }
                    if (dadosArmazenados.produtos != '') {
                        vproduto = dadosArmazenados.produtos.reduce((sum, item) => sum + (item.produto.valorAte * parseInt(item.qtde, 10)), 0);
                    }

                    let vavulso = 0
                    if (dadosArmazenados.avulsos != '') {
                       if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                           vavulso = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                       }
                    }

                    this.valorTotal = (vproduto + vavulso) - this.ValorCupom;
                }
            },
            CalculaFrete() {
                if (this.numCep == '') {
                    this.$mensagem_normal("Por favor preencha o CEP de entrega");
                }
                else {
                    this.ListaEntregaFrete = [];
                    let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        this.ListaProdutos = dadosArmazenados.produtos;
                            if (dadosArmazenados.avulsos != '') {
                            if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                                this.ListaAvulsos = dadosArmazenados.avulsos;
                            }
                            }
                    }
                    this.ValorFrete = '';
                    this.$http
                        .get(this.$apiUrl + "/entregacorreio")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.CepOrigem = tiu2[0].cep;

                            let InformacaoFrete = {
                                Produtos: [],
                                ProdutosAvulsos: [],
                                CepOrigem: this.CepOrigem,
                                CepDestino: this.numCep,
                                Correios: true,
                                Entregas: true
                            }

                            //console.log(this.ListaProdutos)
                            this.ListaProdutos.forEach((itemp) => {
                                itemp.produto.qtdeMinima = parseInt(itemp.qtde,10);
                                //console.log(itemp.produto)
                                InformacaoFrete.Produtos.push(itemp.produto);
                            });

                            this.ListaAvulsos.forEach((itempa) => {
                                InformacaoFrete.ProdutosAvulsos.push(itempa.itemAvulso);
                            });

                            //console.log(InformacaoFrete)

                            this.$http
                                .post(this.$apiUrl + "/EntregaFrete/Calcular", InformacaoFrete)
                                .then(response => {

                                    this.ListaEntregaFrete = response.body
                                    //console.log("aqui");
                                     //console.log(this.ListaEntregaFrete)
                                    /*  let vrecebe = []
                                    this.ListaEntregaFrete.forEach((itemex) => {
                                       if (itemex.nome.indexOf("Frete") != -1 || itemex.nome.indexOf("Miguel") != -1){
                                           vrecebe.push(itemex);
                                       }
                                    });

                                    if (vrecebe!= ''){ 
                                        this.ListaEntregaFrete = vrecebe;
                                    } */
                                     let vrecebe = []
                                    this.ListaEntregaFrete.forEach((itemex) => {
                                         //aqui valida compra minima
                                      /*  if (itemex.compraMinima > 0){
                                           this.MostraCompraminia = "Nas Compras acima de R$ " + parseFloat(itemex.compraMinima).toFixed(2).toString().replace('.',',') + ", Você ganha frete Grátis!";
                                       } */
                                        console.log(this.valorTotal)
                                       if (this.valorTotal > itemex.compraMinima){
                                           vrecebe.push(itemex);
                                       }
                                    });

                                    if (vrecebe!= ''){ 
                                        let _filtraGratis = vrecebe;
                                        let vrecebe2 = []
                                         _filtraGratis.forEach((itemex2) => {
                                             if (itemex2.nome.indexOf("Frete") != -1 || itemex2.nome.indexOf("Miguel") != -1){
                                              vrecebe2.push(itemex2);
                                             }
                                         });
                                       
                                        if (vrecebe2 != ''){
                                            this.ListaEntregaFrete = vrecebe2;
                                            //this.MostraCompraminia = "";
                                        }
                                        else{
                                           this.ListaEntregaFrete = _filtraGratis;
                                        }
                                    }
                                },
                                    error => {
                                        this.$mensagem_erro("CEP inválido.")
                                        console.log(error)
                                    });

                        });
                }

            },
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/nova_conta.css';
</style>