<template>
  
  <section class="topo_internas2 set-img" :style="{ backgroundImage: 'url(' + require('@/assets/img/13.png') + ')' }">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="titulo_principal text-shadow">{{ titulo }}</h2>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  props: ['titulo'],

}
</script>

<style scoped>

  .topo_internas2 {
    color: #fff;
    padding: 100px 0;
    text-align: center;
  }

  .text-shadow {
    text-shadow: 1px 1px 10px #000;
  }

</style>