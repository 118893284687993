<template>

    <section class="vinhos">
        <TopoInternas :titulo="NomeSegmentoFilho" class="hidden-sm"></TopoInternas>
        <div class="shop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                        <div class="row">
                            <div class="col">
                                <div class="shop_bar mt-2  clearfix">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="shop_product_count mb-4 mb-md-0" style="font-size:25px;">
                                                {{ NomeSegmentoFilho }}
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="">
                                                <v-select v-model="Ordemselected" @input="MudaOrdem" style="position:relative;z-index:99999999;"
                                                          :options="Ordenar"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome"
                                                          class="style-chooser">
                                                </v-select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row" style="position:relative;z-index:0;">

                            <div class="col-6 col-lg-3 col-xl-3" v-for="produto in Produtos" :key="produto.produtoId">

                                <div class="product_item border_active text-center">
                                    <router-link :to="/produtoDetalhe/ + produto.produtoId">
                                        <div class="product_image d-flex flex-column align-items-center justify-content-center">
                                            <img v-if="produto.imagens[0] != null" :src="$Url  + produto.imagens[0].urlImg" alt="" style="height:240px;width:auto;">
                                            <img v-else :src="$Url  + '/imagens/produtos/indisponivel.jpg)'" alt="">
                                        </div>
                                    </router-link>

                                    <div class="product_content" style="height:90px;padding-top: 40px;">
                                        <div class="product_name" style="height:50px;">
                                            <div>
                                                <router-link v-if="produto.indisponivel != true" :to="/produtoDetalhe/ + produto.produtoId" style="color: #000000; font-size: 14px; font-weight: 400;">{{ produto.nomeProduto }}<br />{{ produto.infoExtra }}</router-link>
                                                <a v-else class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                                            </div>
                                        </div>
                                        <div v-html="produto.resuno" style="color: #5E1836; font-size: .7rem;text-transform:full-width;"></div>
                                        <div style="display:inline-flex;">
                                            <span v-if="produto.valorDe > 0" class="preco-antigo" style="font-size:11px;padding-top:5px;">R$ <span style="text-decoration: line-through;">{{ parseFloat(produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span> por </span>
                                            <div class="product_price">R$ {{ parseFloat(produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <!--<div class="shop_content">-->
                        <!-- Shop Page Navigation -->
                        <!--<div class="shop_page_nav d-flex flex-row justify-content-center">
                                <div class="page_prev d-flex flex-column align-items-center justify-content-center"><i class="fas fa-chevron-left"></i></div>
                                <ul class="page_nav d-flex flex-row">
                                    <li><a href="#">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">...</a></li>
                                    <li><a href="#">9</a></li>
                                </ul>
                                <div class="page_next d-flex flex-column align-items-center justify-content-center"><i class="fas fa-chevron-right"></i></div>
                            </div>

                        </div>-->

                    </div>
                </div>
            </div>
        </div>
    </section>

</template>


<script>
    import TopoInternas from '../shared/TopoInternas.vue'
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        components: {
            vSelect,
            TopoInternas
        },
        data() {
            return {
                MenuSegmentos: [],
                MenuSegmento: {},
                ProdutosGerais: [],
                Produtos: [],
                produto: {},
                ListaEtiquetaImg: [],
                NomeSegmentoFilho: '',
                Ordemselected: 'Ordenar por',
                Ordenar: [
                    {
                        nome: "Maior Valor",
                        valor: 3
                    },
                    {
                        nome: "Menor Valor",
                        valor: 4
                    }
                ],
                SegselectedNav: 'Escolha Segmento',
                SegMobile: [],
                parcelas: 0,
                parcelasTexto: '',
                bg_imagem: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            }
        },
        directives: { money: VMoney },
        methods: {
            MudaOrdem() {
                if (this.Ordemselected == 3) {
                    this.Produtos.sort(function (a, b) {
                        return a.valorAte - b.valorAte;
                    }).reverse();
                } else if (this.Ordemselected == 4) {
                    this.Produtos.sort(function (a, b) {
                        return a.valorAte - b.valorAte;
                    });
                } else {
                    this.Produtos.sort(function (a, b) {
                        return a.produtoId - b.produtoId;
                    });
                }

            },
            MudaSegMobile() {
                this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.SegselectedNav.segmentoProdNivel2Id, 10))).sort(function (a, b) {
                    return a.produtoid - b.produtoid
                });
                this.NomeSegmentoFilho = this.SegselectedNav.nomeSegmento;
                if (this.SegselectedNav.imagens != '') {
                    this.bg_imagem = this.SegselectedNav.imagens[0].urlImagem;
                }
                else {
                    this.bg_imagem = '';
                }
                this.MudaOrdem();
            },
            CarregaSegmento2(value) {
                this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(value.segmentoProdNivel2Id, 10)));
                console.log(value.imagens)
                this.NomeSegmentoFilho = value.nomeSegmento;
                if (value.imagens != '') {
                    this.bg_imagem = value.imagens[0].urlImagem;
                }
                else {
                    this.bg_imagem = '';
                }
                this.MudaOrdem();

            },
            CarregaEtiqueta(value) {
                if (this.ListaEtiquetaImg.produto != null) {
                    var retorno = this.ListaEtiquetaImg.filter(x => x.produto.produtoId == value);
                    return retorno.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                }
                else {
                    return null
                }
            },
            CarregaImagemUnica(value) {
                if (value != null) {
                    value.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });

                    //value.sort(function (a, b) {
                    //    if (a.ordem > b.ordem) {
                    //        return 1;
                    //    }
                    //    if (a.ordem < b.ordem) {
                    //        return -1;
                    //    }
                    //    return 0;
                    //});
                    return this.$Url + value[0].urlImg;
                }
                else {
                    return this.$Url + "/imagens/indisponivel.jpg"
                }

            },
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/produto")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ProdutosGerais = tiu2.filter(x=> x.visivel && x.promocao);
                  
                       this.Produtos = this.ProdutosGerais.sort(function (a, b) {
                            return a.produtoid - b.produtoid
                       });
                        this.NomeSegmentoFilho = "Outlet"
                        this.MudaOrdem();

                });

            this.$http
                .get(this.$apiUrl + "/produtopremio")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaEtiquetaImg = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });

            this.$http
                .get(this.$apiUrl + "/produtotextogeral")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.parcelas = parseInt(tiu2[0].texto1, 10);
                    this.parcelasTexto = tiu2[0].texto2;
                });

        },
        mounted() {

        },
    }

</script>

<style scoped>

    @import '/css/produtos.css';
    @import '/css/vinhos.css';

    ul {
        padding-left: unset;
    }

    .form-control-sidebar {
        max-width: 80%;
    }


    .product_item {
        width: 100%;
        /*        background: #FFFFFF;
        box-shadow: 6px 4px 8px #ccc;*/
        padding-top: 130px;
        padding-bottom: 230px;
        transition: all .2s ease-in;
    }

    /*        .product_item:hover {
            box-shadow: 10px 10px 8px #ccc;
        }*/

    /********************
    Responsive
    ********************/

    /* Smartphones */
    @media (max-width:767px) {
        .product_item {
            left: 0px;
        }

        .shop_sorting {
            float: initial;
        }

        .shop {
            background: #FFFFFF;
            padding-top: 20px;
            padding-bottom: 139px;
        }
    }

    /* Tudo, menos desktop */
    @media (max-width:1199px) {
        .product_item {
            height: min-content;
            padding-top: 60px;
            padding-bottom: 80px;
        }

        .product_price {
            margin-top: 0px;
        }
    }
</style>
