<template>
  
  <section class="experiencia_detalhe">

    <TopoInternas2 titulo="Vinhos Portugueses Segunda Edição"></TopoInternas2>
    
    <div class="container mt-4 mb-5 mb-lg-0">
      <div class="row">
        <div class="col-lg-8">
          <div class="content my-5">
            <h4 class="mb-5">{{ titulo }}</h4>
            <p v-html="texto"></p>         
          </div>
        </div>
        <div class="col-lg-4">
          <div class="side_content card_side">
            <h5>Data e Hora</h5>
            <span class="subtexto">10/10/2021</span><i class="fas fa-grip-lines-vertical text-secondary mx-4"></i><span>19h às 21h</span>
            <h5 class="mt-4">Local</h5>
            <span class="subtexto d-block">Rua Nononononon, 1234/56</span>            
            <span class="subtexto d-block">Bairro Nonononononnon nono</span>            
            <span class="subtexto d-block">Cidade/RS</span> 
            <a class="link_decoration text-success" href="#">Link da Localização</a>           
            <h5 class="mt-4">Valor</h5>
            <span class="subtexto d-block">R$ 123,45</span> 
            <h5 class="text-danger mt-4">Vagas Limitadas</h5>
            <span class="subtexto text-danger d-block">Restam apenas 12 vagas</span> 
            <a href="#" class="btn btn-lg btn-success d-block mt-5">Reservar Vaga</a>
          </div>
        </div>
      </div>
    </div>

    <Depoimentos />
    <Galeria />


  </section>

</template>

<script>
import TopoInternas2 from '../shared/TopoInternas2.vue'
import Depoimentos from '../shared/Depoimentos.vue'
import Galeria from '../shared/Galeria.vue'

export default {
  components: {
    TopoInternas2,
    Depoimentos,
    Galeria
  },
  data() {
    return {
      titulo: 'Uma nova experiência',
      texto: 'Integer tellus ex, mattis quis semper at, tincidunt quis dui. Praesent eget lorem blandit, egestas massa sodales, ullamcorper nunc. Fusce purus tortor, congue ut lacus nec, semper fermentum ex. <br /> Integer vitae mi auctor, molestie sem et, luctus tellus. Proin tempus sed lectus at rutrum. Aliquam eu efficitur erat, tincidunt condimentum nibh. Proin nunc sem, maximus sit amet molestie eget, maximus quis sapien. Aenean tristique ut arcu eget posuere. Nulla non mi nulla. <br /> Nunc varius ligula nec aliquet sollicitudin. Pellentesque ac orci odio. Morbi aliquet facilisis neque eu rhoncus. Curabitur at tellus ut sapien molestie ultricies semper at lorem. Cras a sagittis tortor, nec pulvinar eros. Morbi tortor orci, fermentum quis nunc nec, semper venenatis quam. Sed euismod massa cursus ex laoreet, ac luctus magna commodo ex laoreet, ac luctus magna commodo.'
    }
  }
}
</script>

<style scoped>

  .content p {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .side_content h5 {
    margin-bottom: 0;
  }

  .card_side {
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 30px;
  }

  /* Desktop + Notbooks */
  @media (min-width:1200px) {
    .hidden-xl {
      display: none !important;
    }
    .content p {
      padding-right: 50px;
    }
  }

</style>