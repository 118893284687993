<template>

    <div class="newsletter">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="newsletter_container d-flex flex-lg-row flex-column align-items-lg-center align-items-center justify-content-lg-start justify-content-center">
                        <div class="newsletter_title_container">
                            <div class="newsletter_icon"><i class="far fa-paper-plane fa-4x text-white"></i></div>
                            <div class="newsletter_title">receba ofertas com preços exclusivos</div>
                            <!-- <div class="newsletter_text"><p>...and receive %20 coupon for first shopping.</p></div> -->
                        </div>
                        <div class="newsletter_content clearfix">
                                <input v-model="Emailnews" class="newsletter_input" required="required" placeholder="digite seu e-mail">
                                <button class="newsletter_button" @click="armazenaEmailNews(Emailnews)">Cadastrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import moment from 'moment'
    export default {

        components: {
        },
        data() {
            return {
                Emailnews: '',
                news: [],
                Institucional: {},
                Tela: {},
            }
        },
        methods: {
            armazenaEmailNews(value) {
               
                if (value == '') {
                    this.$mensagem_normal("Por favor digite um e-mail");
                } else {
                    if (this.validateEmail(value)) {
                        let vtipo = 23;
                        this.$http.get(this.$apiUrl + "/TelaInstitucional/" + vtipo).then(
                            (response) => {
                                this.Tela = response.body;
                                this.Institucional.tela = this.Tela;
                                this.Institucional.visivel = true;
                                this.Institucional.nome = value;
                                this.Institucional.data1 = moment().format("YYYY-MM-DD");
                                console.log(this.Institucional)
                                this.$http
                                    .post(
                                        this.$apiUrl + "/Institucional/", this.Institucional)
                                    .then(
                                        (response) => {
                                            console.log(response.body);
                                            this.$mensagem_sucesso("E-mail cadastrado com sucesso.");
                                            this.Emailnews = null
                                        },
                                        (response) => {
                                            this.$mensagem_erro(response.body);
                                        }
                                    );
                            });
                    }
                    else {
                        this.$mensagem_normal("Por favor digite um e-mail válido.");
                    }
                }

            },
            validateEmail(value) {
                if (value.length > 0) {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || false;
                }
            },
        },
        created() {
        },
    }
</script>
