<template>

    <section class="produto-detalhe">

        <div class="single_product pt-0 mb-5">
            <div class="container">

                <!-- Breadcrumb -->
                <div class="row">
                    <div class="col mt-5">
                        <div class="product_name mb-3">
                            <router-link :to="'/produtos/' + segmentoProdNivel1Id + '/' +  segmentoProdNivel2Id + '/' + nomeSegmento" style="float:right;">
                                Voltar
                            </router-link>
                            <h2> {{ Produto.nomeProduto }}</h2>

                        </div>
                    </div>
                </div>

                <div class="row">

                    <!-- Selected Image -->
                    <div class="col-lg-5 order-lg-2 order-1">
                        <div class="image_selected  text-center py-5">
                            <lingallery :iid.sync="currentId" :addons="{ enableLargeView: true }" :width="450" :height="560" :items="ImgProduto" />
                        </div>
                    </div>

                    <!-- Description -->
                    <div class="col-lg-6 offset-lg-1 col-12 order-2 mt-4 mt-lg-0">
                        <div class="product_description">
                            <div class="product_name"><h4>Detalhes</h4></div>

                            <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7" class="descricaoProduto align-items-center justify-content-between mb-3">
                                <div class="row">
                                    <div class="col-md-5 col-xs-12">
                                        <span class="color-light mr-10 mb-2">País: </span>
                                        <span class="color-principal"> {{ Produto.pais }} </span>
                                    </div>
                                    <div class="col-md-7 col-xs-12" style="display:inline-flex;">
                                        <span class="color-light mr-10 mb-2">Região: </span>
                                        <span class="color-principal">{{ Produto.origem }}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5 col-xs-12">
                                        <span class="color-light mr-10 mb-2">Uva: </span>
                                        <span v-if="Produto.filtroMateriaPrima!=null" class="color-principal"> {{ Produto.filtroMateriaPrima.nomeMateriaPrima }} </span>
                                    </div>
                                    <div class="col-md-7 col-xs-12">
                                        <span class="color-light mr-10 mb-2">Tipo de Vinho: </span>
                                        <span v-if="Produto.filtroCaracteristica!=null" class="color-principal"> {{ Produto.filtroCaracteristica.nomeCaracteristica }} </span>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-12 col-xs-12">
                                        <span class="color-light mr-10 mb-2">Vinícola: </span>
                                        <span v-if="Produto.filtroFabrica!=null" class="color-principal"> {{ Produto.filtroFabrica.nomeFabrica }} </span>
                                    </div>
                                    <div class="col-md-12 col-xs-12">
                                    </div>
                                </div>

                            </div>
                            <span class="texto_detalhe" v-html="Produto.resuno">
                            </span>
                            <div v-if="UrlimgBannerGeral != null && ProdutosAvulso != ''" class="text-left my-2">
                                <a href="" @click.prevent="showModal_addProd=true"><img class="img-fluid imgcel" :src="$Url + UrlimgBannerGeral" @click="CarregaModal(0)" alt="" style="width:240px;height:auto;border-radius:15px;"></a>
                            </div>
                            <div v-else></div>
                            <span class="texto_detalhe mt-2 mb-2" v-html="Produto.descricao">
                            </span>
                            <span class="texto_detalhe mt-2 mb-2" v-html="Produto.descricao2">
                            </span>
                            <small v-if="segmentoProdNivel1Id==9" class="texto_detalhe mt-4 mb-2">
                                Vagas restantes: {{ Produto.qtdeMinima }}
                            </small>
                            <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3" class="descricaoProduto d-flex align-items-center justify-content-between mb-3 mt-3">
                                <div>
                                    <div>
                                        <span v-if="Etiquetas != ''" class="color-light">Prêmios: </span>
                                        <figure id="container" class="vfigure">
                                            <div v-for="ItemEtiqueta in Etiquetas" :key="ItemEtiqueta.produtoPremioId">
                                                <img :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" style="padding-right:5px;" />
                                                <figcaption :title="ItemEtiqueta.nomePremio" style="font-size: 12px; left: 0px; top: 17px;color:#000000;width:50px;position: absolute;">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                                <small style=" padding-right: 10px; font-size: 10px;">{{ ItemEtiqueta.nomePremio }}</small>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                                <div>
                                    <div><span class="color-principal"> </span></div>
                                </div>
                            </div>
                            <div v-if="liberaValores && segmentoProdNivel1Id==10">
                                <h4>Valores</h4>
                                            <p class="product_price" style="font-size: 13px;font-weight: 0;"> Bottle: {{ Produto.infoExtra2 }}€</p>
                                            <p class="product_price" style="font-size: 13px;font-weight: 0;"> Palettes: {{ Produto.infoExtra3 }}€</p>
                                            <p class="product_price" style="font-size: 13px;font-weight: 0;"> Full Cont.: {{ Produto.infoExtra4 }}€</p>
                            </div>
                            <div v-if="segmentoProdNivel1Id!=10" class="order_info d-flex flex-row mt-4">
                                <form action="#">
                                    <div class="clearfix" style="z-index:0;display:inline-flex;">
                                        <!-- Product Quantity -->
                                        <div v-if="Produto.descricao4=='' || Produto.descricao4==null" class="product_quantity clearfix" style="padding-right: 30px;">
                                            <span>Quantidade: </span>
                                            <input v-model="Qtde" class="form-control" autofocus type="number" min="1" style="width:100px;">
                                        </div>
                                        <div v-if="subtotal!=0" class="product_quantity clearfix">
                                            <span v-if="Produto.descricao4=='' || Produto.descricao4==null">Valor: </span>
                                            <!--<small  class="d-block product_type color-light">De: R$ {{  parseFloat(Produto.valorDe,10).toFixed(2).toString().replace('.',',') }} por</small>-->
                                            <h4 v-if="Produto.descricao4=='' || Produto.descricao4==null">R$ {{ parseFloat(subtotal).toFixed(2).toString().replace('.',',') }}</h4>
                                            <h4 v-else>Loja Parceira</h4>

                                        </div>
                                    </div>
                                    <div v-if="Produto.descricao4=='' || Produto.descricao4==null" class="button_container">
                                        <button v-if="Produto.indisponivel!=true" @click="CarregaFinal()" type="button" class="btn btn-lg btn-success w-100 mt-4">
                                            <i class="fas fa-shopping-cart text-white"></i>
                                            Adicionar ao Carrinho
                                        </button>
                                        <a v-if="Produto.indisponivel==true" class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                                    </div>
                                    <div v-else class="button_container">
                                        <a v-if="Produto.indisponivel!=true" :href="Produto.descricao4" target="_blank" class="btn btn-lg btn-success w-100 mt-4">
                                            <i class="fas fa-shopping-cart text-white"></i>
                                            IR LOJA PARCEIRA
                                        </a>
                                        <a v-if="Produto.indisponivel==true" class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                                    </div>

                                    <!-- Formas de Pagamento -->
                                    <div class="box-payment mt-5 mt-lg-2">
                                        <img class="img-fluid mt-4" src="/img/cartoes.png" alt="Bandeiras dos cartões aceitos" style="margin-top:30px;">
                                    </div>
                                    <!-- Calcular frete -->
                                    <div v-if="segmentoProdNivel1Id!=9 && Produto.descricao4=='' || Produto.descricao4==null">
                                        <div class="input-group mt-4 mt-lg-3">
                                            <input type="text" class="form-control input-frete" v-model="numCep" placeholder="Informe o CEP" aria-label="Informe o CEP" aria-describedby="button-addon2">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-success btn-frete" @click.prevent="CalculaFrete()"><i class="fas fa-shipping-fast mr-1"></i>Ver Frete</button>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-4 mt-md-0 vHorarios">
                                            <label v-if="ListaEntregaFrete != ''" style="font-size:15px;font-weight:400;padding-top:10px;">Visualizar tipos da Entrega<span style="color:red;">*</span></label>
                                            <div v-for="(listaFrete,index) in ListaEntregaFrete" :key="index" class="form-check">
                                                <span> - {{ listaFrete.nome }} <span v-if="listaFrete.valor!=0">- R$ {{ parseFloat(listaFrete.valor).toFixed(2).toString().replace('.',',') }} </span> <span v-if="listaFrete.nome.indexOf('RETIRE')== -1"> Prazo: {{ listaFrete.prazo }} {{ listaFrete.prazo == 1 ? 'dia útil' : 'dias úteis' }}</span> </span>
                                            </div>
                                        </div>
                                        <p style="color:green;font-size:20px;padding-top: 10px;">{{ MostraCompraminia }}</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="description th__mb-100 hidden-sm hidden-xs">
            <div class="container">
                <div class="row">
                    <!--<div v-for="Produtoinfo in ProdutosInfoAdicional" :key="Produtoinfo.ProdutoInfoAdicionalId" class="col-lg-2 col-12" style="display:inline-flex;padding-right:10px;">
                        <h6 class="text-black"><a href="" @click.prevent="MudaTexto(Produtoinfo)" style="color:#000000;">{{ Produtoinfo.tituloInfo }}</a></h6>
                    </div>
                    <hr>
                    <div class="col">
                        <br />
                        <p v-html="Texto"></p>
                    </div>-->
                    <tabs>
                        <tab v-for="Produtoinfo in ProdutosInfoAdicional" :key="Produtoinfo.ProdutoInfoAdicionalId" :title="Produtoinfo.tituloInfo">
                            <p v-html="Produtoinfo.descricaoInfo"></p>
                        </tab>
                    </tabs>
                </div>
            </div>
           
           
        </div>
        <div class="description th__mb-100 hidden-lg hidden-md hidden-xl">
            <div class="container">
                <div class="row">
                    <div v-for="Produtoinfo in ProdutosInfoAdicional" :key="Produtoinfo.ProdutoInfoAdicionalId" class="col-12">
                        <h6 class="text-black" style="padding-top:30px;">{{ Produtoinfo.tituloInfo }}</h6>
                        <hr>
                        <div class="col-12">
                            <br />
                            <p v-html="Produtoinfo.descricaoInfo"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Adicione mais Produtos -->
        <Modal v-model="showModal_addProd" title="">

            <section class="section-modal modal-produtos">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal">
                                Adicione mais itens ao seu pedido
                            </h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-lg-3 col-xl-3" v-for="ProdutoAvulso in ProdutosAvulso" :key="ProdutoAvulso.produtoAvulsoId" style="margin-top:20px;">
                            <div class="">
                                <div v-if="verificaAvulso(ProdutoAvulso)" class="" style="text-align:center;">
                                    <a @click="carregaAvulsos(ProdutoAvulso)">
                                        <div class="">
                                            <img v-if="ProdutoAvulso.itemAvulso.urlImg != null" :src="$Url  + ProdutoAvulso.itemAvulso.urlImg" alt="" style="height:120px;width:auto;">
                                            <img v-else :src="$Url  + '/imagens/produtos/indisponivel.jpg)'" alt="">
                                        </div>
                                    </a>
                                    <div class="">
                                        <div class="">
                                            <div>
                                                <a @click="carregaAvulsos(ProdutoAvulso)" style="color: #000000; font-size: 14px; font-weight: 400;">
                                                    {{ ProdutoAvulso.itemAvulso.nomeItemAvulso }}
                                                </a>
                                            </div>
                                        </div>
                                        <div style="display:inline-flex;">
                                            <div class="product_price">R$ {{ parseFloat(ProdutoAvulso.itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</div>
                                        </div>
                                    </div>
                                    <a @click="carregaAvulsos(ProdutoAvulso)" class="btn btn-outline-info d-block" style="margin-top:0px !important;font-size:12px;">
                                        Adicionar
                                    </a>
                                </div>
                                <div v-else style="text-align:center;">
                                    <a @click="RemoveAvulsos(ProdutoAvulso)">
                                        <div class="">
                                            <img v-if="ProdutoAvulso.itemAvulso.urlImg != null" :src="$Url  + ProdutoAvulso.itemAvulso.urlImg" alt="" style="height:120px;width:auto;">
                                            <img v-else :src="$Url  + '/imagens/produtos/indisponivel.jpg)'" alt="">
                                        </div>
                                    </a>
                                    <div class="product_content">
                                        <div class="product_name">
                                            <div>
                                                <a @click="RemoveAvulsos(ProdutoAvulso)" style="color: #000000; font-size: 14px; font-weight: 400;">
                                                    {{ ProdutoAvulso.itemAvulso.nomeItemAvulso }}
                                                </a>
                                            </div>
                                        </div>
                                        <div style="display:inline-flex;">
                                            <div class="product_price">R$ {{ parseFloat(ProdutoAvulso.itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</div>
                                        </div>
                                    </div>
                                    <a @click="RemoveAvulsos(ProdutoAvulso)" class="btn btn-success d-block" style="margin-top:0px !important; font-size:12px;">
                                        Adicionado
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-if="ProdutosAvulso == ''" class="col-12 col-md-12 col-lg-12 my-4 text-center">
                            <h6 class="titulo-card" style="font-size:16px !important;">
                                Não existem produtos avulsos disponíveis para este produto.
                            </h6>
                        </div>
                    </div>
                </div>
            </section>



        </Modal>
    </section>

</template>

<script>

    import VueModal from '@kouts/vue-modal'
    import { VMoney } from 'v-money'
    import moment from 'moment';
    import Lingallery from 'lingallery';
   

    export default {
        metaInfo: {
            bodyScript: [
            { src: '' }
            ],
        },
        components: {
            'Modal': VueModal,
            Lingallery,
        },
        data() {
            return {
                Pagina: this.$route.params.pagina,
                vModal: this.$route.params.modal,
                ProdutoId: this.$route.params.id,
                segmentoProdNivel1Id: this.$route.params.segmento,
                segmentoProdNivel2Id: '',
                nomeSegmento: '',
                Produto: {},
                ProdutosIncluso: [],
                ProdutoIncluso: {},
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                },
                ImgProduto: [],
                currentId: null,
                ProdutosAvulso: [],
                ProdutoAvulso: {},
                totalavulsos: 0,
                totalfoto: 0,
                DadosCompra: {
                    produtos: [],
                    mensagem: [],
                    agenda: [],
                    foto: [],
                    avulsos: [],
                    baloes: [],
                    informacao: [],
                    pagamento: [],
                    cupom: [],
                },
                ProdutosInfoAdicional: [],
                Produtoinfo: {},
                Texto: '',
                ListaEntregaFrete: [],
                vValor: 0,
                EntregaFreteId: 0,
                showModal_addProd: false,
                ValorTotal: 0,
                Qtde: 1,
                pais: {},
                listaFrete: {},
                Frete: '',
                numCep: '',
                CepOrigem: '',
                ligacaoProduto: [],
                ListaEtiquetaImg: [],
                mostraPremio: true,
                UrlimgBannerGeral: '',
                liberaValores:false,
                 ClientesGeral:[],
                Clientes:[],
                 ClientesTabelaPreco:[],
                TabelaPreco:[],
                MostraCompraminia:'',
                Etiquetas:[],
            }
        },
        directives: { money: VMoney },
        methods: {
            MudaTexto(value) {
                this.Texto = value.descricaoInfo;
            },
            CarregaModal(value) {
                console.log(value)
            },
            currentDateTime(vdate) {
                return moment(vdate).format('LL');
            },
            carregaAvulsos(value) {
                this.ArmazenaDados('avulsos', value)
            },
            BuscaPais(value) {
                //console.log(value)
                if (this.segmentoProdNivel1Id != 9 && this.segmentoProdNivel1Id != 7 && value != null) {
                    this.$http
                        .get(this.$apiUrl + "/pais/" + parseInt(value, 10))
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.pais = tiu2;
                        });
                    return this.pais.nomePais;
                } else {
                    return null
                }

            },
            RemoveAvulsos(value) {
                let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId != value.itemAvulso.itemAvulsoId;
                    });
                    dadosArmazenados.avulsos = vrecebe;
                    localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))
                    this.$forceUpdate();

                }
            },
            carregarAvulsos() {
                let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.avulsos == '') {
                        this.$mensagem_normal("Você precisa adicionar produtos ou marcar enviar sem avulsos.");
                    }
                    else {
                        this.showModal_addProd = false
                        if (dadosArmazenados.avulsos != '') {
                            this.$mensagem_normal("Complementos adicionados com sucesso.");
                        }
                    }
                }

            },
            enviarSemAvulsos() {
                let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.avulsos = [];
                    dadosArmazenados.avulsos.push({ produtoAvulsoId: 0, avulso: "Sem Avulsos" });
                    localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))
                    this.showModal_addProd = false
                }
            },
            verificaAvulso(value) {
                let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId == value.itemAvulso.itemAvulsoId;
                    });

                    if (vrecebe != '') {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }

            },
            CarregaFinal() {
                if (this.Pagina != null) {
                    this.$redirect("/" + this.Pagina);
                } else {
                    if (parseInt(this.Produto.qtdeMinima,10) < parseInt(this.Qtde,10)) {
                        this.$mensagem_normal("Quantidade indisponível.");
                    }
                    else {
                        this.ArmazenaDados('produtos', null)
                        this.$redirect_reload("/carrinho");
                    }
                }
            },
            ArmazenaDados(item, value) {

                let varray = value;
                //console.log("array " + varray)

                if (item == 'produtos') {
                    let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        dadosArmazenados.produtos.push({ produtoId: parseInt(this.ProdutoId, 10), qtde: parseInt(this.Qtde,10), produto: this.Produto });
                        localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))

                    }
                }

                if (item == 'avulsos') {
                    let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);

                        let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                            return x.produtoAvulsoId == varray.itemAvulso.itemAvulsoId;
                        });

                        let vrecebe2 = dadosArmazenados.avulsos.filter((x) => {
                            return x.produtoAvulsoId == 0;
                        });

                        if (vrecebe2 != '') {
                            dadosArmazenados.avulsos = [];
                        }

                        if (vrecebe == '') {
                            dadosArmazenados.avulsos.push({ produtoAvulsoId: varray.itemAvulso.itemAvulsoId, avulso: varray.itemAvulso });
                            localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))
                        }
                    }
                }
            },
            totalizaavulsos() {
                let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.avulsos != '') {
                        if (dadosArmazenados.avulsos[0].avulso == "Sem Avulsos") {
                            return 0;
                        }
                        else {
                            let recebe = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                            return recebe;
                        }
                    }
                    else {
                        return 0;
                    }

                }
                else {
                    return 0;
                }
            },
            CalculaFrete() {
                if (this.numCep == '') {
                    this.$mensagem_normal("Por favor preencha o CEP de entrega");
                }
                else {

                    this.$http
                        .get(this.$apiUrl + "/entregacorreio")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.CepOrigem = tiu2[0].cep;

                            let InformacaoFrete = {
                                Produtos: [],
                                ProdutosAvulsos: [],
                                CepOrigem: this.CepOrigem,
                                CepDestino: this.numCep,
                                Correios: true,
                                Entregas: true
                            }
                            let _produto = this.Produto;
                            _produto.qtdeMinima = parseInt(this.Qtde,10);
                            //console.log(_produto)
                           
                            InformacaoFrete.Produtos.push(_produto);

                            //this.ListaAvulsos.forEach((itempa) => {
                            //    InformacaoFrete.ProdutosAvulsos.push(itempa.itemAvulso);
                            //});

                            console.log(InformacaoFrete)

                            this.$http
                                .post(this.$apiUrl + "/EntregaFrete/Calcular", InformacaoFrete)
                                .then(response => {

                                    this.ListaEntregaFrete = response.body
                                  //console.log("aqui");
                                     console.log(this.ListaEntregaFrete)
                                     let vrecebe = []
                                    this.ListaEntregaFrete.forEach((itemex) => {
                                         //aqui valida compra minima
                                       if (itemex.compraMinima > 0){
                                           this.MostraCompraminia = "Nas Compras acima de R$ " + parseFloat(itemex.compraMinima).toFixed(2).toString().replace('.',',') + ", Você ganha frete Grátis!";
                                       }

                                       if (this.subtotal > itemex.compraMinima){
                                           vrecebe.push(itemex);
                                       }
                                    });

                                    if (vrecebe!= ''){ 
                                        let _filtraGratis = vrecebe;
                                        let vrecebe2 = []
                                         _filtraGratis.forEach((itemex2) => {
                                             if (itemex2.nome.indexOf("Frete") != -1 || itemex2.nome.indexOf("Miguel") != -1){
                                              vrecebe2.push(itemex2);
                                             }
                                         });
                                       
                                        if (vrecebe2 != ''){
                                            this.ListaEntregaFrete = vrecebe2;
                                            this.MostraCompraminia = "";
                                        }
                                        else{
                                           this.ListaEntregaFrete = _filtraGratis;
                                        }
                                    }
                                    //console.log(this.ListaEntregaFrete);
                                },
                                    error => {
                                        this.$mensagem_erro("CEP Inválido.")
                                        console.log(error)
                                    });

                        });
                }

            },
             CheckLogin() {
                let usuarioTmp = {
                    email: '',
                    senha: '',
                }
                usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
                if (usuarioTmp != null) {

                    this.$http
                    .get(this.$apiUrl + "/clientes")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.ClientesGeral = tiu2;
                        this.Clientes = tiu2.filter(x => x.clienteId == usuarioTmp.usuarioId);

                        if (this.Clientes!= ''){
                            if (this.Clientes[0].acessoPrivado){
                               this.liberaValores=true;
                            }
                            else{
                                //this.$mensagem_normal("Cadastro aguardando liberação, em breve retornaremos.");
                                this.liberaValores=false;
                            }

                        }
                    });
                }
                else{
                    this.liberaValores=false;
                }
            },
             CheckLoginTabelaPreco() {
                let usuarioTmp = {
                    email: '',
                    senha: '',
                }
                //console.log('aqui')
                usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
                if (usuarioTmp != null) {

                    this.$http
                    .get(this.$apiUrl + "/LigacaoPrecoClientes")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.ClientesGeral = tiu2;
                        this.ClientesTabelaPreco = tiu2.filter(x => x.cliente.clienteId == usuarioTmp.usuarioId && x.tabelaPreco.status && moment(x.tabelaPreco.validade).format() >= moment().format());
                        //console.log("this.ClientesTabelaPreco")
                        //console.log(this.ClientesTabelaPreco)
                        if (this.ClientesTabelaPreco != ''){
                                //console.log("check login");
                                this.$http
                                .get(this.$apiUrl + "/tabelaprecoproduto/tabelapreco/" + parseInt(this.ClientesTabelaPreco[0].tabelaPreco.tabelaPrecoId,10) )
                                .then((res4) => res4.json())
                                .then((tiu4) => {
                                     this.TabelaPreco = tiu4;

                                      //atualiza valores de acordo com tabela de preço do cliente
                                        if (this.TabelaPreco != ''){
                                            console.log("tabela preco");
                                            console.log(this.TabelaPreco)
                                            let vProd = this.TabelaPreco.filter(x => x.produtoId == this.ProdutoId )
                                            if (vProd[0].valoPor > 0 && vProd[0].qtdeEstoque > 0){
                                                this.Produto.valorDe = vProd[0].valorDe;
                                                this.Produto.valorAte = vProd[0].valoPor;
                                            }
                                        }
                                 });
                            }
                     });
                }
            },
        },
        created() {
           let recaptchaScript = document.createElement('script')
           recaptchaScript.setAttribute('type', 'text/javascript')
           recaptchaScript.setAttribute('src', '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-623a3062c5d30f1a')
           document.body.appendChild(recaptchaScript)

            // if (this.segmentoProdNivel1Id == 0) {
            this.$http
                .get(this.$apiUrl + "/ligacaosegmentoproduto")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ligacaoProduto = tiu2.filter(x => x.produto.produtoId == this.ProdutoId)
                    this.segmentoProdNivel1Id = this.ligacaoProduto[0].segmentoProdNivel1.segmentoProdNivel1Id;
                    this.segmentoProdNivel2Id = this.ligacaoProduto[0].segmentoProdNivel2.segmentoProdNivel2Id;
                    this.nomeSegmento = this.ligacaoProduto[0].segmentoProdNivel1.nomeSegmento.replaceAll(' ', '-').replaceAll('/', '')
                });
            // }
            if (this.segmentoProdNivel1Id==10){
                 this.CheckLogin();
             }
            // console.log(this.segmentoProdNivel1Id)
            this.totalizaavulsos();

            //carrega informações do produto
            this.$http
                .get(this.$apiUrl + "/produto/" + parseInt(this.ProdutoId, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Produto = tiu2
                    this.Produto.imagens.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    }).forEach(element => {
                        if (element.urlImg != '') {
                            this.ImgProduto.push({ src: this.$Url + element.urlImg, thumbnail: this.$Url + element.urlImg })
                        }
                    }
                    );
                    
                    //verifica se tem tabela de preço para esse cliente
                    this.CheckLoginTabelaPreco();

                     //carrega informações dos produtos informação adicional
                    this.$http
                        .get(this.$apiUrl + "/ProdutoInfoAdicional/produto/" + parseInt(this.ProdutoId, 10))
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ProdutosInfoAdicional = tiu2;
                            if (this.segmentoProdNivel1Id != 9 && this.segmentoProdNivel1Id != 7) {
                                //console.log(this.Produto)
                                this.ProdutosInfoAdicional.push({ produtoInfoAdicionalId: 999999, produto: null, tituloInfo: 'Vinícola', descricaoInfo: this.Produto.filtroFabrica.texto })
                            }

                            this.ProdutosInfoAdicional.sort(function (a, b) {
                                return a.ordem - b.ordem;
                            });

                            this.MudaTexto(this.ProdutosInfoAdicional[0])
                        });
                });


            this.$http
                .get(this.$apiUrl + "/ProdutoAvulso/produto/" + parseInt(this.ProdutoId, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ProdutosAvulso = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });

            this.$http
                .get(this.$apiUrl + "/produtopremio/produto/"+ parseInt(this.ProdutoId, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Etiquetas = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                    console.log('premios');
                    console.log(this.Etiquetas);
                });

            //carrega informações de todos os produtos
            this.$http
                .get(this.$apiUrl + "/produtotextogeral")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    if (tiu2 != null) {
                        this.UrlimgBannerGeral = tiu2[0].urlImg;
                    }
                });

            // cria localstorage para armazenar informações da compra
            let dadosArmazenados = localStorage.getItem('dadoslabodeguita');
            if (!dadosArmazenados) {
                dadosArmazenados = this.DadosCompra;
                localStorage.setItem('dadoslabodeguita', JSON.stringify(dadosArmazenados))
            }

        },
        mounted() {
        },
        computed: {
            subtotal: function () {
                return this.totalavulsos + (this.Produto.valorAte * parseInt(this.Qtde,10));
            },
        }
    }
</script>

<style scoped>
input {
    font-size: 20px;
    padding:6px;
}

input[type=number]::-webkit-inner-spin-button { 
  all: unset; 
  min-width: 21px;
  min-height: 45px;
  margin: 17px;
  padding: 0px;
  background-image: 
  linear-gradient(to top, transparent 0px, transparent 16px, #fff 16px, #fff 26px, transparent 26px, transparent 35px, #000 35px,#000 36px,transparent 36px, transparent 40px),
  linear-gradient(to right, transparent 0px, transparent 10px, #000 10px, #000 11px, transparent 11px, transparent 21px);
  transform: rotate(90deg) scale(0.8, 0.9);
  cursor:pointer;
}
    .vfigure {
        display: -webkit-box;
        position: relative;
        margin-right: 3px;
        top: 5px;
        height: 40px;
        text-align: center;
    }

    @media (max-width:600px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 100%;
            outline: none;
            height: 270px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 12px;
        }

        .imagemCartao {
            height: 270px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 90%;
        }

        .vfigure {
            display: -webkit-box;
            position: relative;
            margin-right: 3px;
            top: 5px;
            height: 40px;
            text-align: center;
        }
    }

    @media (min-width:608px) and (max-width:991px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 98%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 80%;
        }
    }

    @media (min-width:992px) and (max-width:1199px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    @media (min-width:1200px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }
</style>