<template>
    <section class="vinhos">
        <TopoInternas :titulo="NomeSegmentoFilho" class="hidden-sm"></TopoInternas>
            <div v-if="!liberaValores && segmentoProdNivel1Id==10" class="container">
                    <div class="row box_content" style="margin-top:10px;margin-bottom:10px;">
                        <div class="col-lg-6">
                             <h3 class="titulo-principal text-center mb-5">Acesso aos importadores</h3>
                             <p>
                                Se você é importador de vinhos e procura novos rótulos ou novas vinícolas para importar, você chegou ao lugar certo! Se já possui seu acesso, basta digitar ao lado seu CNPJ e Senha. Com seu acesso cadastrado você poderá visualizar os preços FOB dos vinhos ou solicitar cotações específicas.
                             </p>
                              <button v-if="AbreCad==false" @click="AbreCad=true" class="btn btn-success w-100 mt-5">Quero me cadastrar</button>
                              <button v-if="AbreCad==true" @click="AbreCad=false" class="btn btn-success w-100 mt-5">Lembrei meu login</button>
                        </div>
                        <div class="col-lg-1">
                        </div>
                          <div class="col-lg-5" style="padding-top: 15px;">
                            <div class="">
                                <div v-if="AbreCad" class="input-group mb-3 mt-4">
                                    <input v-model="Formulario.Email" type="text" class="form-control form-control-icone" placeholder="Email" onfocus="this.placeholder=''" onblur="this.placeholder='Email'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                </div>
                                 <div class="input-group mb-3">
                                    <input v-model="Formulario.cnpj" type="text" v-mask="'##.###.###/####-##'" class="form-control form-control-icone" placeholder="CNPJ" onfocus="this.placeholder=''" onblur="this.placeholder='CNPJ'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                </div>
                                <!--v-if="AbreSenha || AbreCad"-->
                                <div  class="input-group mb-3">
                                    <input v-model="Formulario.Senha" type="password" class="form-control form-control-icone" placeholder="Senha" onfocus="this.placeholder=''" onblur="this.placeholder='Senha'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-lock"></i>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="AbreCad" class="input-group mb-3">
                                    <input v-model="Formulario.Nomecli" type="text" class="form-control form-control-icone" placeholder="Razão Social*" onfocus="this.placeholder=''" onblur="this.placeholder='Razão Social*'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-user-circle"></i>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="AbreCad" class="input-group mb-3">
                                    <input v-model="Formulario.Whatscli" type="text" v-mask="'(##) ####-#####'" class="form-control form-control-icone" placeholder="Whatsapp*" onfocus="this.placeholder=''" onblur="this.placeholder='Whatsapp*'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-phone"></i>
                                        </span>
                                    </div>
                                </div>
                                <a href="#" v-if="AbreCad==false" class="navbar_detalhes-link" @click.prevent="showModal_senha=true">
                                    Esqueci a senha
                                </a>
                                <button v-if="!AbreSenha && !AbreCad" @click="entrar()" class="btn btn-success w-100 mt-4">Login</button>
                                <button v-if="AbreCad" @click="carregaCliente()" class="btn btn-success w-100 mt-4">Enviar Cadastro</button>
                            </div>
                        </div>
                    </div>
            </div>
        <div class="shop">
            <div class="container">
                <div class="row">
                    <!-- Side -->
                    <div class="col-lg-3">

                        <div class="row">
                            <div class="col-md-6 col-lg-12">
                                <!-- Shop Sidebar -->
                                <div class="shop_sidebar">
                                    <!-- Categoria -->
                                    <div class="sidebar_section">
                                        <div class="sidebar_title">Segmento</div>
                                        <ul class="sidebar_categories hidden-sm-md">
                                            <li v-for="MenuSegmento in MenuSegmentos" :key="MenuSegmento.segmentoProdNivel2Id">
                                                <a href="" @click.prevent="CarregaSegmento2(MenuSegmento)">{{ MenuSegmento.nomeSegmento }}</a>
                                            </li>
                                        </ul>
                                        <!-- Somente MOBILE -->
                                        <center>
                                        <v-select v-model="SegselectedNav" @input="MudaSegMobile"
                                                  :options="SegMobile"
                                                  :value="SegMobile.segmentoProdNivel2Id"
                                                  single-line
                                                  label="nomeSegmento" class="style-chooser hidden-lg hidden-xl mt-4 mt-md-0" style="width: 90%;"></v-select>
                                                </center>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-12 mt-4">
                                <div class="sidebar_title hidden-lg hidden-xl" style="background-color:#f2f7f7;text-align:center;padding:10px;">
                                    <a href="" @click.prevent="MostraPesquisa()" style="padding-top:10px;color:#000;">Pesquisa Avan&ccedil;ada <i class="fa fa-angle-double-down" style="font-size:12px;"></i></a>
                                </div>
                                <!-- Filtro de nome -->
                                <div v-if="mostrapes" class="hidden-lg hidden-xl">
                                    <div class="sidebar_section">
                                        <div class="sidebar_subtitle">Por nome</div>
                                        <div class="" style="display:inline-flex;">
                                            <input type="text" v-model="BuscaNome" style="margin-right:5px;">
                                            <button type="button"
                                                    class="btn btn-success"
                                                    @click="AplicaFiltros()">
                                                Busca
                                            </button>
                                        </div>
                                    </div>
                                    <!-- Filtro de valor -->
                                    <div v-if="segmentoProdNivel1Id!=10" class="sidebar_section">
                                        <div class="sidebar_subtitle">Filtro Valor</div>
                                        <div class="">
                                            <div id="slider-range" class="slider_range"></div>
                                            <div v-for="(filtroValor,index) in filtrosValor" :key="filtroValor.filtroValorId">
                                                <span v-if="index == 0" style="color:rgba(0,0,0,0.5);font-size: 14px;font-weight: 400;margin-bottom:3px;">
                                                    <input type="radio" v-model="vmfiltroValor" :value="filtroValor.filtroValorId" @click="vmfiltroResuladoValor=filtroValor,AplicaFiltros()" class="amount" readonly style="border:0; font-weight:bold;"> At&eacute; R$ {{ parseFloat(filtroValor.valorAte,10).toFixed(2).toString().replace('.',',') }}
                                                </span>
                                                <span v-else-if="index == filtrosValor.length - 1" style="color:rgba(0,0,0,0.5);font-size: 14px;font-weight: 400;margin-bottom:3px;">
                                                    <input type="radio" v-model="vmfiltroValor" :value="filtroValor.filtroValorId" @click="vmfiltroResuladoValor=filtroValor,AplicaFiltros()" class="amount" readonly style="border:0; font-weight:bold;"> Acima de R$ {{ parseFloat(filtroValor.valorDe,10).toFixed(2).toString().replace('.',',') }}
                                                </span>
                                                <span v-else style="color:rgba(0,0,0,0.5);font-size: 14px;font-weight: 400;margin-bottom:3px;">
                                                    <input type="radio" v-model="vmfiltroValor" :value="filtroValor.filtroValorId" @click="vmfiltroResuladoValor=filtroValor,AplicaFiltros()" class="amount" readonly style="border:0; font-weight:bold;"> De R$ {{ parseFloat(filtroValor.valorDe,10).toFixed(2).toString().replace('.',',') }} at&eacute; R$ {{ parseFloat(filtroValor.valorAte,10).toFixed(2).toString().replace('.',',') }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3" class="sidebar_section sidebar-select">
                                        <div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-2">País</div>
                                        <v-select v-model="paisselected" @input="Mudapais"
                                                  :options="pais"
                                                  :value="pais.paisId"
                                                  single-line
                                                  label="nomePais" class="style-chooser form-control-sidebar">
                                        </v-select>
                                    </div>
                                    <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3 && segmentoProdNivel1Id!=10" class="sidebar_section sidebar-select">
                                        <div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-2">Uva</div>
                                        <v-select v-model="materiaPrimaselected" @input="MudamateriaPrima"
                                                  :options="materiaPrima"
                                                  :value="materiaPrima.filtroMateriaPrimaId"
                                                  single-line
                                                  label="nomeMateriaPrima" class="style-chooser form-control-sidebar">
                                        </v-select>
                                    </div>
                                    <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3 && segmentoProdNivel1Id!=10" class="sidebar_section sidebar-select">
                                        <div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-2">Marca</div>
                                        <v-select v-model="marcaselected" @input="Mudamarca"
                                                  :options="marca"
                                                  :value="marca.filtroMarcaId"
                                                  single-line
                                                  label="nomeMarca" class="style-chooser form-control-sidebar">
                                        </v-select>
                                    </div>
                                </div>
                                <div class="hidden-sm">
                                    <div class="sidebar_section">
                                        <div class="sidebar_subtitle">Por nome</div>
                                        <div class="" style="display:inline-flex;">
                                            <input type="text" v-model="BuscaNome" style="margin-right:5px;">
                                            <button type="button"
                                                    class="btn btn-success"
                                                    @click="AplicaFiltros()">
                                                Busca
                                            </button>
                                        </div>
                                    </div>
                                    <!-- Filtro de valor -->
                                    <div v-if="segmentoProdNivel1Id!=10" class="sidebar_section">
                                        <div class="sidebar_subtitle">Filtro Valor</div>
                                        <div class="">
                                            <div id="slider-range" class="slider_range"></div>
                                            <div v-for="(filtroValor,index) in filtrosValor" :key="filtroValor.filtroValorId">
                                                <span v-if="index == 0" style="color:rgba(0,0,0,0.5);font-size: 14px;font-weight: 400;margin-bottom:3px;">
                                                    <input type="radio" v-model="vmfiltroValor" :value="filtroValor.filtroValorId" @click="vmfiltroResuladoValor=filtroValor,AplicaFiltros()" class="amount" readonly style="border:0; font-weight:bold;"> At&eacute; R$ {{ parseFloat(filtroValor.valorAte,10).toFixed(2).toString().replace('.',',') }}
                                                </span>
                                                <span v-else-if="index == filtrosValor.length - 1" style="color:rgba(0,0,0,0.5);font-size: 14px;font-weight: 400;margin-bottom:3px;">
                                                    <input type="radio" v-model="vmfiltroValor" :value="filtroValor.filtroValorId" @click="vmfiltroResuladoValor=filtroValor,AplicaFiltros()" class="amount" readonly style="border:0; font-weight:bold;"> Acima de R$ {{ parseFloat(filtroValor.valorDe,10).toFixed(2).toString().replace('.',',') }}
                                                </span>
                                                <span v-else style="color:rgba(0,0,0,0.5);font-size: 14px;font-weight: 400;margin-bottom:3px;">
                                                    <input type="radio" v-model="vmfiltroValor" :value="filtroValor.filtroValorId" @click="vmfiltroResuladoValor=filtroValor,AplicaFiltros()" class="amount" readonly style="border:0; font-weight:bold;"> De R$ {{ parseFloat(filtroValor.valorDe,10).toFixed(2).toString().replace('.',',') }} at&eacute; R$ {{ parseFloat(filtroValor.valorAte,10).toFixed(2).toString().replace('.',',') }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3" class="sidebar_section sidebar-select">
                                        <div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-2">País</div>
                                        <v-select v-model="paisselected" @input="Mudapais"
                                                  :options="pais"
                                                  :value="pais.paisId"
                                                  single-line
                                                  label="nomePais" class="style-chooser form-control-sidebar">
                                        </v-select>
                                    </div>
                                    <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3 && segmentoProdNivel1Id!=10" class="sidebar_section sidebar-select">
                                        <div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-2">Uva</div>
                                        <v-select v-model="materiaPrimaselected" @input="MudamateriaPrima"
                                                  :options="materiaPrima"
                                                  :value="materiaPrima.filtroMateriaPrimaId"
                                                  single-line
                                                  label="nomeMateriaPrima" class="style-chooser form-control-sidebar">
                                        </v-select>
                                    </div>
                                    <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=7 && segmentoProdNivel1Id!=3 && segmentoProdNivel1Id!=10" class="sidebar_section sidebar-select">
                                        <div class="sidebar_subtitle brands_subtitle mt-md-0 mt-lg-4 mb-2">Marca</div>
                                        <v-select v-model="marcaselected" @input="Mudamarca"
                                                  :options="marca"
                                                  :value="marca.filtroMarcaId"
                                                  single-line
                                                  label="nomeMarca" class="style-chooser form-control-sidebar">
                                        </v-select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-9">

                        <div class="row">
                            <div class="col">
                                <div class="shop_bar mt-2  clearfix">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="shop_product_count mb-4 mb-md-0" style="font-size:25px;">
                                                {{ NomeSegmentoFilho }}
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="" >
                                                <center>
                                                <v-select v-model="Ordemselected" @input="MudaOrdem" style="position:relative;z-index:99999999;width: 90%;"
                                                          :options="Ordenar"
                                                          :reduce="nome => nome.valor"
                                                          single-line
                                                          label="nome"
                                                          class="style-chooser">
                                                </v-select>
                                            </center>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div v-if="segmentoProdNivel1Id!=9 && segmentoProdNivel1Id!=10" class="row" style="position:relative;z-index:0;">

                            <div class="col-6 col-lg-3 col-xl-3" v-for="produto in Produtos" :key="produto.produtoId" style="margin-top:20px;">
                                <figure id="container" class="vfigure">
                                    <div v-for="ItemEtiqueta in produto.premios" :key="ItemEtiqueta.produtoPremioId">
                                        <img :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" />
                                        <figcaption :title="ItemEtiqueta.nomePremio" style="font-size: 12px; left: 0px; top: 17px;color:#000000;width:50px;position: absolute;">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                    </div>
                                </figure>
                                <div class="product_item border_active text-center">
                                    <a @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                        <div class="product_image d-flex flex-column align-items-center justify-content-center">
                                            <img v-if="produto.imagens[0] != null" :src="$Url  + produto.imagens[0].urlImg" alt="" style="height:240px;width:auto;">
                                            <img v-else :src="$Url  + '/imagens/produtos/indisponivel.jpg)'" alt="">
                                        </div>
                                    </a>

                                    <div class="product_content" style="height:90px;padding-top: 40px;">
                                        <div class="product_name" style="height:50px;">
                                            <div>
                                                <a v-if="produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))" style="color: #000000; font-size: 14px; font-weight: 400;">{{ produto.nomeProduto }}</a>
                                                <a v-else class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                                            </div>
                                         </div>
                                        {{ produto.infoExtra }}
                                        <div v-html="produto.resuno" style="color: #5E1836; font-size: .7rem;text-transform:full-width;"></div>
                                        <div style="display:inline-flex;">
                                            <span v-if="produto.valorDe > 0" class="preco-antigo" style="font-size:11px;padding-top:5px;padding-right:5px;">R$ <span style="text-decoration: line-through;padding-right:5px;">{{ parseFloat(produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span> por </span>
                                            <div class="product_price" v-if="produto.descricao4=='' || produto.descricao4==null"> R$ {{ parseFloat(produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</div>
                                            <div class="product_price" v-else> Loja Parceira</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    <div v-else-if="segmentoProdNivel1Id==10" class="row" style="position:relative;z-index:0;">

                            <div class="col-6 col-lg-3 col-xl-3" v-for="produto in Produtos" :key="produto.produtoId" style="margin-top:20px;">
                                <figure id="container" class="vfigure">
                                    <div v-for="ItemEtiqueta in produto.premios" :key="ItemEtiqueta.produtoPremioId">
                                        <img :src="$Url + ItemEtiqueta.urlImg" :title="ItemEtiqueta.nomePremio" />
                                        <figcaption :title="ItemEtiqueta.nomePremio" style="font-size: 12px; left: 0px; top: 17px;color:#000000;width:50px;position: absolute;">{{ ItemEtiqueta.siglaPremio }}</figcaption>
                                    </div>
                                </figure>
                                <div class="product_item border_active text-center">
                                    <a @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))">
                                        <div class="product_image d-flex flex-column align-items-center justify-content-center">
                                            <img v-if="produto.imagens[0] != null" :src="$Url  + produto.imagens[0].urlImg" alt="" style="height:240px;width:auto;">
                                            <img v-else :src="$Url  + '/imagens/produtos/indisponivel.jpg)'" alt="">
                                        </div>
                                    </a>

                                    <div class="product_content" style="height:100px;padding-top: 40px;">
                                        <div class="product_name" style="height:50px;">
                                            <div>
                                                <a v-if="produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId + '/' + segmentoProdNivel1Id + '/' + produto.nomeProduto.replaceAll(' ','-').replaceAll('/',''))" style="color: #000000; font-size: 14px; font-weight: 400;">{{ produto.nomeProduto }}</a>
                                                <a v-else class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                                            </div>
                                        </div>
                                        {{ produto.infoExtra }}
                                        <div v-html="produto.resuno" style="color: #5E1836; font-size: .7rem;text-transform:full-width;"></div>
                                        <div v-if="liberaValores">
                                            <p class="product_price" style="font-size: 13px;font-weight: 0;"> Bottle: {{ produto.infoExtra2 }}€</p>
                                            <p class="product_price" style="font-size: 13px;font-weight: 0;"> Palettes: {{ produto.infoExtra3 }}€</p>
                                            <p class="product_price" style="font-size: 13px;font-weight: 0;"> Full Cont.: {{ produto.infoExtra4 }}€</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div v-else class="row" style="text-align:center;">
                            <div class="col-md-6 col-lg-4  mt-4 mb-4" v-for="experiencia in Produtos" :key="experiencia.produtoId">
                                <div v-if="experiencia.imagens[0] != null" class="img-item set-img" :style="{ backgroundImage: 'url(' + $Url + experiencia.imagens[0].urlImg + ')',backgroundSize: 'contain' }"></div>
                                <div class="content">
                                    <div style="height:40px;">
                                        <a v-if="experiencia.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + experiencia.produtoId + '/' + segmentoProdNivel1Id + '/' + experiencia.nomeProduto.replaceAll(' ','-').replaceAll('/',''))" style="color: #5e1936; font-size: 18px; font-weight: 800;">{{ experiencia.nomeProduto }}</a>
                                        <a v-else class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                                    </div>
                                    <h3 class="titulo_card mt-3" v-html="experiencia.resuno"></h3>
                                    <div class="mb-1 mt-1">
                                        <span v-if="experiencia.valorDe > 0" class="preco-antigo" style="font-size:11px;padding-top:5px;">R$ <span style="text-decoration: line-through;">{{ parseFloat(experiencia.valorDe,10).toFixed(2).toString().replace('.',',') }}</span> por </span>
                                        <div class="product_price" style="font-size:18px;">R$ {{ parseFloat(experiencia.valorAte,10).toFixed(2).toString().replace('.',',') }}</div>
                                    </div>
                                    <a @click="$redirect_reload('/produtoDetalhe/' + experiencia.produtoId + '/' + segmentoProdNivel1Id + '/' + experiencia.nomeProduto.replaceAll(' ','-').replaceAll('/',''))" class="btn btn-success">Ver Mais Detalhes</a>
                                </div>
                            </div>

                        </div>
                        <!--<div class="shop_content">-->
                        <!-- Shop Page Navigation -->
                        <!--<div class="shop_page_nav d-flex flex-row justify-content-center">
                                <div class="page_prev d-flex flex-column align-items-center justify-content-center"><i class="fas fa-chevron-left"></i></div>
                                <ul class="page_nav d-flex flex-row">
                                    <li><a href="#">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">...</a></li>
                                    <li><a href="#">9</a></li>
                                </ul>
                                <div class="page_next d-flex flex-column align-items-center justify-content-center"><i class="fas fa-chevron-right"></i></div>
                            </div>

                        </div>-->

                    </div>
                    <div v-if="BannerCall!= '' && segmentoProdNivel1Id==10" class="col-lg-12" style="text-align:center;padding-top:90px;">
                         <a :href="BannerCall[0].linkBtn1" target="_blank" class="produto-link">
                             <img class="img-fluid set-img" :src="$Url + BannerCall[0].institucionalImg[0].urlImagem" alt="">
                         </a>
                    </div>
                </div>
            </div>
        </div>
          <!-- Modal esqueci a senha -->
        <Modal v-model="showModal_senha">

            <section class="modal-senha">
                <div class="container">
                    <h3 class="titulo-principal text-center">Esqueci a Senha</h3>
                    <div class="row">
                        <div class="col">
                            <form action="#" class="p-4 p-lg-5">
                                <div class="input-group mb-3">
                                    <input type="text" v-model="emailreset" class="form-control form-control-icone" placeholder="CNPJ" onfocus="this.placeholder=''" onblur="this.placeholder='CNPJ'">
                                    <div class="input-group-append">
                                        <span class="input-group-text input-icone" id="#">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                    </div>
                                </div>
                                <a href="#" @click.prevent="EnviarResetSenha()" class="btn btn-success w-100">Recuperar a senha</a>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </Modal>
    </section>
</template>

<script>
    import TopoInternas from '../shared/TopoInternas.vue'
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'
    import moment from 'moment';

    export default {
        components: {
            vSelect,
            TopoInternas
        },
        data() {
            return {
                segmentoProdNivel1Id: this.$route.params.id,
                segmentoProdNivel2Id: this.$route.params.idcategoria,
                MenuSegmentos: [],
                MenuSegmento: {},
                ProdutosGerais: [],
                Produtos: [],
                ProdutosFiltro: [],
                produto: {},
                ListaEtiquetaImg: [],
                NomeSegmentoFilho: '',
                Ordemselected: 'Ordenar por',
                Ordenar: [
                    {
                        nome: "Maior Valor",
                        valor: 3
                    },
                    {
                        nome: "Menor Valor",
                        valor: 4
                    }
                ],
                SegselectedNav: 'Escolha Segmento',
                SegMobile: [],
                parcelas: 0,
                parcelasTexto: '',
                bg_imagem: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                },
                filtrosValor: [],
                filtrovalor: {},
                vmfiltroValor: {},
                vmfiltroResuladoValor: null,
                paisselected: null,
                pais: [],
                paisId: 0,
                materiaPrimaselected: null,
                materiaPrima: [],
                filtroMateriaPrimaId: 0,
                marcaselected: null,
                marca: [],
                filtroMarcaId: 0,
                BuscaNome: '',
                mostrapes: false,
                 Formulario: {
                    Email: '',
                    Senha: '',
                    cnpj:'',
                    Nomecli: '',
                    Whatscli: '',
                },
                showModal_senha: false,
                AbreSenha: false,
                AbreCad: false,
                ClientesGeral:[],
                Clientes:[],
                emailreset:'',
                liberaValores:false,
                BannerCall:[],
            }
        },
        directives: { money: VMoney },
        methods: {
            MudaOrdem() {
                if (this.Ordemselected == 3) {
                    this.Produtos.sort(function (a, b) {
                        return a.valorAte - b.valorAte;
                    }).reverse();
                } else if (this.Ordemselected == 4) {
                    this.Produtos.sort(function (a, b) {
                        return a.valorAte - b.valorAte;
                    });
                } else {
                    this.Produtos.sort(function (a, b) {
                                            if (a.produtoId > b.produtoId) {
                                                return 1;
                                            }
                                            if (a.produtoId < b.produtoId) {
                                                return -1;
                                            }
                                            return 0;
                                        }).reverse();
                }

            },
            MudaSegMobile() {
                this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2 != null && y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.SegselectedNav.segmentoProdNivel2Id, 10))).sort(function (a, b) {
                    return a.produtoid - b.produtoid
                });
                this.ProdutosFiltro = this.Produtos;
                this.AplicaFiltros();
                this.NomeSegmentoFilho = this.SegselectedNav.nomeSegmento;
                if (this.SegselectedNav.imagens != '') {
                    this.bg_imagem = this.SegselectedNav.imagens[0].urlImagem;
                }
                else {
                    this.bg_imagem = '';
                }
                this.MudaOrdem();
            },
            Mudapais() {
                if (this.paisselected != null) {
                    this.paisId = this.paisselected.paisId;
                    this.AplicaFiltros();
                }
                else {
                   // console.log("pais -- " + this.paisselected)
                    this.AplicaFiltros();
                }
            },
            MudamateriaPrima() {
                if (this.materiaPrimaselected != null) {
                    this.filtroMateriaPrimaId = this.materiaPrimaselected.filtroMateriaPrimaId;
                    this.AplicaFiltros();
                }
                else {
                    this.AplicaFiltros();
                }
            },
            Mudamarca() {
                if (this.marcaselected != null) {
                    this.filtroMarcaId = this.marcaselected.filtroMarcaId;
                    this.AplicaFiltros();
                }
                else {
                    this.AplicaFiltros();
                }
            },
            CarregaSegmento2(value) {
                this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2 != null && y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(value.segmentoProdNivel2Id, 10)));
                this.ProdutosFiltro = this.Produtos;
                this.AplicaFiltros();
                //console.log(value.imagens)
                this.NomeSegmentoFilho = value.nomeSegmento;
                if (value.imagens != '') {
                    this.bg_imagem = value.imagens[0].urlImagem;
                }
                else {
                    this.bg_imagem = '';
                }
                this.MudaOrdem();

            },
            CarregaEtiqueta(value) {
                //if (this.ListaEtiquetaImg != null) {
                //console.log(this.ListaEtiquetaImg)
                //console.log(value)
                var retorno = this.ListaEtiquetaImg.filter(x => x.produto.produtoId == value);
                //console.log(retorno)
                return retorno.sort(function (a, b) {
                    return a.ordem - b.ordem;
                });
                //}
                //else {
                //    return null
                //}
            },
            CarregaImagemUnica(value) {
                if (value != null) {
                    value.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });

                    //value.sort(function (a, b) {
                    //    if (a.ordem > b.ordem) {
                    //        return 1;
                    //    }
                    //    if (a.ordem < b.ordem) {
                    //        return -1;
                    //    }
                    //    return 0;
                    //});
                    return this.$Url + value[0].urlImg;
                }
                else {
                    return this.$Url + "/imagens/indisponivel.jpg"
                }

            },
            AplicaFiltros() {
                let resultado = this.ProdutosFiltro;
                console.log('resultado')
                 console.log(resultado)
                console.log('this.filtroMateriaPrimaId')
                console.log(this.filtroMateriaPrimaId)
                //fitra nome
                if (this.BuscaNome != '') {
                    //console.log("aqui nome")
                    resultado = resultado.filter(x => x.nomeProduto != null && x.resuno != null && (x.nomeProduto.toLowerCase().indexOf(this.BuscaNome.toLowerCase()) != -1 || x.resuno.toLowerCase().indexOf(this.BuscaNome.toLowerCase()) != -1));
                }

                //filtra valor
                if (this.vmfiltroResuladoValor != null) {
                    //console.log("aqui 0")
                    //console.log(this.vmfiltroResuladoValor)
                    resultado = resultado.filter(x => x.valorAte >= this.vmfiltroResuladoValor.valorDe && x.valorAte <= this.vmfiltroResuladoValor.valorAte);
                }

                //filtra pais
                if (this.paisselected != null) {
                    //console.log("aqui 1 ")
                    resultado = resultado.filter(x => x.pais == this.paisselected.nomePais);
                }

                //filtra uva
                if (this.materiaPrimaselected != null) {
                    //console.log("aqui 2")
                    resultado = resultado.filter(x => x.filtroMateriaPrima!=null && x.filtroMateriaPrima.filtroMateriaPrimaId ==  this.materiaPrimaselected.filtroMateriaPrimaId);
                }

                //filtra marca
                if (this.marcaselected != null) {
                    //console.log("aqui 3")
                    resultado = resultado.filter(x => x.filtroMarca!=null && x.filtroMarca.filtroMarcaId == this.marcaselected.filtroMarcaId);
                }
                //console.log("aqui 4")
                //console.log(resultado)
                this.Produtos = resultado;

                this.MudaOrdem();
            },
            MostraPesquisa() {
                if (this.mostrapes) {
                    this.mostrapes = false;
                }
                else {
                    this.mostrapes = true;
                }
            },
            entrar() {

              if (this.Formulario.cnpj == ''){
                  this.$mensagem_normal("Preencha seu CNPJ.");
              } else if (this.Formulario.Senha == ''){
                  this.$mensagem_normal("Preencha sua senha.");
              }
              else{
                 this.$http
                    .get(this.$apiUrl + "/clientes")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.ClientesGeral = tiu2;
                        this.Clientes = tiu2.filter(x => x.cnpj == this.Formulario.cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-',''));
                        
                        if (this.Clientes!= ''){
                            let _usuario = {
                                email: this.Clientes[0].email,
                                senha: this.$sha1(this.Formulario.Senha),
                            };
                            let usuario = {};
                            this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                                (response) => {
                                    // get body data
                                    usuario = response.body;
                                    let _dados = {
                                        nomeUsuario: '',
                                        usuarioId: '',
                                        senha: '',
                                        email: '',
                                    }
                                    _dados.nomeUsuario = usuario.nomeEmpresa;
                                    _dados.usuarioId = usuario.clienteId;
                                    _dados.senha = usuario.senha;
                                    _dados.email = usuario.email;

                                    let _usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
                                    if (_usuarioTmp != null) {
                                        this.$cookie.delete("usuarioLabodeguita");
                                    }

                                    this.$cookie.set("usuarioLabodeguita", JSON.stringify(_dados), {
                                        expires: "120m",
                                    });
                                    this.$redirect_reload("/produtos/10/0/Importadores");
                                },
                                (response) => {
                                    // error callback
                                    this.$cookie.delete("usuarioLabodeguita");
                                    this.$mensagem_erro(response.body);
                                }
                            );
                        }
                        else{
                            this.$mensagem_erro("Usuário não cadastrado, faça seu cadastro.");
                        }
                    });
                
                 }

            },
            CheckLogin() {
                let usuarioTmp = {
                    email: '',
                    senha: '',
                }
                usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
                if (usuarioTmp != null) {

                    this.$http
                    .get(this.$apiUrl + "/clientes")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.ClientesGeral = tiu2;
                        this.Clientes = tiu2.filter(x => x.clienteId == usuarioTmp.usuarioId);

                        if (this.Clientes!= ''){
                            if (this.Clientes[0].acessoPrivado){
                               this.liberaValores=true;
                            }
                            else{
                                //this.$mensagem_normal("Cadastro aguardando liberação, em breve retornaremos.");
                                this.liberaValores=false;
                            }

                        }
                    });
                }
                else{
                    this.liberaValores=false;
                }
            },
            EnviarResetSenha() {
                if (this.emailreset == null) {
                    this.$mensagem_normal("Por favor preencha o CNPJ da conta.");
                }
                else {
                    this.$http
                        .get(this.$apiUrl + "/clientes")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ClientesGeralReset = tiu2.filter(x => x.cnpj == this.emailreset.replaceAll('.','').replaceAll('/','').replaceAll('-',''));
                            //console.log(this.ClientesGeralReset)
                            if (this.ClientesGeralReset != '') {
                                //emailrecuperasenha
                                let objetoreset = {
                                    clienteid: parseInt(this.ClientesGeralReset[0].clienteId, 10),
                                    site: 'labodeguita.com.br',
                                    texto: 'Sua solicita&Ccedil;&atilde;o de altera&Ccedil;&atilde;o de senha foi efetuada.',
                                };
                                //console.log(objetoreset)
                                //console.log(this.$apiUrl + "/clientes/emailrecuperasenha")
                                this.$http.post(this.$apiUrl + "/clientes/emailrecuperasenha", objetoreset).then(
                                    (response) => {
                                        // get body data
                                        objetoreset = response.body;
                                        this.$mensagem_normal("Senha enviada para seu e-mail.");
                                        this.$cookie.delete("usuarioLabodeguita");
                                    },
                                    (response) => {
                                        // error callback
                                        this.$mensagem_erro(response.body);
                                    }
                                );
                            }
                            else {
                                this.$mensagem_normal("Usuário não cadastrado no sistema.");
                            }
                        });
                }
            },
            carregaCliente() {
                if (this.Formulario.Nomecli == '') {
                    this.$mensagem_normal("Por favor preencha Razão Social");
                } else if (this.Formulario.Whatscli == '') {
                    this.$mensagem_normal("Por favor preencha o whatsapp");
                } else if (this.Formulario.Email == '') {
                    this.$mensagem_normal("Por favor preencha o e-mail");
                } else if (this.Formulario.Senha == '') {
                    this.$mensagem_normal("Por favor preencha a Senha");
                } else if (this.Formulario.cnpj == '') {
                    this.$mensagem_normal("Por favor preencha a CNPJ");
                } else {
                    //console.log(this.Formulario.cnpj)
                     this.$http
                    .get(this.$apiUrl + "/clientes")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.ClientesGeral = tiu2;
                        this.Clientes = tiu2.filter(x => x.email.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());
                        
                    if (this.Clientes!= ''){
                        if (this.Clientes[0].cnpj == this.Formulario.cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-','')){
                          this.$mensagem_normal("Usuario ja cadastrado, informe sua senha.");
                          this.AbreCad = false;
                        }else{
                          this.$mensagem_normal("E-mail já utilizado em outro cadastro, informe outro e-mail.");
                          this.AbreCad = false;
                        }
                    }
                    else {
                        let _cliente =
                        {
                            clienteId: 0,
                            nomeEmpresa: this.Formulario.Nomecli,
                            email: this.Formulario.Email,
                            whatsapp: this.Formulario.Whatscli,
                            telefone: this.Formulario.Whatscli,
                            tipoCliente: { tipoClienteId: 2 },
                            cnpj: this.Formulario.cnpj.replaceAll('.','').replaceAll('/','').replaceAll('-',''),
                            ativo: true,
                            visivel: true,
                            acessoPrivado:false,
                            DataCadastro: moment().format(),
                            senha: this.$sha1(this.Formulario.Senha),
                        };

                        this.$http.post(this.$apiUrl + "/clientes/", _cliente).then(
                            (response) => {
                                this.Cliente = response.body;
                                let codcli = this.Cliente.clienteId;

                                let _dados = {
                                    nomeUsuario: '',
                                    usuarioId: '',
                                    senha: '',
                                    email: '',
                                }
                                _dados.nomeUsuario = this.Formulario.Nomecli;
                                _dados.usuarioId = codcli;
                                _dados.senha = this.$sha1(this.Formulario.Senha);
                                _dados.email = this.Formulario.Email;

                                this.$cookie.set("usuarioLabodeguita", JSON.stringify(_dados), {
                                    expires: "120m",
                                });

                                 //avisa lagodeguita
                                this.Contato.NomeEmpresa = "La Bodeguita"
                                this.Contato.Assunto = "Liberar Acesso Importadores: Cliente: " + _dados.nomeUsuario
                                this.Contato.Destinatario = "contato@labodeguita.com.br";
                                this.Contato.EmailResposta = _dados.email
                                this.Contato.TextoEmail = "Liberar Acesso Importadores: Cliente: Nome: " + _dados.nomeUsuario + " Código:" + _dados.usuarioId + " E-mail: " + _dados.email
                                this.Contato.Anexo = ""
                                this.Contato.NomeAnexo = ""
                                this.Contato.TipoAnexo = "";

                                this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
                                        .then(response => {
                                            if (response.ok)
                                                console.log("E-mail Enviado");
                                                this.$mensagem_normal("Cadastro Enviado, em breve avisaremos a liberação.");
                                                this.$redirect_reload("/produtos/10/0/Importadores");
                                        }, error => {
                                            console.log(error.bodyText)
                                            this.$mensagem_normal("Houve uma falha na conexão, favor entrar em contato com La Bodeguita.")
                                        });     
                               
                                        
                            });
                       }
                    });
                }
            },
        },
        created() {
            if (this.segmentoProdNivel1Id==10){
                this.CheckLogin();
            }

            this.$http
                .get(this.$apiUrl + "/SegmentoProdNivel2/Nivel1/" + parseInt(this.segmentoProdNivel1Id, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.MenuSegmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                    this.SegMobile = this.MenuSegmentos;
                    //this.bg_imagem = this.MenuSegmentos[0].imagens[0].urlImagem;

                    this.$http
                        .get(this.$apiUrl + "/produto/segmentoprodnivel1/" + parseInt(this.segmentoProdNivel1Id, 10))
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ProdutosGerais = tiu2
                            console.log('ProdutosGeraisaqui')
                            console.log( this.ProdutosGerais)
                            //this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == ));
                            //this.Produtos = this.ProdutosGerais.filter(x => x.visivel);
                            if (this.segmentoProdNivel2Id == 0) {
                                //this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.MenuSegmentos[0].segmentoProdNivel2Id, 10)));
                                if (this.segmentoProdNivel1Id==10){
                                   
                                      this.Produtos = this.ProdutosGerais.filter(x => x.visivel).sort(function (a, b) {
                                            if (a.produtoId > b.produtoId) {
                                                return 1;
                                            }
                                            if (a.produtoId < b.produtoId) {
                                                return -1;
                                            }
                                            return 0;
                                        }).reverse();
                                } else if (this.segmentoProdNivel1Id==9){
                                    this.Produtos = this.ProdutosGerais.filter(x => x.visivel).sort(function (a, b) {
                                            if (a.produtoId > b.produtoId) {
                                                return 1;
                                            }
                                            if (a.produtoId < b.produtoId) {
                                                return -1;
                                            }
                                            return 0;
                                        }).reverse();
                                }
                                else{
                                    console.log('aqui')
                                     
                                     this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2 != null && y.segmentoProdNivel2.visivel && y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(12, 10))).sort(function (a, b) {
                                            if (a.produtoId > b.produtoId) {
                                                return 1;
                                            }
                                            if (a.produtoId < b.produtoId) {
                                                return -1;
                                            }
                                            return 0;
                                        }).reverse();
/*                                         console.log('this.Produtos')
                                        console.log(this.Produtos)
                                        console.log('this.MenuSegmentos')
                                        console.log(this.MenuSegmentos) */
                               }
                               
                               
                                //console.log(this.Produtos)
                                this.ProdutosFiltro = this.Produtos;
                                this.MudaOrdem();
                                //console.log('this.Produtos')
                                //console.log(this.Produtos[0].segmentos[0].segmentoProdNivel1.nomeSegmento)
                                   if (this.segmentoProdNivel1Id==11){
                                      this.NomeSegmentoFilho = 'OUTLET';
                                    } else if (this.segmentoProdNivel1Id==9){
                                      this.NomeSegmentoFilho = 'EXPERIÊNCIAS';
                                    }
                                    else{
                                      console.log(this.Produtos)
                                      this.NomeSegmentoFilho = this.Produtos[0].segmentos[0].segmentoProdNivel1.nomeSegmento;//this.MenuSegmentos[0].nomeSegmento;
                                    }
                                
                            }
                            else {
                                this.SegselectedNav = this.MenuSegmentos.filter(x => x.segmentoProdNivel2Id == parseInt(this.segmentoProdNivel2Id))
                               //console.log('aqui2')
                               if (this.segmentoProdNivel1Id==10){
                                    this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.segmentoProdNivel2.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.segmentoProdNivel2Id, 10))).sort(function (a, b) {
                                    return a.produtoid - b.produtoid
                                });
                               }
                               else{
                                    this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.qtdeMinima > 0 && x.segmentos.segmentoProdNivel2.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.segmentoProdNivel2Id, 10))).sort(function (a, b) {
                                    return a.produtoid - b.produtoid
                                });
                               }

                                this.ProdutosFiltro = this.Produtos;
                                this.NomeSegmentoFilho = this.Produtos[0].segmentos.filter(x => x.segmentoProdNivel2.segmentoProdNivel1.segmentoProdNivel1Id == parseInt(this.segmentoProdNivel1Id, 10) && x.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(this.segmentoProdNivel2Id, 10))[0].segmentoProdNivel2.nomeSegmento;
                                if (this.SegselectedNav[0].imagens != '') {
                                    this.bg_imagem = this.SegselectedNav[0].imagens[0].urlImagem;
                                }
                                else {
                                    this.bg_imagem = '';
                                }
                                this.MudaOrdem(this.ProdutosGerais);
                            }
                           
                        });

                });

        /*      this.$http
                .get(this.$apiUrl + "/produtopremio")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaEtiquetaImg = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                }); */
 
            this.$http
                .get(this.$apiUrl + "/produtotextogeral")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.parcelas = parseInt(tiu2[0].texto1, 10);
                    this.parcelasTexto = tiu2[0].texto2;
                });

            //monta filtro valor
            this.$http
                .get(this.$apiUrl + "/filtrovalor")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.filtrosValor = tiu2.filter(x => x.visivel);
                });

            //monta filtro Pais
            this.$http
                .get(this.$apiUrl + "/pais")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.pais = tiu2.slice().sort(function (a, b) {
                        return (a.nomePais > b.nomePais) ? 1 : -1;
                    });
                });

            //monta filtro Materia Prima (uva)
            this.$http
                .get(this.$apiUrl + "/filtromateriaprima")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.materiaPrima = tiu2.slice().sort(function (a, b) {
                        return (a.nomeMateriaPrima > b.nomeMateriaPrima) ? 1 : -1;
                    });
                });

            //monta filtro marca
            this.$http
                .get(this.$apiUrl + "/filtromarca")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.marca = tiu2.slice().sort(function (a, b) {
                        return (a.nomeMarca > b.nomeMarca) ? 1 : -1;
                    });
                });

                //monta banner call action
                this.$http
                  .get(this.$apiUrl + "/institucional/telas/callactionimportadores")
                  .then((res2) => res2.json())
                  .then((tiu2) => {
                    this.BannerCall = tiu2.filter(x => x.visivel);
                });
        },
        mounted() {

        },
    }

</script>

<style scoped>

    @import '/css/produtos.css';
    @import '/css/vinhos.css';

    ul {
        padding-left: unset;
    }

    .form-control-sidebar {
        max-width: 80%;
    }


    .product_item {
        width: 100%;
        /*        background: #FFFFFF;
        box-shadow: 6px 4px 8px #ccc;*/
        padding-top: 130px;
        padding-bottom: 230px;
        transition: all .2s ease-in;
    }

    /*        .product_item:hover {
            box-shadow: 10px 10px 8px #ccc;
        }*/


    .vfigure {
        position: absolute;
        float: left;
        top: 20px;
        height: 40px;
        z-index: 999;
        text-align: center;
    }


    /********************
    Responsive
    ********************/

    /* Smartphones */
    @media (max-width:767px) {
        .product_item {
            left: 0px;
        }

        .shop_sorting {
            float: initial;
        }

        .shop {
            background: #FFFFFF;
            padding-top: 20px;
            padding-bottom: 139px;
        }

        .vfigure {
            position: absolute;
            float: left;
            top: 65px;
            height: 40px;
            z-index: 999;
            text-align: center;
        }
    }

    /* Tudo, menos desktop */
    @media (max-width:1199px) {
        .product_item {
            height: min-content;
            padding-top: 60px;
            padding-bottom: 80px;
        }

        .product_price {
            margin-top: 0px;
        }
    }

    .img-item {
        /*border-radius: 5px;*/
      /*   box-shadow: 1px 1px 10px #303030; */
        height: 260px;
    }

    .content {
        padding: 10px 5px 10px 5px;
    }

    /* Smartphones */
    @media (max-width:767px) {
        .content {
            padding: 30px 10px 50px 10px;
        }
    }
</style>



