<template>
  
  <section class="galeria th__section-pad-50">
    <div class="container">
      <div class="row">
        <h6 class="text-black">Conheça nosso ambiente</h6>
        <hr>
      </div>
      <div class="row">

        <div class="col-md-4 col-lg-2 text-center border p-0 hidden-sm" v-for="imagem in arr_galeria" :key="imagem.id">
          <div class="img-galeria set-img">
            <img class="img-fluid" :src="imagem.img" alt="">
          </div>
        </div>
            
      </div>
    </div>
  </section>

</template>

<script>
export default {
  data() {
    return {
      arr_galeria: [
        {
          id: 1,
          img: 'img/03.png'
        },
        {
          id: 2,
          img: 'img/04.png'
        },
        {
          id: 3,
          img: 'img/05.png'
        },
        {
          id: 4,
          img: 'img/03.png'
        },
        {
          id: 5,
          img: 'img/05.png'
        },
        {
          id: 6,
          img: 'img/04.png'
        },
      ]
    }
  }
}
</script>


<style scoped>

  .img-galeria img {
    height: 180px;
  }

</style>