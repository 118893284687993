<template>

    <section class="carrinho">

        <CheckAcesso />

        <section class="main-minha_conta">

            <div class="container">
                <div class="row">
                    <!-- Sidenav -->
                    <div class="col-lg-2">
                        <ul class="lista-sidebar_links">
                            <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                            <li><router-link to="/meusPedidos" class="texto">Meus Pedidos</router-link></li>
                            <li><router-link to="/meusArquivos" class="texto">Arquivos</router-link></li>
                            <li><router-link to="/meusGaleriaFotos" class="texto">Galeria de Fotos</router-link></li>
                            <li><router-link to="/meusVideos" class="texto">V&iacute;deos</router-link></li>
                        </ul>
                    </div>
                    <!-- Main -->
                    <div class="col-lg-10 col-xl-9 offset-xl-1 mt-5 mt-lg-0">
                        <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
                        <h6 class="subtitulo h5 mb-4">Meus V&iacute;deos</h6>
                        <!-- Lista de videos -->
                        <div class="row">
                                <div v-for="arquivo in Arquivos" :key="arquivo.videosClienteId" class="col-lg-3 col-xl-3 col-6" style="text-align:center;">
                                    <a href="#" @click="AbreModal(arquivo.video)">
                                        <div style="padding-bottom:10px;">
                                            <img src="/imagens/videopng.png" style="width:100px" />
                                        </div>
                                        <p>
                                            {{ arquivo.video.nomeVideo }}
                                        </p>
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Modal (Abre V�deo)-->
            <Modal v-model="showModal" title="">
                <div class="container" v-html="HtmlModal" id="containerHtmlVideo">
                </div>
            </Modal>

        </section>

    </section>

</template>

<script>

    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
    import moment from 'moment';

    export default {
        components: {
            CheckAcesso,
        },
        data() {
            return {
                ClienteId: 0,
                PedidoId: this.$route.params.id,
                Arquivos: [],
                NomeArquivo: 'teste',
                showModal: false,
                HtmlModal: "",
            }
        },
        methods: {
            FormataData(value) {
                return moment(value).format('DD/MM/YYYY')
            },
            AbreModal(Video) {
                this.showModal = true;
                var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;// eslint-disable-line
                var match = Video.linkVideo.match(regExp);
                if (match && match[1].length == 11) {
                    var code = match[1];
                    this.HtmlModal = "<iframe width=\"100%\" height=\"315\" src=\"https://www.youtube.com/embed/" + code + "\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>";
                }
                else {
                    this.HtmlModal = "N�o foi possivel carregar o video";
                }

            },
        },
        created() {

            let usuarioTmp = JSON.parse(this.$cookie.get("usuarioLabodeguita"));
            if (usuarioTmp != null) {
                this.ClienteId = parseInt(usuarioTmp.usuarioId, 10)
            }

            this.$http
                .get(this.$apiUrl + "/videosclientes")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Arquivos = retornoAPI.filter(x => x.video.visivel && x.cliente.clienteId == this.ClienteId);
                    console.log(this.ClienteId)
                    console.log(this.Arquivos)
                });

        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
    @import '/css/modais.css';
</style>