<template>
  
  <section class="topo_internas">
    <h2 class="titulo_principal">{{ titulo }}</h2>
  </section>

</template>

<script>
export default {
  props: ['titulo']
}
</script>

<style scoped>

  .topo_internas {
    background-color: #5e1936;
    color: #fff;
    padding: 30px 0;
    text-align: center;
  }

</style>